import { FC, useState } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import useLanguages from "hooks/useLanguages";
import Register, { SignUpParams } from "./Register";
import { FormProvider, useForm } from "react-hook-form";
import Otp from "./Otp";

export interface PageSignUpProps {
  className?: string;
}

export type OtpParams = {
  phonenumber?: string
  email?: string
}

const PageSignUp: FC<PageSignUpProps> = ({ className = "" }) => {
  const { t } = useLanguages();
  const signUpMethods = useForm<SignUpParams>();
  const [step, setStep] = useState<number>(1);

  return (
    <div className={`nc-PageSignUp  ${className}`} data-nc-id="PageSignUp">
      <Helmet>
        <title>{t('pages.register')} | Oteller</title>
      </Helmet>
      <div className="container mb-24 lg:mb-32">
        <h2 className={`${step === 2 ? "mt-20 mb-6" : "mt-20 mb-14"} flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center`}>
          {t('general.create-account')}
        </h2>
        {step === 2 && <p className="mb-8 text-center text-sm text-orange-600 dark:text-orange-500">{t('alert.dont-refresh')}</p>}
        <div className="max-w-md mx-auto space-y-6 ">
          <div>
            {step === 1 && <FormProvider {...signUpMethods}>
              <Register setStep={setStep} />
            </FormProvider>}
            {step === 2 && <FormProvider {...signUpMethods}>
              <Otp />
            </FormProvider>}
          </div>

          {step === 1 && <span className="block text-center text-neutral-700 dark:text-neutral-300">
            {t('general.have-account')} {` `}
            <Link to="/sign-in" className="text-primary-6000 font-medium underline">{t('button.sign-in')}</Link>
          </span>}
        </div>
      </div>
    </div>
  );
};

export default PageSignUp;
