import NcInputNumber from "components/NcInputNumber/NcInputNumber";
import { FC } from "react";
import { useFormContext } from "react-hook-form";
import { MobileSearchForm } from "./Search";
import useLanguages from "hooks/useLanguages";

export interface GuestInputProps {
  className?: string;
}

const GuestInput: FC<GuestInputProps> = ({
  className = "",
}) => {
  const { t } = useLanguages();
  const { watch, setValue } = useFormContext<MobileSearchForm>();

  const handleChangeData = (value: number, type: keyof GuestObject) => {
    if (type === "adultCount") {
      setValue("adultCount", value);
    }
    if (type === "childrenCount") {
      setValue("childrenCount", value);
    }
    if (type === "roomCount") {
      setValue("roomCount", value);
    }
  };

  return (
    <div className={`flex flex-col relative p-5 ${className}`}>
      <span className="mb-5 block font-semibold text-xl sm:text-2xl">
        {t('search.who-is-coming')}
      </span>
      <NcInputNumber
        className="w-full"
        defaultValue={watch("adultCount")}
        onChange={(value) => handleChangeData(value, "adultCount")}
        max={10}
        min={1}
        label={t('fields.adults')}
        desc={t('placeholder.over-twelve')}
      />
      <NcInputNumber
        className="w-full mt-6"
        defaultValue={watch('childrenCount')}
        onChange={(value) => handleChangeData(value, "childrenCount")}
        max={10}
        min={0}
        label={t('fields.children')}
        desc={t('placeholder.upto-twelve')}
      />

      <NcInputNumber
        className="w-full mt-6"
        defaultValue={watch("roomCount")}
        onChange={(value) => handleChangeData(value, "roomCount")}
        max={10}
        min={1}
        label={t('fields.rooms')}
      />
    </div>
  );
};

export default GuestInput;
