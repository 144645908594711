import { FC } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import SaleOffBadge from "components/SaleOffBadge/SaleOffBadge";
import Gallery from "components/Gallery/Gallery";
import useLanguages from "hooks/useLanguages";
import { IMAGE_PATH } from "api";
import { useApp } from "hooks/useApp";

export interface RoomCardProps {
  className?: string;
  data: IRoom;
  href: string
  bookHref?: string
  size?: "default" | "small";
}

const RoomCard: FC<RoomCardProps> = ({
  size = "default",
  className = "",
  href,
  bookHref,
  data,
}) => {
  const { user } = useApp();
  const { t, i18n } = useLanguages();
  const location = useLocation();
  const navigate = useNavigate();
  const {
    _id,
    title,
    images,
    type,
    bathCount,
    bedCount,
    price
  } = data;
  const roomImages = images.map((image) => `${IMAGE_PATH}/rooms/thumb_${image}`);

  const renderSliderGallery = () => {
    return (
      <div className="relative w-full">
        <Gallery
          uniqueID={`RoomCard_${_id}`}
          ratioClass="aspect-w-4 aspect-h-3 "
          galleryImgs={roomImages}
          href={href}
        />
        {<SaleOffBadge desc={t(`room-types.${type}`)} className="absolute left-3 top-3" />}
      </div>
    );
  };

  const onBook = () => {
    if (bookHref)
      navigate(bookHref, { state: { from: location } });
  }

  const renderContent = () => {
    return (
      <div className={size === "default" ? "p-4 space-y-4" : "p-3 space-y-2"}>
        <Link to={href} className="space-y-2">
          <span className="text-sm text-neutral-500 dark:text-neutral-400">
            {bedCount} {bedCount > 1 ? t("counter.beds.plural") : t("counter.beds.single")} {"· "}
            {bathCount} {bathCount > 1 ? t('counter.baths.plural') : t('counter.baths.single')}
          </span>
          <div className="flex items-center space-x-2">
            <h2
              className={` font-medium capitalize ${size === "default" ? "text-lg" : "text-base"
                }`}
            >
              <span className="line-clamp-1">{title[i18n.language as "tm" | "en" | "ru"]}</span>
            </h2>
          </div>
        </Link>
        <div className="w-14 border-b border-neutral-100 dark:border-neutral-800"></div>
        <div className="flex justify-between items-center">
          <span className="text-base font-semibold">
            {price} TMT
            {` `}
            {size === "default" && (
              <span className="text-sm text-neutral-500 dark:text-neutral-400 font-normal">
                /{t('general.night')}
              </span>
            )}
          </span>
          {(!user || user.role === "user") && bookHref ? <button type={"button"} onClick={onBook} className="px-4 py-2 rounded-full bg-primary-6000 hover:bg-primary-500 transition-colors text-white">
            {t('button.reserve')}
          </button> : null}
        </div>
      </div>
    );
  };

  return (
    <div
      className={`nc-RoomCard group relative bg-white dark:bg-neutral-900 border border-neutral-100 dark:border-neutral-800 rounded-2xl overflow-hidden will-change-transform hover:shadow-xl transition-shadow ${className}`}
      data-nc-id="RoomCard"
    >
      {renderSliderGallery()}
      <div>{renderContent()}</div>
    </div>
  );
};

export default RoomCard;
