import DatePicker from "react-datepicker";
import { FC } from "react";
import DatePickerCustomHeaderTwoMonth from "components/DatePickerCustomHeaderTwoMonth";
import DatePickerCustomDay from "components/DatePickerCustomDay";
import { useFormContext } from "react-hook-form";
import { MobileSearchForm } from "./Search";
import useLanguages from "hooks/useLanguages";
import localizeDayNames from "utils/localizeDayNames";

export interface DatesInputProps {
  className?: string;
}

const DatesInput: FC<DatesInputProps> = ({
  className = "",
}) => {
  const { t, i18n } = useLanguages();
  const { watch, setValue } = useFormContext<MobileSearchForm>();

  const onChangeDate = (dates: [Date | null, Date | null]) => {
    const [start, end] = dates;
    setValue("startDate", start);
    setValue("endDate", end);
  };

  return (
    <div>
      <div className="p-5">
        <span className="block font-semibold text-xl sm:text-2xl">
          {t('search.when-is-trip')}
        </span>
      </div>
      <div
        className={`relative flex-shrink-0 flex justify-center z-10 py-5 ${className} `}
      >
        <DatePicker
          selected={watch("startDate")}
          onChange={onChangeDate}
          startDate={watch("startDate")}
          endDate={watch("endDate")}
          minDate={new Date()}
          maxDate={new Date(new Date().setMonth(new Date().getMonth() + 2))}
          selectsRange
          monthsShown={2}
          showPopperArrow={false}
          inline
          renderCustomHeader={(p) => <DatePickerCustomHeaderTwoMonth {...p} />}
          renderDayContents={(day, date) => (
            <DatePickerCustomDay dayOfMonth={day} date={date} />
          )}
          formatWeekDay={(day) => {
            const dayName = localizeDayNames(day, i18n.language);
            return dayName
          }}
        />
      </div>
    </div>
  );
};

export default DatesInput;
