import FormItem from "components/FormItem";
import useLanguages from "hooks/useLanguages";
import { FC, useState } from "react";
import { useNavigate } from "react-router-dom";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import Input from "shared/Input/Input";

interface ChangePasswordProps {
  next: () => void
  onChange: (values: any) => void
}

const ChangePassword: FC<ChangePasswordProps> = ({ next, onChange }) => {
  const { t } = useLanguages();
  const navigate = useNavigate();
  const [form, setForm] = useState<{ newPassword: string, confirmPassword: string }>({
    newPassword: "",
    confirmPassword: ""
  });

  const onSubmit = (value: string) => {
    onChange((prev: any) => ({
      ...prev,
      password: value
    }))
    next()
  }

  return (
    <div className="space-y-5">
      <FormItem className="relative" label={t('fields.new-password')}>
        <Input
          id="newPassword"
          type={"password"}
          name="newPassword"
          defaultValue={form.newPassword}
          onChange={(e) => setForm({ ...form, newPassword: e.target.value })}
        />
      </FormItem>
      <FormItem className="relative" label={t('fields.confirm-password')}>
        <Input
          id="confirmPassword"
          type={"password"}
          name="confirmPassword"
          defaultValue={form.confirmPassword}
          onChange={(e) => setForm({ ...form, confirmPassword: e.target.value })}
        />
      </FormItem>
      <ButtonPrimary className="w-full" onClick={() => onSubmit(form.newPassword)}>{t('button.continue')}</ButtonPrimary>
      <ButtonSecondary type={"button"} className="w-full" onClick={() => navigate(-1)}>{t('button.go-back')}</ButtonSecondary>
    </div>
  )
};

export default ChangePassword;