import { useMutation, useQueryClient } from "@tanstack/react-query";
import toast from "react-hot-toast";
import useLanguages from "./useLanguages";
import { userApi } from "api/user";
import api from "api";

interface UseUserValue {
  update: (values: FormData) => void,
  changePassword: (values: {
    password: string,
    newPassword: string
  }) => void
  removeReview: (id: string) => void
  updateLoading: boolean
  passwordLoading: boolean
}

const useUser = (): UseUserValue => {
  const { t } = useLanguages();
  const queryClient = useQueryClient();

  const { mutate: update, isPending: updateLoading } = useMutation({
    mutationKey: ["update-user"],
    mutationFn: userApi.update,
    onSuccess: () => {
      toast.success(t('alert.updated'));
      queryClient.invalidateQueries({ queryKey: ["current-user"] });
    },
    onError: (err: any) => {
      if (err?.response?.data === "File too large") {
        toast.error(t('alert.file-too-large'));
      } else {
        toast.error(t('alert.something-went-wrong'));
      }
    }
  });

  // BROKEN
  const { mutate: changePassword, isPending: passwordLoading } = useMutation({
    mutationKey: ["change-password"],
    mutationFn: userApi.changePassword,
    onSuccess: () => {
      toast.success(t('alert.updated'));
      queryClient.invalidateQueries({ queryKey: ["current-user"] });
    },
    onError: (err: any) => {
      if (err?.response?.data === "File too large") {
        toast.error(t('alert.file-too-large'));
      } else {
        toast.error(t('alert.something-went-wrong'));
      }
    }
  });

  const { mutate: removeReview } = useMutation({
    mutationKey: ["remove-review"],
    mutationFn: async (id: string) => {
      const res = await api.delete(`/reviews/${id}`);
      return res.data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["reviews"] });
      queryClient.invalidateQueries({ queryKey: ["search-result"]});
      toast.success(t('alert.deleted'));
    },
    onError: (err: any) => {
      console.log(err);
    }
  })

  return {
    update,
    changePassword,
    removeReview,
    updateLoading,
    passwordLoading,
  }
};

export default useUser;