import moment from "moment";
import "moment/locale/tk";
import "moment/locale/en-gb";
import "moment/locale/ru";
import useLanguages from "hooks/useLanguages";

const useConvertDates = (date: Date | null | undefined) => {
  const { i18n } = useLanguages();
  return moment(date).locale(i18n.language === "tm" ? "tk" : i18n.language === "ru" ? "ru" : "en");
};

export default useConvertDates;