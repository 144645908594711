import { MapPinIcon } from "@heroicons/react/24/outline";
import { useQuery } from "@tanstack/react-query";
import api from "api";
import { FC, useCallback, useEffect, useMemo, useRef, useState } from "react";
import ButtonClear from "./ButtonClear";
import useLanguages from "hooks/useLanguages";
import { useFormContext } from "react-hook-form";
import { SearchForm } from "./Search";

interface LocationInputProps {
  className?: string
}

const LocationInput: FC<LocationInputProps> = ({ className }) => {
  const { t } = useLanguages();
  const { watch, setValue } = useFormContext<SearchForm>();
  const containerRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const [query, setQuery] = useState<string>(watch("region") ? t(`regions.${watch("region")}`) : "");
  const [isToggle, setToggle] = useState<boolean>(false);
  const [hasSelected, setHasSelected] = useState<boolean>(false);

  useEffect(() => {
    const handleClickOutside = (e: MouseEvent) => {
      if (!containerRef.current || !isToggle || containerRef.current.contains(e.target as Node)) {
        return;
      }
      setToggle(false);
    };

    if (isToggle) {
      document.addEventListener("click", handleClickOutside);
    } else {
      document.removeEventListener("click", handleClickOutside);
    }

    return () => document.removeEventListener("click", handleClickOutside);
  }, [isToggle]);

  useEffect(() => {
    if (isToggle && inputRef.current) {
      inputRef.current.focus()
    }
  }, [isToggle]);

  const { data } = useQuery({
    queryKey: ["location"],
    queryFn: async () => {
      const res = await api.get('/hotels/regions');
      return res.data
    }
  });
  const regions = useMemo<IRegion[]>(() => data || [], [data]);

  const filteredRegions = useMemo(() => regions ? query !== "" ?
    regions?.filter(item =>
      t(`regions.${item._id}`).toLowerCase().includes(query.toLowerCase())) : regions : []
    , [query, regions, t]);

  const selectRegion = useCallback((item: string) => {
    setQuery(t(`regions.${item}`));
    setValue("region", item);
    setHasSelected(true);
    setToggle(false);
  }, [setValue, t]);

  const handleBlur = () => {
    if(!query){
      return;
    }

    if (!hasSelected && filteredRegions.length > 0) {
      const closestMatch = filteredRegions.find((item) =>
        t(`regions.${item._id}`).toLowerCase().startsWith(query.toLowerCase())
      );
      if (closestMatch) {
        const regionName = t(`regions.${closestMatch._id}`);
        setQuery(regionName);
        setValue("region", closestMatch._id);
      } else {
        setQuery(watch("region") ? t(`regions.${watch("region")}`) : "");
      }
    } else if (!hasSelected) {
      setQuery(watch("region") ? t(`regions.${watch("region")}`) : "");
    }
  };

  const renderSearch = useMemo(() => {
    if (filteredRegions.length === 0) return null;

    return (
      <>
        <div className="mt-2">
          {filteredRegions.map((item) => (
            <span
              onClick={() => selectRegion(item._id)}
              key={item._id}
              className="flex px-4 sm:px-8 items-center space-x-3 sm:space-x-4 py-4 hover:bg-neutral-100 dark:hover:bg-neutral-700 cursor-pointer"
            >
              <span className="block text-neutral-400">
                <MapPinIcon className="h-4 sm:h-6 w-4 sm:w-6" />
              </span>
              <span className=" block font-medium text-neutral-700 dark:text-neutral-200">
                {t(`regions.${item._id}`)}
              </span>
            </span>
          ))}
        </div>
      </>
    );
  }, [filteredRegions, t, selectRegion]);

  return (
    <div className={`relative flex ${className}`} ref={containerRef}>
      <div onClick={() => setToggle(!isToggle)}
        className={`flex z-10 flex-1 relative [ nc-hero-field-padding ] flex-shrink-0 items-center space-x-3 cursor-pointer focus:outline-none text-left  ${isToggle ? "nc-hero-field-focused" : ""
          }`}
      >
        <div className="text-neutral-300 dark:text-neutral-400">
          <MapPinIcon className="w-5 h-5 lg:w-7 lg:h-7" />
        </div>
        <div className="flex-grow">
          <input
            className={`block w-full bg-transparent border-none focus:ring-0 p-0 focus:outline-none focus:placeholder-neutral-300 xl:text-lg font-semibold placeholder-neutral-800 dark:placeholder-neutral-200 truncate`}
            placeholder={t('placeholder.where-are-you-going')}
            value={query}
            autoFocus={isToggle}
            onChange={(e) => {
              setQuery(e.currentTarget.value);
            }}
            onBlur={handleBlur}
            ref={inputRef}
          />
          <span className="block mt-0.5 text-sm text-neutral-400 font-light ">
            <span className="line-clamp-1">{t('placeholder.location')}</span>
          </span>
          {query && isToggle && (
            <ButtonClear
              onClick={() => {
                setQuery("");
                setValue("region", "")
                setHasSelected(false);
              }}
            />
          )}
        </div>
      </div>

      {isToggle && (
        <div
          className={`h-8 absolute self-center top-1/2 -translate-y-1/2 z-0 bg-white dark:bg-neutral-800`}
        ></div>
      )}

      {isToggle && (
        <div className="absolute left-0 z-40 w-full min-w-[300px] sm:min-w-[500px] bg-white dark:bg-neutral-800 top-full mt-3 py-3 sm:py-6 rounded-3xl shadow-xl max-h-96 overflow-y-auto">
          {renderSearch}
        </div>
      )}
    </div>
  )
};

export default LocationInput;