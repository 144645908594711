import { useMutation, useQueryClient } from "@tanstack/react-query";
import { guestsApi } from "api/guest";
import FormItem from "components/FormItem";
import useLanguages from "hooks/useLanguages";
import { FC, Fragment, useCallback, useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import Input from "shared/Input/Input";
import moment from "moment";
import { CalendarIcon } from "@heroicons/react/24/outline";
import { Popover, Transition } from "@headlessui/react";
import DateTime from "react-datetime"
import "react-datetime/css/react-datetime.css";

interface GuestFormProps {
  guest?: IGuest
  toggle: () => void
  onDelete?: () => void
}

const GuestForm: FC<GuestFormProps> = ({ guest, toggle, onDelete }) => {
  const { t, i18n } = useLanguages();
  const queryClient = useQueryClient();
  const { register, watch, setValue, handleSubmit, reset } = useForm<IGuest>();
  const { mutate: create } = useMutation({
    mutationKey: ["create-guest"],
    mutationFn: guestsApi.create,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["guests"] });
      queryClient.invalidateQueries({ queryKey: ["my-guests"] });
      toggle();
    },
    onError: (err: any) => {
      console.log(err);
    }
  });

  const { mutate: update } = useMutation({
    mutationKey: ["update-guest"],
    mutationFn: guestsApi.update,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["guests"] });
      toggle();
    },
    onError: (err: any) => {
      console.log(err);
    }
  });

  useEffect(() => {
    if (guest) {
      reset({
        firstName: guest.firstName,
        lastName: guest.lastName,
        gender: guest.gender,
        documentType: guest.documentType,
        documentSerial: guest.documentSerial,
        birthDate: guest.birthDate
      })
    }
  }, [guest]);

  const isDisabled = useMemo(() => {
    if (
      !watch("firstName") ||
      !watch("lastName") ||
      !watch("birthDate") ||
      !watch("gender") ||
      !watch("documentType") ||
      !watch("documentSerial")
    ) {
      return true
    } else {
      return false
    }
  }, [watch()]);

  const onSubmit = useCallback(async (values: IGuest) => {
    if (guest) {
      update({ id: guest?._id, values })
    } else {
      create(values)
    }
  }, [guest]);

  return (
    <form className="space-y-4" onSubmit={handleSubmit(onSubmit)}>
      <FormItem label={t('fields.firstName')}>
        <Input
          type="text"
          defaultValue={watch("firstName")}
          {...register("firstName", { required: "Required" })}
        />
      </FormItem>
      <FormItem label={t('fields.lastName')}>
        <Input
          type={"text"}
          defaultValue={watch("lastName")}
          {...register("lastName", { required: "Required" })}
        />
      </FormItem>
      <FormItem label={t('fields.gender')}>
        <select value={watch("gender")} className="p-2.5 rounded-2xl" onChange={(e) => setValue("gender", e.target.value as GENDER)}>
          <option disabled={!!watch("gender")} value="">{t('placeholder.select-gender')}</option>
          <option value="male">{t('gender.male')}</option>
          <option value="female">{t('gender.female')}</option>
        </select>
      </FormItem>
      <FormItem label={t('fields.birth-date')}>
        <Popover>
          {({ open, close }) => (
            <>
              <Popover.Button className={"w-full"}>
                <div className="relative flex justify-between items-center bg-white dark:bg-neutral-900 p-2.5 border border-neutral-200 dark:border-neutral-700 rounded-2xl">
                  <p>{moment(watch("birthDate")).format("DD.MM.YYYY")}</p>
                  <CalendarIcon className="w-4 h-4 text-neutral-500 dark:text-neutral-400" />
                </div>
              </Popover.Button>
              <Transition
                as={Fragment}
                enter="transition ease-out duration-200"
                enterFrom="opacity-0 translate-y-1"
                enterTo="opacity-100 translate-y-0"
                leave="transition ease-in duration-150"
                leaveFrom="opacity-100 translate-y-0"
                leaveTo="opacity-0 translate-y-1"
              >
                <Popover.Panel className={"absolute z-10 bg-white dark:bg-neutral-800 w-screen max-w-[300px] px-4 mt-2 right-10 sm:right-0 sm:px-0"}>
                  <div className="shadow-lg ring-1 ring-black ring-opacity-5">
                    <DateTime
                      locale={i18n.language === "tm" ? "tk-TK" : i18n.language === "ru" ? "ru-RU" : "en-GB"}
                      initialValue={moment(watch("birthDate"))}
                      value={moment(watch("birthDate"))}
                      onChange={(value) => setValue("birthDate", value as any)}
                      input={false}
                      timeFormat={false}
                    />
                  </div>
                </Popover.Panel>
              </Transition>
            </>
          )}
        </Popover>
      </FormItem>
      <FormItem label={t('fields.document-type')}>
        <select value={watch('documentType')} className="rounded-2xl p-3" onChange={(e) => setValue("documentType", e.target.value as DOCUMENT_TYPE)}>
          <option disabled={!!watch("documentType")} value="">{t('placeholder.select-document-type')}</option>
          <option value="passport">{t('document-type.passport')}</option>
          <option value="birth_certificate">{t('document-type.birth-certificate')}</option>
        </select>
      </FormItem>
      <FormItem label={t('fields.document-serial')}>
        <Input
          type={"text"}
          defaultValue={watch("documentSerial")}
          {...register("documentSerial", { required: "Required" })}
        />
      </FormItem>
      <div className="w-full inline-flex justify-end gap-3">
        {onDelete && <ButtonSecondary onClick={onDelete}>{t('button.delete')}</ButtonSecondary>}
        <ButtonPrimary disabled={isDisabled} type={"submit"}>{guest ? t('button.update') : t('button.add')}</ButtonPrimary>
      </div>
    </form>
  )
};

export default GuestForm;