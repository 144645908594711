import { Tab } from "@headlessui/react";
import { useLocation } from "react-router-dom";
import { FC, Fragment, useEffect, useState } from "react";
import NcModal from "shared/NcModal/NcModal";
import Label from "components/Label/Label";
import Input from "shared/Input/Input";
import Textarea from "shared/Textarea/Textarea";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import useSearch from "hooks/useSearch";
import useConvertDates from "hooks/useConvertDates";
import useLanguages from "hooks/useLanguages";
import CheckoutSidebar from "./CheckoutSidebar";
import Checkbox from "shared/Checkbox/Checkbox";
import { LockClosedIcon } from "@heroicons/react/24/outline";
import { useMutation } from "@tanstack/react-query";
import api from "api";
import CheckoutGuests from "./CheckoutGuests";
import GuestForm from "components/GuestForm/GuestForm";
import { Modal } from "shared/Modal/Modal";
import { useNavigate, useSearchParams } from "react-router-dom";
import toast from "react-hot-toast";

export interface CheckoutPageProps {
  className?: string;
}

interface BookingForm {
  hotelId: string | null
  roomId: string | null
  startDate: Date | null
  endDate: Date | null
  roomQuantity: number
  guestIds: string[]
  paymentMethod: "cash" | "card"
}


const CheckoutPage: FC<CheckoutPageProps> = ({
  className = "",
}) => {
  const { t } = useLanguages();
  const navigate = useNavigate();
  const location = useLocation();
  const from = location?.state?.from?.pathname;
  const [searchParams] = useSearchParams();
  const hotelId = searchParams.get("hotelId");
  const roomId = searchParams.get("roomId");
  const { startDate, endDate, adultCount, childrenCount, roomCount, clearSearch } = useSearch();
  const [isChecked, setCheck] = useState<boolean>(false);
  const [showModal, setModal] = useState<boolean>(false);
  const guestCount = adultCount + childrenCount;
  const checkIn = useConvertDates(startDate);
  const checkOut = useConvertDates(endDate);
  const [guestIds, setGuestIds] = useState<string[]>([]);

  useEffect(() => {
    if(from !== `/search/${hotelId}`){
      navigate(-1);
    }
  }, [from]);

  const { mutate } = useMutation({
    mutationKey: ['create-booking'],
    mutationFn: async (values: BookingForm) => {
      const res = await api.post('/bookings', values);
      return res.data
    },
    onSuccess: () => {
      toast.success(t('alert.booking-request-sent'));
      clearSearch();
      navigate("/pay-done", { replace: true })
    },
    onError: (err: any) => {
      console.log(err);
    }
  });

  const onSubmit = () => {
    if(!guestIds?.length){
      toast.error(t('alert.select-guests'));
      return;
    };

    mutate({
      startDate,
      endDate,
      roomQuantity: roomCount,
      guestIds,
      roomId,
      hotelId,
      paymentMethod: 'cash'
    })
  }

  const handleSelectGuest = (id: string) => {
    const existingGuestIds = guestIds || [];
    const newGuestId = id;

    if (existingGuestIds.length >= guestCount) {

    } else if (!existingGuestIds.includes(newGuestId)) {
      setGuestIds((prevGuestIds) => [...prevGuestIds, newGuestId]);
    }

    if (existingGuestIds.includes(id)) {
      setGuestIds((prevGuestIds) => prevGuestIds.filter((guestId) => guestId !== newGuestId));
    }
  };


  const renderMain = () => {
    return (
      <div className="w-full flex flex-col sm:rounded-2xl sm:border border-neutral-200 dark:border-neutral-700 space-y-8 px-0 sm:p-6 xl:p-8">
        <h2 className="text-3xl lg:text-4xl font-semibold">{t('checkout.confirm-payment')}</h2>
        <div className="border-b border-neutral-200 dark:border-neutral-700"></div>
        <div>
          <div>
            <h3 className="text-2xl font-semibold">{t('checkout.your-trip')}</h3>
            <NcModal
              renderTrigger={(openModal) => (
                <span
                  onClick={() => openModal()}
                  className="block lg:hidden underline  mt-1 cursor-pointer"
                >{t('checkout.booking-details')}</span>
              )}
              renderContent={CheckoutSidebar}
              modalTitle={t('checkout.booking-details')}
            />
          </div>
          <div className="mt-6 border border-neutral-200 dark:border-neutral-700 rounded-3xl flex flex-col sm:flex-row divide-y sm:divide-x sm:divide-y-0 divide-neutral-200 dark:divide-neutral-700 overflow-hidden z-10">
            <div className="text-left flex-1 p-5 flex justify-between space-x-5 hover:bg-neutral-50 dark:hover:bg-neutral-800">
              <div className="flex flex-col">
                <span className="text-sm text-neutral-400">{t('search.check-in-check-out')}</span>
                <span className="mt-1.5 text-lg font-semibold">
                  {checkIn.format("MMM DD")} - {checkOut.format("MMM DD")}
                </span>
              </div>
            </div>

            <div className="text-left flex-1 p-5 flex justify-between space-x-5 hover:bg-neutral-50 dark:hover:bg-neutral-800">
              <div className="flex flex-col">
                <span className="text-sm text-neutral-400">{t('search.guests')}</span>
                <span className="mt-1.5 text-lg font-semibold">
                  <span className="line-clamp-1">
                    {`${guestCount} ${guestCount > 1 ? t("counter.guests.plural") : t("counter.guests.single")}, 
                        ${roomCount} ${roomCount > 1 ? t('counter.rooms.plural') : t('counter.rooms.single')}`}
                  </span>
                </span>
              </div>
            </div>
          </div>
        </div>

        <div>
          <div className="w-full inline-flex justify-between items-center">
            <h3 className="text-xl md:text-2xl font-semibold">{t('checkout.select-guests')} ({guestIds.length}/{guestCount})</h3>
            <ButtonPrimary onClick={() => setModal(true)}>{t('button.add')}</ButtonPrimary>
          </div>
          <div className="w-14 border-b border-neutral-200 dark:border-neutral-700 my-5"></div>

          <div className="mt-6">
            <CheckoutGuests handleChange={handleSelectGuest} guestIds={guestIds} />
          </div>
        </div>

        <div>
          <h3 className="text-xl md:text-2xl font-semibold">{t("checkout.payment-method")}</h3>
          <div className="w-14 border-b border-neutral-200 dark:border-neutral-700 my-5"></div>

          <div className="mt-6">
            <Tab.Group>
              <Tab.List className="flex my-5 gap-1">
                <Tab as={Fragment}>
                  {({ selected }) => (
                    <button
                      disabled
                      className={`px-4 py-1.5 inline-flex items-center  gap-2 sm:px-6 sm:py-2.5 rounded-full focus:outline-none ${selected
                        ? "bg-neutral-800 dark:bg-neutral-200 text-white dark:text-neutral-900"
                        : "text-neutral-6000 dark:text-neutral-400 hover:bg-neutral-100 dark:hover:bg-neutral-800"
                        }`}
                    >
                      <LockClosedIcon className="w-5 h-5" />
                      {t('checkout.card')}
                    </button>
                  )}
                </Tab>
                <Tab as={Fragment}>
                  {({ selected }) => (
                    <button
                      className={`px-4 py-1.5 sm:px-6 sm:py-2.5  rounded-full flex items-center justify-center focus:outline-none  ${selected
                        ? "bg-neutral-800 dark:bg-neutral-200 text-white dark:text-neutral-900"
                        : " text-neutral-6000 dark:text-neutral-400 hover:bg-neutral-100 dark:hover:bg-neutral-800"
                        }`}
                    >
                      <span className="mr-2.5">{t('checkout.cash')}</span>
                    </button>
                  )}
                </Tab>
              </Tab.List>

              <Tab.Panels>
                <Tab.Panel className="space-y-5">
                  <div className="space-y-1">
                    <Label>Card number </Label>
                    <Input defaultValue="111 112 222 999" />
                  </div>
                  <div className="space-y-1">
                    <Label>Card holder </Label>
                    <Input defaultValue="JOHN DOE" />
                  </div>
                  <div className="flex space-x-5  ">
                    <div className="flex-1 space-y-1">
                      <Label>Expiration date </Label>
                      <Input type="date" defaultValue="MM/YY" />
                    </div>
                    <div className="flex-1 space-y-1">
                      <Label>CVC </Label>
                      <Input />
                    </div>
                  </div>
                  <div className="space-y-1">
                    <Label>Messager for author </Label>
                    <Textarea placeholder="..." />
                    <span className="text-sm text-neutral-500 block">
                      Write a few sentences about yourself.
                    </span>
                  </div>
                </Tab.Panel>
                <Tab.Panel className="space-y-5 p-3">
                  <Checkbox
                    defaultChecked={isChecked}
                    onChange={setCheck}
                    name="confirm"
                    label={t('checkout.cash-payment')}
                    subLabel={t('checkout.cash-payment-info')}
                  />
                </Tab.Panel>
              </Tab.Panels>
            </Tab.Group>
            <div className="pt-8">
              <ButtonPrimary type="button" onClick={onSubmit} disabled={!isChecked}>{t('button.confirm')}</ButtonPrimary>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className={`nc-CheckoutPage ${className}`}>
      <main className="container mt-11 mb-24 lg:mb-32 flex flex-col-reverse lg:flex-row">
        <div className="w-full lg:w-3/5 xl:w-2/3 lg:pr-10 ">{renderMain()}</div>
        <div className="hidden lg:block flex-grow">
          <CheckoutSidebar />
        </div>
      </main>
      <Modal title={t('pages.add-guest')} isOpen={showModal} open={() => setModal(false)}>
        <GuestForm toggle={() => setModal(false)} />
      </Modal>
    </div>
  );
};

export default CheckoutPage;
