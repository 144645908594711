import { useQuery } from "@tanstack/react-query";
import api from "api";
import RoomCard from "components/RoomCard/RoomCard";
import useLanguages from "hooks/useLanguages";
import { FC, useMemo } from "react";
import { useParams } from "react-router-dom";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import CommonLayout from "./CommonLayout";

const HotelRooms: FC = () => {
  const { t } = useLanguages();
  const { id } = useParams();
  const { data } = useQuery({
    queryKey: ["rooms", id],
    queryFn: async () => {
      const res = await api.get(`/hotels/${id}/rooms`);
      return res.data
    }
  });
  const rooms = useMemo<IRoom[]>(() => data ? data?.rooms : [], [data]);

  return (
    <CommonLayout>
      <div className="space-y-6">
        <ButtonPrimary href={`/my-hotel/${id}/add-room`}>{t('button.add-room')}</ButtonPrimary>
        <div className="grid grid-cols-1 gap-6 md:gap-8 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
          {rooms.map((stay) => (
            <RoomCard
              key={stay._id}
              href={`/my-hotel/${id}/add-room?roomId=${stay._id}`}
              data={stay}
            />
          ))}
        </div>
      </div>
    </CommonLayout>

  )
};

export default HotelRooms;