import useLanguages from "hooks/useLanguages";
import { FC, useCallback, useState } from "react";
import { useFormContext } from "react-hook-form";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import Input from "shared/Input/Input";
import { SignUpParams } from "./Register";
import useAuth from "hooks/useAuth";
import useCounter from "hooks/useCounter";
import { OtpMethod } from "./OtpMethod";
import toast from "react-hot-toast";

const Otp: FC = () => {
  const { t } = useLanguages();
  const { minutes, seconds, isCompleted, reset } = useCounter(60);
  const { verifyOTP, signUp, otpLoading } = useAuth({});
  const { watch, register, handleSubmit } = useFormContext<SignUpParams & { otp: string }>();
  const [selectedMethod, setMethod] = useState<string>("");

  const onSubmit = useCallback(async (values: SignUpParams & { otp: string }) => {
    verifyOTP(values)
  }, [])

  const sendOTP = useCallback(async (values: SignUpParams) => {
    const { phonenumber, email } = values;
    if (selectedMethod === "") {
      toast.error(t('alert.select-otp-method'));
      return;
    };
    signUp(selectedMethod === "phone" ? { phonenumber } : { email });
    reset()
  }, [selectedMethod]);

  return (
    <>
      <form className="space-y-3" onSubmit={handleSubmit(onSubmit)}>
        <div className="grid grid-cols-1 gap-6">
          <label className="block">
            <span className="text-neutral-800 dark:text-neutral-200">{t('fields.otp')}</span>
            <Input
              type="text"
              autoComplete="off"
              className="mt-1"
              defaultValue={watch("otp")}
              {...register("otp", {
                required: "Required"
              })}
            />
          </label>
          <ButtonPrimary disabled={isCompleted} type="submit" loading={otpLoading}>{t("button.continue")}</ButtonPrimary>
        </div>
      </form>
      <div className="mt-4">
        {!isCompleted ? <p className="text-center text-red-600 font-medium">0{minutes}:{seconds >= 10 ? "" : 0}{seconds}</p> : (
          <form className="space-y-3" onSubmit={handleSubmit(sendOTP)}>
            <button className="w-full text-center text-red-600 hover:text-red-700 font-medium underline transition-colors">{t("alert.expired-otp")}</button>
            <OtpMethod setMethod={setMethod} />
          </form>
        )}
      </div >
    </>
  )
};

export default Otp;