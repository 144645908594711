import useLanguages from "hooks/useLanguages";
import { FC } from "react";

export interface SectionHeroProps {
  className?: string;
  rightImg: string
}

const SectionHero: FC<SectionHeroProps> = ({
  className = "",
  rightImg
}) => {
  const { t, i18n } = useLanguages();
  const boldifyText = (text: string) => {
    let boldWords: string[] = [];

    if (i18n.language === "tm") {
      boldWords = ["Oteller – onlaýn otel bron etmek ulgamy", "Oteller", "smarthotel.com.tm", "SmartHotel", "Mobil", "web", '"Oteller"', "nagt däl", "nagt", "onlaýn töleg", "Maglumat howpsuzlygy"];
    } else if (i18n.language === "ru") {
      boldWords = ["Oteller - система онлайн-бронирования гостиниц", "в мобильном", "в веб-формате", "Oteller", "SmartHotel", "в наличной", "в безналичной", "Информационная безопасность"];
    } else if (i18n.language === "en") {
      boldWords = ["Oteller", "SmartHotel", "Oteller - is an online hotel booking system", "mobile", "web", "cashless", "cash", "online payment", "Information security"]
    }

    let modifiedText = text;

    boldWords.forEach((word) => {
      const escapedWord = word.replace(/[-[\]/{}()*+?.\\^$|]/g, "\\$&"); // Escape special characters
      const regex = new RegExp(`(${escapedWord})`, "gi"); // Add 'u' flag for Unicode support
      modifiedText = modifiedText.replace(regex, "<strong>$1</strong>");
    });

    return { __html: modifiedText }
  };

  return (
    <div
      className={`nc-SectionHero relative space-y-10 ${className}`}
      data-nc-id="SectionHero"
    >
      <div className="flex flex-col lg:flex-row space-y-14 lg:space-y-0 lg:space-x-10 items-center relative text-center lg:text-left">
        <div className="w-screen max-w-full xl:max-w-lg space-y-5 lg:space-y-7">
          <h2 className="text-3xl !leading-tight font-semibold text-neutral-900 md:text-4xl xl:text-5xl dark:text-neutral-100">
            {t('sitemap.about-us')}
          </h2>
          <span className="block text-base xl:text-lg text-neutral-6000 dark:text-neutral-400">
            <p dangerouslySetInnerHTML={boldifyText(t('about-us.heading'))} />
          </span>
        </div>
        <div className="flex-grow">
          <img className="w-full" src={rightImg} alt="" />
        </div>
      </div>
      <div className="text-base xl:text-lg text-neutral-700 dark:text-neutral-300 space-y-4">
        <p className="text-center lg:text-left text-base xl:text-lg text-neutral-700 dark:text-neutral-300"
          dangerouslySetInnerHTML={boldifyText(t('about-us.text1'))} />
        <p className="text-center lg:text-left" dangerouslySetInnerHTML={boldifyText(t('about-us.text2'))} />
        <p className="text-center lg:text-left" dangerouslySetInnerHTML={boldifyText(t('about-us.text3'))} />
        <p className="text-center lg:text-left" dangerouslySetInnerHTML={boldifyText(t('about-us.text4'))} />
      </div>
      <div className="pt-10 space-y-5">
        <h2 className="text-xl font-semibold">{t('about-us.features.title')}</h2>
        <div className="divide-y-[0.5px] divide-neutral-200 dark:divide-neutral-800">
          {featuresText.map((text) => {
            return (
              <div className="py-3 first:border-t-[1px] border-neutral-200 dark:border-neutral-800 last:border-b hover:bg-neutral-50 hover:bg-opacity-40 dark:hover:bg-opacity-30 dark:hover:bg-neutral-800">
                <h3 className="text-lg font-semibold">{t(`${text.title}`)}</h3>
                <p className="text-sm">{t(`${text.desc}`)}</p>
              </div>
            )
          })}
        </div>
      </div>
    </div>
  );
};

const featuresText = [
  { title: "about-us.features.interface.title", desc: "about-us.features.interface.desc" },
  { title: "about-us.features.bookingService.title", desc: "about-us.features.bookingService.desc" },
  { title: "about-us.features.support.title", desc: "about-us.features.support.desc" }
]

export default SectionHero;
