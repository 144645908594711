import { AppContext, AppContextValue } from "context/AppContext"
import { useContext } from "react"

export const useApp = (): AppContextValue => {
  const context = useContext(AppContext);

  if(!context){
    throw new Error("useApp must be used in AppProvider")
  };

  return context
}