import { FC } from "react";

interface SpinnerProps {
  boxSize?: string
  circleSize?: string
}

const Spinner: FC<SpinnerProps> = ({ boxSize= "w-14 h-14", circleSize = "w-3 h-3"}) => {
  return (
    <div className={`spinner ${boxSize}`}>
      <div className={`spinner__dot ${circleSize}`}></div>
    </div>
  )
};

export default Spinner;