import { SearchForm } from "components/Hero/Search";
import { FormProvider, useForm } from "react-hook-form";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import GuestsInput from "./components/GuestInput";
import useSearch from "hooks/useSearch";
import DatesRange from "./components/DatesRange";
import useLanguages from "hooks/useLanguages";
import { useCallback } from "react";

const SearchDetailSidebar = () => {
  const { t } = useLanguages();
  const { startDate, endDate, adultCount, childrenCount, roomCount, saveSearchHotel } = useSearch();
  const formMethods = useForm<SearchForm>({
    defaultValues: {
      startDate,
      endDate,
      adultCount,
      childrenCount,
      roomCount
    }
  });
  const { handleSubmit } = formMethods;

  const onSubmit = useCallback((values: SearchForm) => {
    saveSearchHotel(
      values.startDate,
      values.endDate,
      values.adultCount,
      values.childrenCount,
      values.roomCount
    );
  }, []);

  return (
    <FormProvider {...formMethods}>
      <form className="listingSectionSidebar__wrap shadow-xl" onSubmit={handleSubmit(onSubmit)}>
        {/* PRICE */}
        <div className="flex justify-between">
          <span className="text-xl font-semibold">
            {t('search.change-your-search')}
          </span>
        </div>

        {/* FORM */}
        <div className="flex flex-col border border-neutral-200 dark:border-neutral-700 rounded-3xl ">
          <DatesRange className="flex-1 z-[11]" />
          <div className="w-full border-b border-neutral-200 dark:border-neutral-700"></div>
          <GuestsInput className="flex-1" />
        </div>

        <ButtonPrimary type={"submit"}>{t('button.change')}</ButtonPrimary>
      </form>
    </FormProvider>

  )
};

export default SearchDetailSidebar;