import useAuth from "hooks/useAuth";
import useLanguages from "hooks/useLanguages";
import { FC, useCallback, useState } from "react";
import { useFormContext } from "react-hook-form";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import Input from "shared/Input/Input";
import { OtpMethod } from "./OtpMethod";
import toast from "react-hot-toast";

interface RegisterProps {
  setStep: (value: number) => void
}

export type SignUpParams = {
  firstName: string
  lastName: string
  email: string
  phonenumber: string
  password: string
}

const Register: FC<RegisterProps> = ({ setStep }) => {
  const { t } = useLanguages();
  const { register, watch, handleSubmit } = useFormContext<SignUpParams>();
  const { signUp, registerLoading } = useAuth({ nextStep: setStep });
  const [selectedMethod, setMethod] = useState<string>("");

  const onSubmit = useCallback(async (values: SignUpParams) => {
    if(!selectedMethod) {
      toast.error(t('alert.select-otp-method'));
      return;
    }
    const { phonenumber, email } = values;
    signUp(selectedMethod === "phone" ? { phonenumber } : { email });
  }, [selectedMethod]);

  return (
    <form className="grid grid-cols-1 gap-6" onSubmit={handleSubmit(onSubmit)}>
      <div className="grid sm:grid-cols-2 gap-3">
        <label className="block">
          <span className="text-neutral-800 dark:text-neutral-200">{t('fields.firstName')}</span>
          <Input
            type="text"
            autoComplete="off"
            className="mt-1"
            defaultValue={watch("firstName")}
            {...register("firstName", {
              required: "Required"
            })}
          />
        </label>
        <label className="block">
          <span className="text-neutral-800 dark:text-neutral-200">{t('fields.lastName')}</span>
          <Input
            type="text"
            autoComplete="off"
            className="mt-1"
            defaultValue={watch("lastName")}
            {...register("lastName", {
              required: "Required"
            })}
          />
        </label>
      </div>
      <label className="block">
        <span className="text-neutral-800 dark:text-neutral-200">{t('fields.email')}</span>
        <Input
          type="email"
          autoComplete="off"
          className="mt-1"
          defaultValue={watch("email")}
          {...register("email", {
            required: "Required"
          })}
        />
      </label>
      <label className="block">
        <span className="text-neutral-800 dark:text-neutral-200">{t('fields.phonenumber')}</span>
        <Input
          type="text"
          className="mt-1"
          autoComplete="off"
          defaultValue={watch("phonenumber")}
          {...register("phonenumber", {
            required: "Required"
          })}
        />
      </label>
      <label className="block relative">
        <span className="flex justify-between items-center text-neutral-800 dark:text-neutral-200">{t("fields.password")}</span>
        <Input type="password" className="mt-1"
          autoComplete="off"
          defaultValue={watch("password")}
          {...register("password", {
            required: "Required"
          })}
        />
      </label>
      <OtpMethod
        setMethod={setMethod}
      />
      <ButtonPrimary
        type="submit"
        loading={registerLoading}
      >{t("button.continue")}</ButtonPrimary>
    </form>
  )
};

export default Register;