import aeroport from "images/hotels/aeroport.jpg";
import mary from "images/hotels/mary-hotel.png";
import arcman from "images/hotels/arcman.jpg";
import dashoguz from "images/hotels/dashoguz.jpg";
import jeyhun from "images/hotels/jeyhun.jpg";
import markaw from "images/hotels/markaw.png";
import margus from "images/hotels/margus.png";
import merw from "images/hotels/merw.png";
import yrsgal from "images/hotels/yrsgal.png";
import dayanc from "images/hotels/dayanc.png";
import akyol from "images/hotels/akyol.png";
import turkmenabat from "images/hotels/turkmenabat.png";
import yupekYoly from "images/hotels/yupek-yoly.png";
import kerki from "images/hotels/kerki.png";
import seydi from "images/hotels/seydi.png";
import uzboy from "images/hotels/uzboy.png";
import shabat from "images/hotels/shabat.png";
import erik from "images/hotels/erik.png";
import diwan from "images/hotels/diwan.jpeg";
import yyldyz from "images/hotels/yyldyz.jpeg";
import garagum from "images/hotels/garagum.jpeg";
import olimpiya from "images/hotels/olimpiya.webp";
import arcabil from "images/hotels/arcabil.webp";
import ashgabatAg from "images/hotels/ashgabat-ag.webp";
import grandHotel from "images/hotels/grand-turkmen.jpeg";
import lachynAg from "images/hotels/lachyn-ag.jpeg";
import senagatchyAg from "images/hotels/senagatchy-ag.webp";
import mizan from "images/hotels/mizan.webp";
import aziya from "images/hotels/aziya.webp";
import arkach from "images/hotels/arkach.webp";
import nebitchiAg from "images/hotels/nebitch-ag.jpeg";
import oguzkent from "images/hotels/oguzkent.jpeg";
import sport from "images/hotels/sport.jpeg";
import arkadagAg from "images/hotels/arkadag.jpeg";
import syyahat from "images/hotels/syyahat.jpeg";
import paytagt from "images/hotels/paytagt.jpeg";
import carlak from "images/hotels/carlak.png";
import turkmenbashy from "images/hotels/turkmenbashy.png";
import yupekYolyBn from "images/hotels/yupek-yoly-bn.png";
import nebitchiBn from "images/hotels/nebitchi.png";
import mollagara from "images/hotels/mollagara.png";
import akaltyn from "images/hotels/akaltyn.webp";

import deniz from "images/hotels/deniz.webp";
import gami from "images/hotels/gami.jpeg";
import showhun from "images/hotels/showhun.webp";
import ashgabatCottageComplex from "images/hotels/ashgabat-cottage-complex.webp";
import tolkun from "images/hotels/tolkun.webp";
import hazar from "images/hotels/hazar.webp";
import shamekan from "images/hotels/shamekan.webp";
import seyrana from "images/hotels/seyrana.webp";
import merdana from "images/hotels/merdana.jpeg";
import berkarar from "images/hotels/berkarar.png";
import bereket from "images/hotels/bereket.webp";
import serdar from "images/hotels/serdar.webp";
import nebitchi from "images/hotels/nebitchi-avaza.webp";
import rowach from "images/hotels/rowach.jpeg";
import akyelken from "images/hotels/ak-yelken.webp";
import ahalDynchAlyshWeSagaldysh from "images/hotels/ahal-dynch-alysh-we-sagaldysh-merkezi.webp";
import maryDynchAlyshWeSagaldysh from "images/hotels/mary-dynch-alysh-we-sagaldysh-merkezi.webp";
import watanchy from "images/hotels/watanchy.webp";
import hasyl from "images/hotels/hasyl.webp";
import bagtyyar from "images/hotels/bagtyyar.webp";
import yupekYolyCottageComplex from "images/hotels/yupek-yoly-cottage-complex.webp";
import kerwen from "images/hotels/kerwen.webp";
import dashoguzDynchAlyshWeSagaldysh from "images/hotels/dashoguzDynchAlyshWeSagaldysh.webp";
import lebapDynchAlyshWeSagaldysh from "images/hotels/lebap-dynch-alysh-we-sagaldysh.webp";
import yelken from "images/hotels/yelken.webp";
import hazyna from "images/hotels/hazyna.webp";
import shapak from "images/hotels/shapak.webp";
import kuwwat from "images/hotels/kuwwat.webp";
import galkynysh from "images/hotels/galkynysh.webp";
import ahal from "images/hotels/ahal-ashgabat.jpg";

const hotels = [
  {
    province: "arkadag",
    cover: markaw,
    hotels: [
      {
        id: 1,
        title: {
          tm: "Märkaw",
          en: "Märkaw",
          ru: "Märkaw",
        },
        image: markaw,
        address: {
          tm: "",
          en: "",
          ru: "",
        },
        city: {
          tm: "Arkadag şäheri",
          en: "Arkadag city",
          ru: "Аркадаг",
        },
        contact: {
          phone: "",
        },
      },
    ],
  },
  {
    province: "ashgabat",
    hotels: [
      {
        id: 2,
        image: diwan,
        title: {
          tm: "Diwan",
          en: "Diwan",
          ru: "Diwan",
        },
        address: {
          tm: "1995 (Galkynyş) köçesi, 70",
          en: "1995 (Galkynyş) köçesi, 70",
          ru: "1995 (Galkynyş) köçesi, 70",
        },
        city: {
          tm: "Aşgabat",
          en: "Ashgabat",
          ru: "Ашхабад",
        },
        contact: {
          phone: "+99312221025",
        },
      },
      {
        id: 1,
        title: {
          tm: "Daýhan",
          en: "Daýhan",
          ru: "Daýhan",
        },
        address: {
          tm: "Alişer Nowaýy köçesi, 69",
          en: "Alişer Nowaýy köçesi, 69",
          ru: "Alişer Nowaýy köçesi, 69",
        },
        city: {
          tm: "Aşgabat",
          en: "Ashgabat",
          ru: "Ашхабад",
        },
        contact: {
          phone: "+99312931344",
        },
      },
      {
        id: 3,
        image: yyldyz,
        title: {
          tm: "Ýyldyz",
          en: "Ýyldyz",
          ru: "Ýyldyz",
        },
        address: {
          tm: "2002/05 (Bagtyýarlyk) köçesi, 17",
          en: "2002/05 (Bagtyýarlyk) köçesi, 17",
          ru: "2002/05 (Bagtyýarlyk) köçesi, 17",
        },
        city: {
          tm: "Aşgabat",
          en: "Ashgabat",
          ru: "Ашхабад",
        },
        contact: {
          phone: "+99312390900",
        },
      },
      {
        id: 4,
        image: garagum,
        title: {
          tm: "Garagum",
          en: "Garagum",
          ru: "Garagum",
        },
        address: {
          tm: "A. Nyýazow köçesi, Hoja Ahmet Ýasawy",
          en: "A. Nyýazow köçesi, Hoja Ahmet Ýasawy",
          ru: "A. Nyýazow köçesi, Hoja Ahmet Ýasawy",
        },
        city: {
          tm: "Aşgabat",
          en: "Ashgabat",
          ru: "Ашхабад",
        },
        contact: {
          phone: "+99312400150",
        },
      },
      {
        id: 5,
        image: olimpiya,
        title: {
          tm: "Olimpiýa",
          en: "Olimpiýa",
          ru: "Olimpiýa",
        },
        address: {
          tm: "Türkmenbaşy şaýoly, 17",
          en: "Türkmenbaşy şaýoly, 17",
          ru: "Türkmenbaşy şaýoly, 17",
        },
        city: {
          tm: "Aşgabat",
          en: "Ashgabat",
          ru: "Ашхабад",
        },
        contact: {
          phone: "+99312462918",
        },
      },
      {
        id: 6,
        title: {
          tm: "Arçabil",
          en: "Arçabil",
          ru: "Arçabil",
        },
        image: arcabil,
        address: {
          tm: "Arçabil şaýoly",
          en: "Arçabil şaýoly",
          ru: "Arçabil şaýoly",
        },
        city: {
          tm: "Aşgabat",
          en: "Ashgabat",
          ru: "Ашхабад",
        },
        contact: {
          phone: "+99312400000",
        },
      },
      {
        id: 7,
        image: ashgabatAg,
        title: {
          tm: "Aşgabat",
          en: "Ashgabat",
          ru: "Ашхабад",
        },
        address: {
          tm: "Bagtyýarlyk köçesi, 11",
          en: "Bagtyýarlyk köçesi, 11",
          ru: "Bagtyýarlyk köçesi, 11",
        },
        city: {
          tm: "Aşgabat",
          en: "Ashgabat",
          ru: "Ашхабад",
        },
        contact: {
          phone: "+99312952861",
        },
      },
      {
        id: 8,
        image: grandHotel,
        title: {
          tm: "Grand Turkmen",
          en: "Grand Turkmen",
          ru: "Grand Turkmen",
        },
        address: {
          tm: "Görogly köçesi, 50",
          en: "Görogly köçesi, 50",
          ru: "Görogly köçesi, 50",
        },
        city: {
          tm: "Aşgabat",
          en: "Ashgabat",
          ru: "Ашхабад",
        },
        contact: {
          phone: "+99312920555",
        },
      },
      {
        id: 9,
        image: lachynAg,
        title: {
          tm: "Laçyn",
          en: "Laçyn",
          ru: "Laçyn",
        },
        address: {
          tm: "Bitaraplyk köçesi, 589",
          en: "Bitaraplyk köçesi, 589",
          ru: "Bitaraplyk köçesi, 589",
        },
        city: {
          tm: "Aşgabat",
          en: "Ashgabat",
          ru: "Ашхабад",
        },
        contact: {
          phone: "+99312238661",
        },
      },
      {
        id: 10,
        image: senagatchyAg,
        title: {
          tm: "Senagatçy",
          en: "Senagatchy",
          ru: "Senagatchy",
        },
        address: {
          tm: "Bitaraplyk köçesi, 589",
          en: "Bitaraplyk köçesi, 589",
          ru: "Bitaraplyk köçesi, 589",
        },
        city: {
          tm: "Aşgabat",
          en: "Ashgabat",
          ru: "Ашхабад",
        },
        contact: {
          phone: "+99312488147",
        },
      },
      {
        id: 11,
        image: mizan,
        title: {
          tm: "Mizan",
          en: "Mizan",
          ru: "Mizan",
        },
        address: {
          tm: "Arçabil şaýoly, 52",
          en: "Arçabil şaýoly, 52",
          ru: "Arçabil şaýoly, 52",
        },
        city: {
          tm: "Aşgabat",
          en: "Ashgabat",
          ru: "Ашхабад",
        },
        contact: {
          phone: "+99312488641",
        },
      },
      {
        id: 12,
        image: ahal,
        title: {
          tm: "Ahal",
          en: "Ahal",
          ru: "Ahal",
        },
        address: {
          tm: "Arçabil şaýoly, 36",
          en: "Arçabil şaýoly, 36",
          ru: "Arçabil şaýoly, 36",
        },
        city: {
          tm: "Aşgabat",
          en: "Ashgabat",
          ru: "Ашхабад",
        },
        contact: {
          phone: "+99312488739",
        },
      },
      {
        id: 13,
        image: aziya,
        title: {
          tm: "Aziýa",
          en: "Aziýa",
          ru: "Aziýa",
        },
        address: {
          tm: "Arçabil şaýoly",
          en: "Arçabil şaýoly",
          ru: "Arçabil şaýoly",
        },
        city: {
          tm: "Aşgabat",
          en: "Ashgabat",
          ru: "Ашхабад",
        },
        contact: {
          phone: "+99312480180",
        },
      },
      {
        id: 14,
        title: {
          tm: "Jeýhun",
          en: "Jeýhun",
          ru: "Jeýhun",
        },
        address: {
          tm: "Arçabil şaýoly, 49",
          en: "Arçabil şaýoly, 49",
          ru: "Arçabil şaýoly, 49",
        },
        city: {
          tm: "Aşgabat",
          en: "Ashgabat",
          ru: "Ашхабад",
        },
        contact: {
          phone: "+99312489010",
        },
      },
      {
        id: 15,
        title: {
          tm: "Parfiýa",
          en: "Parfiýa",
          ru: "Parfiýa",
        },
        address: {
          tm: "Arçabil şaýoly, 53",
          en: "Arçabil şaýoly, 53",
          ru: "Arçabil şaýoly, 53",
        },
        city: {
          tm: "Aşgabat",
          en: "Ashgabat",
          ru: "Ашхабад",
        },
        contact: {
          phone: "+99312488191",
        },
      },
      {
        id: 16,
        image: arkach,
        title: {
          tm: "Arkaç",
          en: "Arkaç",
          ru: "Arkaç",
        },
        address: {
          tm: "Arçabil şaýoly, 23",
          en: "Arçabil şaýoly, 23",
          ru: "Arçabil şaýoly, 23",
        },
        city: {
          tm: "Aşgabat",
          en: "Ashgabat",
          ru: "Ашхабад",
        },
        contact: {
          phone: "+99312488651",
        },
      },
      {
        id: 17,
        title: {
          tm: "Rahat",
          en: "Rahat",
          ru: "Rahat",
        },
        address: {
          tm: "Arçabil şaýoly",
          en: "Arçabil şaýoly",
          ru: "Arçabil şaýoly",
        },
        city: {
          tm: "Aşgabat",
          en: "Ashgabat",
          ru: "Ашхабад",
        },
        contact: {
          phone: "+99312489325",
        },
      },
      {
        id: 18,
        title: {
          tm: "Azatlyk",
          en: "Azatlyk",
          ru: "Azatlyk",
        },
        address: {
          tm: "Arçabil şaýoly, 33",
          en: "Arçabil şaýoly, 33",
          ru: "Arçabil şaýoly, 33",
        },
        city: {
          tm: "Aşgabat",
          en: "Ashgabat",
          ru: "Ашхабад",
        },
        contact: {
          phone: "+99312488701",
        },
      },
      {
        id: 19,
        image: nebitchiAg,
        title: {
          tm: "Nebitçi",
          en: "Nebitchi",
          ru: "Nebitchi",
        },
        address: {
          tm: "Arçabil şaýoly, 29",
          en: "Arçabil şaýoly, 29",
          ru: "Arçabil şaýoly, 29",
        },
        city: {
          tm: "Aşgabat",
          en: "Ashgabat",
          ru: "Ашхабад",
        },
        contact: {
          phone: "+99312489360",
        },
      },
      {
        id: 20,
        title: {
          tm: "Mirap",
          en: "Mirap",
          ru: "Mirap",
        },
        address: {
          tm: "Arçabil şaýoly, 19",
          en: "Arçabil şaýoly, 19",
          ru: "Arçabil şaýoly, 19",
        },
        city: {
          tm: "Aşgabat",
          en: "Ashgabat",
          ru: "Ашхабад",
        },
        contact: {
          phone: "+99312489340",
        },
      },
      {
        id: 21,
        title: {
          tm: "Altyn suw",
          en: "Altyn suw",
          ru: "Altyn suw",
        },
        address: {
          tm: "Arçabil şaýoly",
          en: "Arçabil şaýoly",
          ru: "Arçabil şaýoly",
        },
        city: {
          tm: "Aşgabat",
          en: "Ashgabat",
          ru: "Ашхабад",
        },
        contact: {
          phone: "+99312488419",
        },
      },
      {
        id: 22,
        title: {
          tm: "Köpetdag",
          en: "Köpetdag",
          ru: "Köpetdag",
        },
        address: {
          tm: "Arçabil şaýoly, 39",
          en: "Arçabil şaýoly, 39",
          ru: "Arçabil şaýoly, 39",
        },
        city: {
          tm: "Aşgabat",
          en: "Ashgabat",
          ru: "Ашхабад",
        },
        contact: {
          phone: "+99312480335",
        },
      },
      {
        id: 23,
        title: {
          tm: "Gypjak",
          en: "Gypjak",
          ru: "Gypjak",
        },
        address: {
          tm: "",
          en: "",
          ru: "",
        },
        city: {
          tm: "Aşgabat",
          en: "Ashgabat",
          ru: "Ашхабад",
        },
        contact: {
          phone: "+99312257383",
        },
      },
      {
        id: 24,
        image: oguzkent,
        title: {
          tm: "Oguzkent",
          en: "Oguzkent",
          ru: "Oguzkent",
        },
        address: {
          tm: "Bitaraplyk şaýoly, 231",
          en: "Bitaraplyk şaýoly, 231",
          ru: "Bitaraplyk şaýoly, 231",
        },
        city: {
          tm: "Aşgabat",
          en: "Ashgabat",
          ru: "Ашхабад",
        },
        contact: {
          phone: "+99312449500",
        },
      },
      {
        id: 25,
        image: sport,
        title: {
          tm: "Sport",
          en: "Sport",
          ru: "Sport",
        },
        address: {
          tm: "10 ýyl Abadançylyk, Olimpiýa şäherjigi",
          en: "10 ýyl Abadançylyk, Olimpiýa şäherjigi",
          ru: "10 ýyl Abadançylyk, Olimpiýa şäherjigi",
        },
        city: {
          tm: "Aşgabat",
          en: "Ashgabat",
          ru: "Ашхабад",
        },
        contact: {
          phone: "+99312462056",
        },
      },
      {
        id: 26,
        image: arkadagAg,
        title: {
          tm: "Arkadag",
          en: "Arkadag",
          ru: "Arkadag",
        },
        address: {
          tm: "Çandybil şaýoly",
          en: "Çandybil şaýoly",
          ru: "Çandybil şaýoly",
        },
        city: {
          tm: "Aşgabat",
          en: "Ashgabat",
          ru: "Ашхабад",
        },
        contact: {
          phone: "",
        },
      },
      {
        id: 27,
        image: akaltyn,
        title: {
          tm: "Ak Altyn",
          en: "Ak Altyn",
          ru: "Ak Altyn",
        },
        address: {
          tm: "Magtymguly şaýoly, 141/1",
          en: "Magtymguly şaýoly, 141/1",
          ru: "Magtymguly şaýoly, 141/1",
        },
        city: {
          tm: "Aşgabat",
          en: "Ashgabat",
          ru: "Ашхабад",
        },
        contact: {
          phone: "+99312363700",
        },
      },
      {
        id: 28,
        image: syyahat,
        title: {
          tm: "Syýahat",
          en: "Syýahat",
          ru: "Syýahat",
        },
        address: {
          tm: "Görogly köçesi",
          en: "Görogly köçesi",
          ru: "Görogly köçesi",
        },
        city: {
          tm: "Aşgabat",
          en: "Ashgabat",
          ru: "Ашхабад",
        },
        contact: {
          phone: "+99312344119",
        },
      },
      {
        id: 29,
        image: paytagt,
        title: {
          tm: "Paýtagt",
          en: "Paytagt",
          ru: "Paytagt",
        },
        address: {
          tm: "Magtymguly şaýoly, 74",
          en: "Magtymguly şaýoly, 74",
          ru: "Magtymguly şaýoly, 74",
        },
        city: {
          tm: "Aşgabat",
          en: "Ashgabat",
          ru: "Ашхабад",
        },
        contact: {
          phone: "+99312940308",
        },
      },
    ],
  },
  {
    province: "ahal",
    cover: arcman,
    hotels: [
      {
        id: 1,
        image: arcman,
        title: {
          tm: "Arçman şypahanasy",
          en: "Archman resort",
          ru: "Арчман резорт",
        },
        address: {
          tm: "Bäherden etraby",
          en: "Baherden district",
          ru: "Бахерденский район",
        },
        city: {
          tm: "Ahal",
          en: "Ahal",
          ru: "Ахал",
        },
        contact: {
          phone: "(+99312) 921410",
        },
      },
      {
        id: 2,
        image: "",
        title: {
          tm: "Gypjak myhmanhanasy",
          en: "Gypjak hotel",
          ru: "Gypjak otel",
        },
        address: {
          tm: "Gypjak obasy",
          en: "Gypjak",
          ru: "Gypjak",
        },
        city: {
          tm: "Ahal",
          en: "Ahal",
          ru: "Ахал",
        },
      },
    ],
  },
  {
    province: "awaza",
    hotels: [
      {
        id: 1,
        image: deniz,
        title: {
          tm: "Deňiz",
          en: "Deňiz",
          ru: "Deňiz",
        },
        address: {
          tm: "Awaza",
          en: "Avaza",
          ru: "Аваза",
        },
        city: {
          tm: "Balkan",
          en: "Balkan",
          ru: "Балкан",
        },
        contact: {
          phone: "(800243) 5-75-01",
        },
      },
      {
        id: 2,
        image: gami,
        title: {
          tm: "Gämi",
          en: "Gämi",
          ru: "Gämi",
        },
        address: {
          tm: "Awaza",
          en: "Avaza",
          ru: "Аваза",
        },
        city: {
          tm: "Balkan",
          en: "Balkan",
          ru: "Балкан",
        },
        contact: {
          phone: "(800243) 5-79-49",
        },
      },
      {
        id: 3,
        image: showhun,
        title: {
          tm: '"Şowhun" kottejler toplumy',
          en: '"Şowhun" cottage complex',
          ru: '"Şowhun" коттеджный комплекс',
        },
        address: {
          tm: "Awaza",
          en: "Avaza",
          ru: "Аваза",
        },
        city: {
          tm: "Balkan",
          en: "Balkan",
          ru: "Балкан",
        },
        contact: {
          phone: "(800243) 5-79-97",
        },
      },
      {
        id: 4,
        image: galkynysh,
        title: {
          tm: '"Galkynyş" kottejler toplumy',
          en: '"Galkynyş" cottage complex',
          ru: '"Galkynyş" коттеджный комплекс',
        },
        address: {
          tm: "Awaza",
          en: "Avaza",
          ru: "Аваза",
        },
        city: {
          tm: "Balkan",
          en: "Balkan",
          ru: "Балкан",
        },
        contact: {
          phone: "(800243) 5-77-66",
        },
      },
      {
        id: 5,
        image: ashgabatCottageComplex,
        title: {
          tm: '"Aşgabat" kottejler toplumy',
          en: '"Aşgabat" cottage complex',
          ru: '"Aşgabat" коттеджный комплекс',
        },
        address: {
          tm: "Awaza",
          en: "Avaza",
          ru: "Аваза",
        },
        city: {
          tm: "Balkan",
          en: "Balkan",
          ru: "Балкан",
        },
        contact: {
          phone: "(800243) 5-77-51",
        },
      },
      {
        id: 6,
        image: tolkun,
        title: {
          tm: "Tolkun",
          en: "Tolkun",
          ru: "Толкун",
        },
        address: {
          tm: "Awaza",
          en: "Avaza",
          ru: "Аваза",
        },
        city: {
          tm: "Balkan",
          en: "Balkan",
          ru: "Балкан",
        },
        contact: {
          phone: "(800243) 5-78-14",
        },
      },
      {
        id: 7,
        image: hazar,
        title: {
          tm: "Hazar",
          en: "Hazar",
          ru: "Hazar",
        },
        address: {
          tm: "Awaza",
          en: "Avaza",
          ru: "Аваза",
        },
        city: {
          tm: "Balkan",
          en: "Balkan",
          ru: "Балкан",
        },
        contact: {
          phone: "(800243) 5-78-01",
        },
      },
      {
        id: 8,
        image: shamekan,
        title: {
          tm: "Şamekan",
          en: "Şamekan",
          ru: "Şamekan",
        },
        address: {
          tm: "Awaza",
          en: "Avaza",
          ru: "Аваза",
        },
        city: {
          tm: "Balkan",
          en: "Balkan",
          ru: "Балкан",
        },
        contact: {
          phone: "(800243) 5-77-63",
        },
      },
      {
        id: 9,
        image: seyrana,
        title: {
          tm: "Seýrana",
          en: "Seýrana",
          ru: "Seýrana",
        },
        address: {
          tm: "Awaza",
          en: "Avaza",
          ru: "Аваза",
        },
        city: {
          tm: "Balkan",
          en: "Balkan",
          ru: "Балкан",
        },
        contact: {
          phone: "(800243) 5-77-83",
        },
      },
      {
        id: 10,
        image: merdana,
        title: {
          tm: "Merdana",
          en: "Merdana",
          ru: "Merdana",
        },
        address: {
          tm: "Awaza",
          en: "Avaza",
          ru: "Аваза",
        },
        city: {
          tm: "Balkan",
          en: "Balkan",
          ru: "Балкан",
        },
        contact: {
          phone: "(800243) 5-76-51",
        },
      },
      {
        id: 11,
        image: berkarar,
        title: {
          tm: "Berkarar",
          en: "Berkarar",
          ru: "Berkarar",
        },
        address: {
          tm: "Awaza",
          en: "Avaza",
          ru: "Аваза",
        },
        city: {
          tm: "Balkan",
          en: "Balkan",
          ru: "Балкан",
        },
        contact: {
          phone: "(800243) 5-76-35",
        },
      },
      {
        id: 12,
        image: bereket,
        title: {
          tm: "Bereket",
          en: "Bereket",
          ru: "Bereket",
        },
        address: {
          tm: "Awaza",
          en: "Avaza",
          ru: "Аваза",
        },
        city: {
          tm: "Balkan",
          en: "Balkan",
          ru: "Балкан",
        },
        contact: {
          phone: "(800243) 5-76-25",
        },
      },
      {
        id: 13,
        image: serdar,
        title: {
          tm: "Serdar",
          en: "Serdar",
          ru: "Serdar",
        },
        address: {
          tm: "Awaza",
          en: "Avaza",
          ru: "Аваза",
        },
        city: {
          tm: "Balkan",
          en: "Balkan",
          ru: "Балкан",
        },
        contact: {
          phone: "(800243) 3-90-30",
        },
      },
      {
        id: 13,
        image: nebitchi,
        title: {
          tm: "Nebitçi",
          en: "Nebitçi",
          ru: "Nebitçi",
        },
        address: {
          tm: "Awaza",
          en: "Avaza",
          ru: "Аваза",
        },
        city: {
          tm: "Balkan",
          en: "Balkan",
          ru: "Балкан",
        },
        contact: {
          phone: "(800243) 5-75-65",
        },
      },
      {
        id: 14,
        image: rowach,
        title: {
          tm: '"Rowaç" kottejler toplumy',
          en: '"Rowaç" cottage complex',
          ru: '"Rowaç" коттеджный комплекс',
        },
        address: {
          tm: "Awaza",
          en: "Avaza",
          ru: "Аваза",
        },
        city: {
          tm: "Balkan",
          en: "Balkan",
          ru: "Балкан",
        },
        contact: {
          phone: "+99324357881",
        },
      },
      {
        id: 15,
        image: akyelken,
        title: {
          tm: '"Ak Ýelken" kottejler toplumy',
          en: '"Ak Ýelken" cottage complex',
          ru: '"Ak Ýelken" коттеджный комплекс',
        },
        address: {
          tm: "Awaza",
          en: "Avaza",
          ru: "Аваза",
        },
        city: {
          tm: "Balkan",
          en: "Balkan",
          ru: "Балкан",
        },
        contact: {
          phone: "(800243) 5-74-88",
        },
      },
      {
        id: 16,
        image: ahalDynchAlyshWeSagaldysh,
        title: {
          tm: '"Ahal" sagaldyş we dynç alyş merkezi',
          en: '"Ahal" health and recreation center',
          ru: 'Санаторно-курортный комплекс "Ахал"',
        },
        address: {
          tm: "Awaza",
          en: "Avaza",
          ru: "Аваза",
        },
        city: {
          tm: "Balkan",
          en: "Balkan",
          ru: "Балкан",
        },
        contact: {
          phone: "(800243) 5-78-66",
        },
      },
      {
        id: 17,
        image: maryDynchAlyshWeSagaldysh,
        title: {
          tm: '"Mary" ulular we çagalar üçin döwrebap sagaldyş we dynç alyş merkezi',
          en: '"Mary" modern health and recreation center',
          ru: 'современный санаторно-курортный центр "Mary"',
        },
        address: {
          tm: "Awaza",
          en: "Avaza",
          ru: "Аваза",
        },
        city: {
          tm: "Balkan",
          en: "Balkan",
          ru: "Балкан",
        },
        contact: {
          phone: "(800243) 5-78-81",
        },
      },
      {
        id: 18,
        image: watanchy,
        title: {
          tm: "Watançy",
          en: "Watançy",
          ru: "Watançy",
        },
        address: {
          tm: "Awaza",
          en: "Avaza",
          ru: "Аваза",
        },
        city: {
          tm: "Balkan",
          en: "Balkan",
          ru: "Балкан",
        },
        contact: {
          phone: "(800243) 5-75-49",
        },
      },
      {
        id: 19,
        image: shapak,
        title: {
          tm: '"Şapak" kottejler toplumy',
          en: '"Şapak" cottage complex',
          ru: '"Şapak" коттеджный комплекс',
        },
        address: {
          tm: "Awaza",
          en: "Avaza",
          ru: "Аваза",
        },
        city: {
          tm: "Balkan",
          en: "Balkan",
          ru: "Балкан",
        },
        contact: {
          phone: "(800243) 5-76-57",
        },
      },
      {
        id: 20,
        image: hasyl,
        title: {
          tm: '"Hasyl" dynç alyş we sagaldyş merkezi',
          en: '"Hasyl" recreation and health center',
          ru: '"Hasyl" центр отдыха и здоровья',
        },
        address: {
          tm: "Awaza",
          en: "Avaza",
          ru: "Аваза",
        },
        city: {
          tm: "Balkan",
          en: "Balkan",
          ru: "Балкан",
        },
        contact: {
          phone: "(800243) 5-75-95",
        },
      },
      {
        id: 21,
        image: bagtyyar,
        title: {
          tm: "Bagtyýar",
          en: "Bagtyýar",
          ru: "Bagtyýar",
        },
        address: {
          tm: "Awaza",
          en: "Avaza",
          ru: "Аваза",
        },
        city: {
          tm: "Balkan",
          en: "Balkan",
          ru: "Балкан",
        },
        contact: {
          phone: "+99324357770",
        },
      },
      {
        id: 22,
        image: yupekYolyCottageComplex,
        title: {
          tm: '"Ýüpek ýoly" kottejler toplumy',
          en: '"Ýüpek ýoly" cottage complex',
          ru: '"Ýüpek ýoly" коттеджный комплекс',
        },
        address: {
          tm: "Awaza",
          en: "Avaza",
          ru: "Аваза",
        },
        city: {
          tm: "Balkan",
          en: "Balkan",
          ru: "Балкан",
        },
        contact: {
          phone: "(800243) 5-79-76",
        },
      },
      {
        id: 23,
        image: kerwen,
        title: {
          tm: '"Kerwen" sagaldyş merkezi',
          en: '"Kerwen" health center',
          ru: 'Санаторий "Kerwen"',
        },
        address: {
          tm: "Awaza",
          en: "Avaza",
          ru: "Аваза",
        },
        city: {
          tm: "Balkan",
          en: "Balkan",
          ru: "Балкан",
        },
        contact: {
          phone: "(800243) 5-75-14",
        },
      },
      {
        id: 24,
        image: dashoguzDynchAlyshWeSagaldysh,
        title: {
          tm: '"Daşoguz" ulular we çagalar üçin döwrebap sagaldyş we dynç alyş merkezi',
          en: '"Daşoguz" modern health and recreation center',
          ru: 'современный санаторно-курортный центр "Daşoguz"',
        },
        address: {
          tm: "Awaza",
          en: "Avaza",
          ru: "Аваза",
        },
        city: {
          tm: "Balkan",
          en: "Balkan",
          ru: "Балкан",
        },
        contact: {
          phone: "(800243) 5-79-00",
        },
      },
      {
        id: 25,
        image: lebapDynchAlyshWeSagaldysh,
        title: {
          tm: '"Lebap" ulular we çagalar üçin döwrebap sagaldyş we dynç alyş merkezi',
          en: '"Lebap" modern health and recreation center',
          ru: 'современный санаторно-курортный центр "Lebap"',
        },
        address: {
          tm: "Awaza",
          en: "Avaza",
          ru: "Аваза",
        },
        city: {
          tm: "Balkan",
          en: "Balkan",
          ru: "Балкан",
        },
        contact: {
          phone: "(800243) 5-78-92",
        },
      },
      {
        id: 26,
        image: yelken,
        title: {
          tm: '"Ýelken" ýaht klubly syýahatçylyk dynç alyş toplumy',
          en: '"Ýelken" tourist resort complex with yacht club',
          ru: 'Курортно-туристический комплекс "Ýelken" с яхт-клубом',
        },
        address: {
          tm: "Awaza",
          en: "Avaza",
          ru: "Аваза",
        },
        city: {
          tm: "Balkan",
          en: "Balkan",
          ru: "Балкан",
        },
        contact: {
          phone: "(800243) 5-74-88",
        },
      },
      {
        id: 27,
        image: hazyna,
        title: {
          tm: "Hazyna",
          en: "Hazyna",
          ru: "Hazyna",
        },
        address: {
          tm: "Awaza",
          en: "Avaza",
          ru: "Аваза",
        },
        city: {
          tm: "Balkan",
          en: "Balkan",
          ru: "Балкан",
        },
        contact: {
          phone: "(800243) 5-74-94",
        },
      },
      {
        id: 28,
        image: kuwwat,
        title: {
          tm: '"Kuwwat" bejeriş sagaldyş merkezi',
          en: '"Kuwwat" healing center',
          ru: 'Лечебный центр "Kuwwat"',
        },
        address: {
          tm: "Awaza",
          en: "Avaza",
          ru: "Аваза",
        },
        city: {
          tm: "Balkan",
          en: "Balkan",
          ru: "Балкан",
        },
        contact: {
          phone: "(800243) 5-75-27",
        },
      },
    ],
  },
  {
    province: "balkan",
    cover: "images/hotels/balkan.jpeg",
    hotels: [
      {
        id: 1,
        image: carlak,
        title: {
          tm: "Çarlak",
          en: "Charlak",
          ru: "Чарлак",
        },
        address: {
          tm: "Bahry-Hazar köçesi",
          en: "Bahry-Hazar street",
          ru: "улица Бахры-Хазар",
        },
        city: {
          tm: "Türkmenbaşy şäheri",
          ru: "город Туркменбаши",
          en: "Turkmenbashi city",
        },
        contact: {
          phone: "+993 243 50974",
        },
      },
      {
        id: 2,
        image: turkmenbashy,
        title: {
          tm: "Türkmenbaşy",
          en: "Turkmenbashi",
          ru: "Туркменбаши",
        },
        address: {
          tm: "Bahry-Hazar köçesi",
          en: "Bahry-Hazar street",
          ru: "улица Бахры-Хазар",
        },
        city: {
          tm: "Türkmenbaşy şäheri",
          ru: "город Туркменбаши",
          en: "Turkmenbashi city",
        },
        contact: {
          phone: "",
        },
      },
      {
        id: 3,
        image: yupekYolyBn,
        title: {
          tm: "Beýik Ýüpek Ýoly",
          en: "Beyik Yupek Yoly",
          ru: "Beyik Yupek Yoly",
        },
        address: {
          tm: "Halkara deňiz porty",
          en: "International seaport",
          ru: "Международный морской порт",
        },
        city: {
          tm: "Türkmenbaşy",
          en: "Туркменбаши",
          ru: "Turkmenbashi",
        },
        contact: {
          phone: "+993 243 49610",
        },
      },
      {
        id: 4,
        image: nebitchiBn,
        title: {
          tm: "Nebitçi",
          en: "Nebitchi",
          ru: "Nebitchi",
        },
        address: {
          tm: "Kwartal 198",
          en: "Kwartal 198",
          ru: "Kwartal 198",
        },
        city: {
          tm: "Balkanabat şäheri",
          en: "Balkanabat city",
          ru: "город Балканабад",
        },
        contact: {
          phone: "+993 222 77039",
        },
      },
      {
        id: 5,
        image: mollagara,
        title: {
          tm: "“Mollagara” şypahanasy",
          en: "Mollagara resort",
          ru: "Моллагара резорт",
        },
        address: {
          tm: "Jebel şäheri",
          ru: "Джебель",
          en: "Jebel",
        },
        city: {
          tm: "Balkanabat",
          en: "Balkanabat",
          ru: "Балканабад",
        },
        contact: {
          phone: "+993 222 91019",
        },
      },
    ],
  },
  {
    province: "dashoguz",
    cover: "images/hotels.dashoguz.jpg",
    hotels: [
      {
        id: 1,
        image: dashoguz,
        title: {
          tm: "Daşoguz",
          en: "Dashoguz",
          ru: "Дашогуз",
        },
        address: {
          tm: "Ruhnama köçesi, №100",
          en: "Ruhnama köçesi, №100",
          ru: "Ruhnama köçesi, №100",
        },
        city: {
          tm: "Daşoguz",
          en: "Dashoguz",
          ru: "Дашогуз",
        },
        contact: {
          phone: "(+99322) 21340",
        },
      },
      {
        id: 2,
        image: uzboy,
        title: {
          tm: "Uzboý",
          en: "Uzboý",
          ru: "Uzboý",
        },
        address: {
          tm: "Türkmenbaşy köçesi, №19/1",
          en: "Türkmenbaşy köçesi, №19/1",
          ru: "Türkmenbaşy köçesi, №19/1",
        },
        city: {
          tm: "Daşoguz",
          en: "Dashoguz",
          ru: "Дашогуз",
        },
      },
      {
        id: 3,
        image: shabat,
        title: {
          tm: "Şabat",
          en: "Şabat",
          ru: "Şabat",
        },
        address: {
          tm: "Türkmenbaşy köçesi, 19/1",
          en: "Türkmenbaşy köçesi, 19/1",
          ru: "Türkmenbaşy köçesi, 19/1",
        },
        city: {
          tm: "Daşoguz",
          en: "Dashoguz",
          ru: "Дашогуз",
        },
      },
      {
        id: 4,
        image: erik,
        title: {
          tm: "Erik",
          en: "Erik",
          ru: "Erik",
        },
        address: {
          tm: "Al Horezmi köçesi  №35A",
          en: "Al Horezmi köçesi  №35A",
          ru: "Al Horezmi köçesi  №35A",
        },
        city: {
          tm: "Daşoguz",
          en: "Dashoguz",
          ru: "Дашогуз",
        },
      },
    ],
  },
  {
    province: "mary",
    cover: mary,
    hotels: [
      {
        id: 1,
        image: mary,
        title: {
          tm: "Mary",
          en: "Mary",
          ru: "Мары",
        },
        address: {
          tm: "Mollanepes köçesi",
          en: "Mollanepes Street",
          ru: "Улица Молланепес",
        },
        city: {
          tm: "Mary",
          en: "Mary",
          ru: "Мары",
        },
        contact: {
          phone: "(+993) 52267951",
        },
      },
      {
        id: 2,
        image: merw,
        title: {
          tm: "Merw",
          en: "Merv",
          ru: "Мерв",
        },
        address: {
          tm: "Murgap köçesi",
          en: "Murgab street",
          ru: "Мургабская улица",
        },
        city: {
          tm: "Mary",
          en: "Mary",
          ru: "Мары",
        },
        contact: {
          phone: "(+993) 52264592",
        },
      },
      {
        id: 3,
        image: margus,
        title: {
          tm: "Marguş",
          en: "Margush",
          ru: "Marguş",
        },
        address: {
          tm: "Gowşut Han köçesi",
          en: "Govshut Khan street",
          ru: "улица Говшут-хана",
        },
        city: {
          tm: "Mary",
          en: "Mary",
          ru: "Мары",
        },
        contact: {
          phone: "(+993) 52260387",
        },
      },
      {
        id: 4,
        image: yrsgal,
        title: {
          tm: "Yrsgal",
          en: "Yrsgal",
          ru: "Ырсгал",
        },
        address: {
          tm: "Ata Köpek Mergen köçesi",
          en: "Ata Kopek Mergen str.",
          ru: "Ata Köpek Mergen köçesi",
        },
        city: {
          tm: "Mary",
          en: "Mary",
          ru: "Мары",
        },
        contact: {
          phone: "",
        },
      },
      {
        id: 5,
        image: dayanc,
        title: {
          tm: "Daýanç",
          en: "Dayanch",
          ru: "Даянч",
        },
        address: {
          tm: "Sakarçäge etraby",
          en: "Sakarchage district",
          ru: "Sakarchage etraby",
        },
        city: {
          tm: "Mary",
          en: "Mary",
          ru: "Мары",
        },
        contact: {
          phone: "",
        },
      },
      {
        id: 6,
        image: akyol,
        title: {
          tm: "Ak ýol",
          en: "Ak yol",
          ru: "Ak yol",
        },
        address: {
          tm: "Üçajy obasy",
          en: "Üçajy obasy",
          ru: "Üçajy obasy",
        },
        city: {
          tm: "Mary",
          en: "Mary",
          ru: "Мары",
        },
        contact: {
          phone: "",
        },
      },
    ],
  },
  {
    province: "lebap",
    cover: "images/hotels/lebap1.jpg",
    hotels: [
      {
        id: 1,
        title: {
          tm: "Jeýhun",
          en: "Jeyhun",
          ru: "Jeyhun",
        },
        address: {
          tm: "Bitarap Türkmenistan köçesi, №106",
          en: "Bitarap Türkmenistan köçesi, №106",
          ru: "Bitarap Türkmenistan köçesi, №106",
        },
        image: jeyhun,
        city: {
          tm: "Türkmenabat",
          en: "Türkmenabat",
          ru: "Türkmenabat",
        },
        contact: {
          phone: "(+993) 42221041",
        },
      },
      {
        id: 2,
        title: {
          tm: "Aeroport",
          en: "Aeroport",
          ru: "Aeroport",
        },
        address: {
          tm: "Halkara howa menzili",
          en: "Halkara howa menzili",
          ru: "Halkara howa menzili",
        },
        city: {
          tm: "Türkmenabat",
          en: "Türkmenabat",
          ru: "Türkmenabat",
        },
        image: aeroport,
        contact: {
          phone: "(+993) 42294681",
        },
      },
      {
        id: 3,
        image: turkmenabat,
        title: {
          tm: "Türkmenabat",
          en: "Türkmenabat",
          ru: "Türkmenabat",
        },
        address: {
          tm: "Magtymguly köçesi, 49",
          en: "Magtymguly köçesi, 49",
          ru: "Magtymguly köçesi, 49",
        },
        city: {
          tm: "Türkmenabat",
          en: "Türkmenabat",
          ru: "Türkmenabat",
        },
        contact: {
          phone: "(+993) 42260226",
        },
      },
      {
        id: 4,
        title: {
          tm: "Ýüpek ýoly",
          en: "Ýüpek ýoly",
          ru: "Ýüpek ýoly",
        },
        image: yupekYoly,
        address: {
          tm: "",
          en: "",
          ru: "",
        },
        city: {
          tm: "Türkmenabat",
          en: "Türkmenabat",
          ru: "Türkmenabat",
        },
        contact: {
          phone: "(+993) 42231227",
        },
      },
      {
        id: 5,
        title: {
          tm: "Amyderýa",
          en: "Amyderýa",
          ru: "Amyderýa",
        },
        address: {
          tm: "S.A.Nyýazow köçesi",
          en: "S.A.Nyýazow köçesi",
          ru: "S.A.Nyýazow köçesi",
        },
        city: {
          tm: "Türkmenabat",
          en: "Türkmenabat",
          ru: "Türkmenabat",
        },
        contact: {
          phone: "(+993) 42231430",
        },
      },
      {
        id: 6,
        title: {
          tm: "Ogulbeg",
          en: "Ogulbeg",
          ru: "Ogulbeg",
        },
        address: {
          tm: "Kerki şäheri",
          en: "Kerki şäheri",
          ru: "Kerki şäheri",
        },
        city: {
          tm: "Lebap",
          en: "Lebap",
          ru: "Lebap",
        },
        contact: {
          phone: "(+993444) 25560",
        },
      },
      {
        id: 7,
        image: kerki,
        title: {
          tm: "Kerki",
          en: "Kerki",
          ru: "Kerki",
        },
        address: {
          tm: "Kerki şäheri",
          en: "Kerki şäheri",
          ru: "Kerki şäheri",
        },
        city: {
          tm: "Lebap",
          en: "Lebap",
          ru: "Lebap",
        },
        contact: {
          phone: "",
        },
      },
      {
        id: 8,
        image: seydi,
        title: {
          tm: "Seýdi",
          en: "Seýdi",
          ru: "Seydi",
        },
        address: {
          tm: "Dänew etraby, Seýdi şäheri",
          en: "Dänew etraby, Seýdi şäheri",
          ru: "Dänew etraby, Seýdi şäheri",
        },
        city: {
          tm: "Lebap",
          en: "Lebap",
          ru: "Lebap",
        },
        contact: {
          phone: "",
        },
      },
    ],
  },
];

export default hotels;
