import { FC } from "react";
import StartRating from "components/StartRating/StartRating";
import { Link } from "react-router-dom";
import BtnLikeIcon from "components/BtnLikeIcon/BtnLikeIcon";
import useLanguages from "hooks/useLanguages";
import icons from "contains/icons";
import Gallery from "components/Gallery/Gallery";
import { IMAGE_PATH } from "api";

export interface HotelCardHProps {
  className?: string;
  data: IHotel;
  href: string
}

const HotelCardH: FC<HotelCardHProps> = ({
  className = "",
  data,
  href
}) => {
  const { t, i18n } = useLanguages();
  const {
    _id,
    title,
    cheapestPrice,
    amenities,
    address,
    images,
    region,
    rating
  } = data;
  const point = rating?.score / rating?.count;
  const formattedPoint = isNaN(point) ? 0 : point.toFixed(1);
  const hotelImages = images.map((image) => `${IMAGE_PATH}/hotels/${image}`);

  const renderSliderGallery = () => {
    return (
      <div className="relative flex-shrink-0 w-full md:w-72 ">
        <Gallery
          ratioClass="aspect-w-6 aspect-h-5"
          galleryImgs={hotelImages}
          uniqueID={`HotelCardH_${_id}`}
          href={href}
        />
        <BtnLikeIcon hotelId={_id} className="absolute right-3 top-3" />
      </div>
    );
  };

  const renderTienIch = () => {
    return (
      <div className="hidden sm:grid grid-cols-3 gap-2">
        <div className="space-y-3">
          {amenities?.slice(0, 2).map((amenity) => (
            <div className="flex items-center space-x-3">
              <i className={`${icons.find(icon => icon.label === amenity)?.value} text-lg`}></i>
              <span className="text-sm text-neutral-500 dark:text-neutral-400 text-ellipsis truncate">{t(`amenities.${amenity}`)}</span>
            </div>
          ))}
        </div>
        <div className="space-y-3">
          {amenities?.slice(2, 4).map((amenity) => (
            <div className="flex items-center space-x-3">
              <i className={`${icons.find(icon => icon.label === amenity)?.value} text-lg`}></i>
              <span className="text-sm text-neutral-500 dark:text-neutral-400 text-ellipsis truncate">{t(`amenities.${amenity}`)}</span>
            </div>
          ))}
        </div>
        <div className="space-y-3">
          {amenities?.slice(4, 6).map((amenity) => (
            <div className="flex items-center space-x-3">
              <i className={`${icons.find(icon => icon.label === amenity)?.value} text-lg`}></i>
              <span className="text-sm text-neutral-500 dark:text-neutral-400 text-ellipsis truncate">{t(`amenities.${amenity}`)}</span>
            </div>
          ))}
        </div>
      </div>
    );
  };

  const renderContent = () => {
    return (
      <div className="flex-grow p-3 sm:p-5 flex flex-col">
        <div className="space-y-2">
          <div className="text-sm text-neutral-500 dark:text-neutral-400">
            <span>
              {address[i18n.language as "en" | "tm" | "ru"]}, {t(`regions.${region}`)}
            </span>
          </div>
          <div className="flex items-center space-x-2">
            {/* {isAds && <Badge name="ADS" color="green" />} */}
            <h2 className="text-lg font-medium capitalize">
              <span className="line-clamp-1">{title[i18n.language as "en" | "tm" | "ru"]}</span>
            </h2>
          </div>
        </div>
        <div className="hidden sm:block w-14 border-b border-neutral-100 dark:border-neutral-800 my-4"></div>
        {renderTienIch()}
        <div className="w-14 border-b border-neutral-100 dark:border-neutral-800 my-4"></div>
        <div className="flex justify-between items-end">
          <StartRating reviewCount={rating?.count} point={formattedPoint as number} />
          {cheapestPrice && <span className="text-base font-semibold text-secondary-500">
            {cheapestPrice}TMT
            {` `}
            <span className="text-sm text-neutral-500 dark:text-neutral-400 font-normal">
              /{t('general.night')}
            </span>
          </span>}
        </div>
      </div>
    );
  };

  return (
    <div
      className={`nc-StayCardH group relative bg-white dark:bg-neutral-900 border border-neutral-100 dark:border-neutral-800 rounded-2xl overflow-hidden hover:shadow-xl transition-shadow will-change-transform ${className}`}
      data-nc-id="StayCardH"
    >
      <Link to={href} className="absolute inset-0"></Link>
      <div className="grid grid-cols-1 md:flex md:flex-row ">
        {renderSliderGallery()}
        {renderContent()}
      </div>
    </div>
  );
};

export default HotelCardH;
