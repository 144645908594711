import { ChevronLeftIcon } from "@heroicons/react/24/outline";
import useLanguages from "hooks/useLanguages";
import { FC } from "react";
import { Link, NavLink, useNavigate, useParams } from "react-router-dom";
import DeleteHotel from "./DeleteHotel";

export interface CommonLayoutProps {
  children?: React.ReactNode;
}

const CommonLayout: FC<CommonLayoutProps> = ({ children }) => {
  const { t } = useLanguages();
  const navigate = useNavigate();
  const { id } = useParams();


  const pages = [
    {
      name: "rooms",
      href: `/my-hotel/${id}/rooms`,
    },
    {
      name: "bookings",
      href: `/my-hotel/${id}/reservations`
    }
  ]

  return (
    <div className="nc-CommonLayoutProps bg-neutral-50 dark:bg-neutral-900">
      <div className="border-b border-neutral-200 dark:border-neutral-700 bg-white dark:bg-neutral-800">
        <div className="container w-full">
          <div className="flex space-x-8 md:space-x-14 overflow-x-auto hiddenScrollbar">
            <button onClick={() => navigate("/author")}>
              <ChevronLeftIcon className="w-5 h-5" />
            </button>
            {pages.map((page) => {
              return (
                <NavLink
                  key={page.name}
                  to={page.href}
                  className={({ isActive }) =>
                    `block py-5 md:py-8 border-b-2 flex-shrink-0 ${!isActive ? "border-transparent" : "border-secondary-900"
                    }`
                  }
                >
                  {t(`pages.${page.name}`)}
                </NavLink>
              )
            })}
            <Link
              to={`/list-your-hotel?hotelId=${id}`}
              className={`block py-5 md:py-8 border-b-2 border-transparent hover:dark:border-orange-500 hover:border-orange-500 flex-shrink-0`}
            >
              {t(`pages.edit-hotel`)}
            </Link>
            <DeleteHotel />
          </div>
        </div>
      </div>
      <div className="container pt-14 pb-24 lg:pb-32">{children}</div>
    </div>
  );
};

export default CommonLayout;