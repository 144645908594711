import { useMutation, useQueryClient } from "@tanstack/react-query";
import { hotelsApi } from "api/hotel";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import useLanguages from "./useLanguages";
import { roomsApi } from "api/room";

const useListing = () => {
  const { t } = useLanguages();
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const { mutate: create } = useMutation({
    mutationKey: ["create-hotel"],
    mutationFn: hotelsApi.create,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["hotels"] });
      navigate("/")
    },
    onError: (err: any) => {
      toast.error(t('alert.something-went-wrong'));
    }
  });

  const { mutate: update } = useMutation({
    mutationKey: ['update-hotel'],
    mutationFn: hotelsApi.update,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["hotels"] });
      navigate('/author');
    },
    onError: (err: any) => {
      toast.error(t('alert.something-went-wrong'));
    }
  })

  const { mutate: remove } = useMutation({
    mutationKey: ["remove-hotel"],
    mutationFn: hotelsApi.remove,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["hotels"] });
      navigate('/author');
    },
    onError: (err: any) => {
      toast.error(t('alert.something-went-wrong'));
    }
  })

  const { mutate: createRoom } = useMutation({
    mutationKey: ["create-room"],
    mutationFn: roomsApi.create,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["rooms"] });
      navigate(-1);
    },
    onError: (err: any) => {
      toast.error(t('alert.something-went-wrong'));
    }
  })

  const { mutate: updateRoom } = useMutation({
    mutationKey: ["create-room"],
    mutationFn: roomsApi.update,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["rooms"] });
      navigate(-1);
    },
    onError: (err: any) => {
      toast.error(t('alert.something-went-wrong'));
    }
  });

  const { mutate: removeRoom } = useMutation({
    mutationKey: ['delete-room'],
    mutationFn: roomsApi.remove,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["rooms"] });
      navigate(-1);
    },
    onError: (err: any) => {
      toast.error(t('alert.something-went-wrong'));
    }
  })

  return { create, update, remove, createRoom, updateRoom, removeRoom };
};

export default useListing;