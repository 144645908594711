import SectionHero from "components/SectionHero/SectionHero";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import SectionVideos from "./SectionVideos";
import SectionDowloadApp from "./SectionDowloadApp";
import React, { useMemo } from "react";
import SectionRegions from "./SectionRegions";
import { useQuery } from "@tanstack/react-query";
import api from "api";

function PageHome(): React.JSX.Element {
  const { data } = useQuery({
    queryKey: ['regions'],
    queryFn: async () => {
      const res = await api.get('/hotels/regions');
      return res.data;
    }
  });
  const regions = useMemo<IRegion[]>(() => data || [], [data]);
  
  return (
    <div className="nc-PageHome relative overflow-hidden">
      <BgGlassmorphism />
      <div className="container relative space-y-24 mb-24 lg:space-y-28 lg:mb-28">
        <SectionHero className="pt-10 lg:pt-16 lg:pb-16" />
        <SectionRegions uniqueClassName="PageHome_s1" regions={regions} />
        <SectionVideos />
        <SectionDowloadApp />
      </div>
    </div>
  );
}

export default PageHome;
