import { Fragment, FC } from "react";
import { Popover, Transition } from "@headlessui/react";
import { CalendarIcon } from "@heroicons/react/24/outline";
import DatePicker from "react-datepicker";
import ButtonClear from "./ButtonClear";
import DatePickerCustomHeaderTwoMonth from "components/DatePickerCustomHeaderTwoMonth";
import DatePickerCustomDay from "components/DatePickerCustomDay";
import { useFormContext } from "react-hook-form";
import { SearchForm } from "./Search";
import useLanguages from "hooks/useLanguages";
import useConvertDates from "hooks/useConvertDates";
import localizeDayNames from "utils/localizeDayNames";

export interface DatesInputProps {
  className?: string;
  fieldClassName?: string;
}

const DatesInput: FC<DatesInputProps> = ({
  className = "[ lg:nc-flex-2 ]",
  fieldClassName = "[ nc-hero-field-padding ]",
}) => {
  const { t, i18n } = useLanguages();
  const { setValue, watch } = useFormContext<SearchForm>();
  const startDate = useConvertDates(watch("startDate"));
  const endDate = useConvertDates(watch("endDate"));

  const onChangeDate = (dates: [Date | null, Date | null]) => {
    const [start, end] = dates;
    setValue("startDate", start);
    setValue("endDate", end);
  };

  const renderInput = () => {
    return (
      <>
        <div className="text-neutral-300 dark:text-neutral-400">
          <CalendarIcon className="w-5 h-5 lg:w-7 lg:h-7" />
        </div>
        <div className="flex-grow text-left">
          <span className="block xl:text-lg font-semibold">
            {watch("startDate") ? startDate.format("DD MMM") : t('placeholder.add-dates')}
            {watch('endDate') ? " - " + endDate.format("DD MMM") : ""}
          </span>
          <span className="block mt-1 text-sm text-neutral-400 leading-none font-light">
            {t('search.check-in-check-out')}
          </span>
        </div>
      </>
    );
  };

  return (
    <Popover className={`DatesInput z-10 relative flex ${className}`}>
      {({ open }) => (
        <>
          <Popover.Button
            className={`flex-1 z-10 flex relative ${fieldClassName} items-center space-x-3 focus:outline-none ${open ? "nc-hero-field-focused" : ""
              }`}
            onClickCapture={() => document.querySelector("html")?.click()}
          >
            {renderInput()}
            {watch('startDate') && open && (
              <ButtonClear onClick={() => onChangeDate([null, null])} />
            )}
          </Popover.Button>

          {open && (
            <div className="h-8 absolute self-center top-1/2 -translate-y-1/2 z-0 -inset-x-0.5 bg-white dark:bg-neutral-800"></div>
          )}

          <Transition
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1"
          >
            <Popover.Panel className="absolute left-1/2 z-10 mt-3 top-full w-screen max-w-sm -translate-x-1/2 transform px-4 sm:px-0 lg:max-w-3xl">
              <div className="overflow-hidden rounded-3xl shadow-lg ring-1 ring-black ring-opacity-5 bg-white dark:bg-neutral-800 p-8">
                <DatePicker
                  selected={watch("startDate")}
                  onChange={onChangeDate}
                  startDate={watch("startDate")}
                  endDate={watch('endDate')}
                  minDate={new Date()}
                  maxDate={new Date(new Date().setMonth(new Date().getMonth() + 2))}
                  selectsRange
                  monthsShown={2}
                  showPopperArrow={false}
                  inline
                  renderCustomHeader={(p) => (
                    <DatePickerCustomHeaderTwoMonth {...p} />
                  )}
                  renderDayContents={(day, date) => (
                    <DatePickerCustomDay dayOfMonth={day} date={date} />
                  )}
                  formatWeekDay={(day) => {
                    const dayName = localizeDayNames(day, i18n.language);
                    return dayName
                  }}
                />
              </div>
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  );
};

export default DatesInput;
