import api from "api";

class Guests {
  async create(values: any){
    const res = await api.post('/guests', values);
    return res.data
  };

  async update({id, values }: { id: string, values: any}){
    const res = await api.put(`/guests/${id}`, values);
    return res.data;
  };

  async remove(id: string){
    const res = await api.delete(`/guests/${id}`);
    return res.data;
  }

  async getAll(){
    const res = await api.get('/guests');
    return res.data
  };

  async getById(id: string){
    const res = await api.get(`/guests/${id}`);
    return res.data
  }
};

export const guestsApi = new Guests();