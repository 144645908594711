import DatePickerCustomDay from "components/DatePickerCustomDay";
import DatePickerCustomHeaderTwoMonth from "components/DatePickerCustomHeaderTwoMonth";
import useLanguages from "hooks/useLanguages";
import { FC } from "react";
import DatePicker from "react-datepicker";
import localizeDayNames from "utils/localizeDayNames";

interface DatesRangeProps {
  onChangeDate: (startDate: Date | null, endDate: Date | null) => void
  startDate: Date | null
  endDate: Date | null
};

const DatesRange: FC<DatesRangeProps> = ({ startDate, endDate, onChangeDate }) => {
  const { i18n } = useLanguages();

  const onChange = (dates: [Date | null, Date | null]) => {
    const [start, end] = dates;
    onChangeDate(start, end);
  };

  return (
    <div>
      <DatePicker
        selected={startDate}
        onChange={onChange}
        startDate={startDate}
        endDate={endDate}
        minDate={new Date()}
        maxDate={new Date(new Date().setMonth(new Date().getMonth() + 2))}
        selectsRange
        monthsShown={2}
        showPopperArrow={false}
        inline
        renderCustomHeader={(p) => <DatePickerCustomHeaderTwoMonth {...p} />}
        renderDayContents={(day, date) => (
          <DatePickerCustomDay dayOfMonth={day} date={date} />
        )}
        formatWeekDay={(day) => {
          const dayName = localizeDayNames(day, i18n.language);
          return dayName
        }}
      />
    </div>
  )
};

export default DatesRange;