import { useQuery } from "@tanstack/react-query";
import api from "api";
import { createContext, ReactNode, useEffect, useMemo } from "react";

export interface AppContextValue {
  user: IUser,
  isLoggedIn: boolean,
  isReady: boolean
}

export const AppContext = createContext<AppContextValue | undefined>(undefined)

export const AppProvider = ({ children }: { children: ReactNode }) => {
  const { data: user, refetch, isError, isLoading } = useQuery({
    queryKey: ["current-user"],
    queryFn: async () => {
      const res = await api.get("/users/details");
      return res.data
    },
    retry: false,
    refetchOnWindowFocus: false
  });

  useEffect(() => {
    if (!isError) {
      refetch();
    };
  }, [isError]);

  const isReady = useMemo(() => isLoading, [isLoading]);

  return <AppContext.Provider value={{
    user,
    isLoggedIn: !isError,
    isReady
  }}>{children}</AppContext.Provider>
}

export const AppConsumer = AppContext.Consumer;