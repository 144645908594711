import { FC, useMemo } from "react";
import CommonLayout from "./CommonLayout";
import useLanguages from "hooks/useLanguages";
import { Helmet } from "react-helmet"
import { useQuery } from "@tanstack/react-query";
import api from "api";
import BookingCard from "components/BookingCard/BookingCard";

const AccountBookings: FC = () => {
  const { t } = useLanguages();
  const { data } = useQuery({
    queryKey: ["bookings"],
    queryFn: async () => {
      const res = await api.get('/bookings');
      return res.data;
    }
  });
  const bookings = useMemo<IBooking[]>(() => data || [], [data]);

  return (
    <div>
      <Helmet>
        <title>{t('pages.bookings')} | Oteller</title>
      </Helmet>
      <CommonLayout>
        <div className="space-y-6 sm:space-y-8">
          <div>
            <h2 className="text-3xl font-semibold">{t('pages.bookings')}</h2>
          </div>
          <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
          <div className="mt-8">
            <div className="grid grid-cols-1 gap-6 lg:gap-8 lg:grid-cols-2">
              {bookings.map((booking) => <BookingCard key={booking._id} {...booking} />)}
            </div>
          </div>
        </div>
      </CommonLayout>
    </div>
  );
};

export default AccountBookings;
