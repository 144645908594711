import moment from "moment";

const dayNameToIndex: any = {
  "Monday": 1,
  "Tuesday": 2,
  "Wednesday": 3,
  "Thursday": 4,
  "Friday": 5,
  "Saturday": 6,
  "Sunday": 0,
};

const localizeDayNames = (day: string, lang: string) => {
  const dayIndex = dayNameToIndex[day];
  const dayName = moment().locale(lang === "tm" ? "tk" : lang === "ru" ? "ru" : "en").weekday(dayIndex).format("dd");

  return dayName;
}

export default localizeDayNames;