import api from "api";

class Hotels {
  async create(values: FormData) {
    const res = await api.post("/hotels", values);
    return res.data;
  }

  async update({ id, values }: { id: string; values: FormData }) {
    const res = await api.put(`/hotels/${id}`, values);
    return res.data;
  }

  async remove(id: string) {
    const res = await api.delete(`/hotels/${id}`);
    return res.data;
  }

  async getById(id: string) {
    const res = await api.get(`/hotels/${id}`);
    return res.data;
  }
}

export const hotelsApi = new Hotels();
