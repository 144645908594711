import DatePicker from "react-datepicker";
import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/solid";
import React, { FC, Fragment, useCallback, useState } from "react";
import DatePickerCustomHeaderTwoMonth from "components/DatePickerCustomHeaderTwoMonth";
import DatePickerCustomDay from "components/DatePickerCustomDay";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import localizeDayNames from "utils/localizeDayNames";
import useLanguages from "hooks/useLanguages";
import NcInputNumber from "components/NcInputNumber/NcInputNumber";
import useSearch from "hooks/useSearch";
import { useForm } from "react-hook-form";
import { SearchForm } from "components/Hero/Search";

interface ModalSearchFormProps {
  renderChildren?: (p: { openModal: () => void }) => React.ReactNode;
}

const ModalSearchForm: FC<ModalSearchFormProps> = ({ renderChildren }) => {
  const [showModal, setShowModal] = useState(false);
  const { t, i18n } = useLanguages();
  const { startDate, endDate, adultCount, childrenCount, roomCount, saveSearchHotel } = useSearch();
  const { watch, setValue, handleSubmit } = useForm<SearchForm>({
    defaultValues: {
      startDate,
      endDate,
      adultCount,
      childrenCount,
      roomCount
    }
  });

  const onChangeDate = (dates: [Date | null, Date | null]) => {
    const [start, end] = dates;
    setValue("startDate", start);
    setValue("endDate", end);
  };

  function closeModal() {
    setShowModal(false);
  }

  const onSubmit = useCallback((values: SearchForm) => {
    saveSearchHotel(
      values.startDate,
      values.endDate,
      values.adultCount,
      values.childrenCount,
      values.roomCount
    );
    closeModal()
  }, []);

  function openModal() {
    setShowModal(true);
  }

  const renderButtonOpenModal = () => {
    return renderChildren ? (
      renderChildren({ openModal })
    ) : (
      <button onClick={openModal}>Select Date</button>
    );
  };

  return (
    <>
      {renderButtonOpenModal()}
      <Transition appear show={showModal} as={Fragment}>
        <Dialog
          as="div"
          className="HeroSearchFormMobile__Dialog relative z-50"
          onClose={closeModal}
        >
          <div className="fixed inset-0 bg-neutral-100 dark:bg-neutral-900">
            <div className="flex h-full">
              <Transition.Child
                as={Fragment}
                enter="ease-out transition-transform"
                enterFrom="opacity-0 translate-y-52"
                enterTo="opacity-100 translate-y-0"
                leave="ease-in transition-transform"
                leaveFrom="opacity-100 translate-y-0"
                leaveTo="opacity-0 translate-y-52"
              >
                <Dialog.Panel as={"form"} onSubmit={handleSubmit(onSubmit)} className="relative h-full overflow-hidden flex-1 flex flex-col justify-between ">
                  <>
                    <div className="absolute left-4 top-4">
                      <button
                        className="focus:outline-none focus:ring-0"
                        onClick={closeModal}
                      >
                        <XMarkIcon className="w-5 h-5 text-black dark:text-white" />
                      </button>
                    </div>

                    <div className="flex-1 pt-12 pb-6 px-4 flex flex-col gap-4 overflow-auto">
                      <div className="flex-1 flex flex-col bg-white dark:bg-neutral-800 rounded-2xl">
                        <div className="w-full flex flex-col transition-opacity animate-[myblur_0.4s_ease-in-out] overflow-auto">
                          <div>
                            <div className="p-5">
                              <span className="block font-semibold text-xl sm:text-2xl">
                                {t('search.when-is-trip')}
                              </span>
                            </div>
                            <div
                              className={`relative flex-shrink-0 flex justify-center z-10 py-5`}
                            >
                              <DatePicker
                                selected={watch("startDate")}
                                onChange={onChangeDate}
                                startDate={watch("startDate")}
                                endDate={watch("endDate")}
                                minDate={new Date()}
                                maxDate={new Date(new Date().setMonth(new Date().getMonth() + 2))}
                                selectsRange
                                monthsShown={2}
                                showPopperArrow={false}
                                inline
                                renderCustomHeader={(p) => <DatePickerCustomHeaderTwoMonth {...p} />}
                                renderDayContents={(day, date) => (
                                  <DatePickerCustomDay dayOfMonth={day} date={date} />
                                )}
                                formatWeekDay={(day) => {
                                  const dayName = localizeDayNames(day, i18n.language);
                                  return dayName
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="flex-1 flex flex-col bg-white dark:bg-neutral-800 rounded-2xl">
                        <div className={`flex flex-col relative p-5`}>
                          <span className="mb-5 block font-semibold text-xl sm:text-2xl">
                            {t('search.who-is-coming')}
                          </span>
                          <NcInputNumber
                            className="w-full"
                            defaultValue={watch("adultCount")}
                            onChange={(value) => setValue("adultCount", value)}
                            max={10}
                            min={1}
                            label={t('fields.adults')}
                            desc={t('placeholder.over-twelve')}
                          />
                          <NcInputNumber
                            className="w-full mt-6"
                            defaultValue={watch('childrenCount')}
                            onChange={(value) => setValue("childrenCount", value)}
                            max={10}
                            min={0}
                            label={t('fields.children')}
                            desc={t('placeholder.upto-twelve')}
                          />

                          <NcInputNumber
                            className="w-full mt-6"
                            defaultValue={watch("roomCount")}
                            onChange={(value) => setValue("roomCount", value)}
                            max={10}
                            min={1}
                            label={t('fields.rooms')}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="px-4 py-3 bg-white dark:bg-neutral-900 border-t border-neutral-200 dark:border-neutral-700 flex justify-end">
                      <ButtonPrimary
                        sizeClass="px-6 py-3 !rounded-xl"
                        type={"submit"}
                      >
                        {t("button.save")}
                      </ButtonPrimary>
                    </div>
                  </>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

export default ModalSearchForm;
