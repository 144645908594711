import { Tab } from "@headlessui/react";
import FormItem from "components/FormItem";
import useLanguages from "hooks/useLanguages";
import { FC } from "react";
import { useFormContext } from "react-hook-form";
import Input from "shared/Input/Input";

const ListingRoom1: FC = () => {
  const { t } = useLanguages();
  const { register } = useFormContext<ListingRoomType>();

  return (
    <>
      <div>
        <h2 className="text-2xl font-semibold">{t('listing-room.room-name')}</h2>
        <p className="text-neutral-500 dark:text-neutral-400">{t('listing-room.fill-fields')}</p>
      </div>
      <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
      <Tab.Group>
        <Tab.List className={"flex"}>
          {["tm", "en", "ru"].map((lang) => (
            <Tab key={lang}>
              {({ selected }) => (
                <div
                  className={`flex-shrink-0 block !leading-none font-medium px-3 py-2.5 text-center uppercase focus:outline-none focus:border-none focus:ring-0 ${selected ? "opacity-100 border-b-2 border-primary-6000" : "opacity-80"}`}>
                  {lang}
                </div>
              )}
            </Tab>
          ))}
        </Tab.List>
        <Tab.Panels>
          <Tab.Panel>
            <FormItem label={""}>
              <Input
                type={"text"}
                autoComplete="off"
                {...register("title.tm", { required: "Required" })}
              />
            </FormItem>
          </Tab.Panel>
          <Tab.Panel>
            <FormItem label={""}>
              <Input
                type={"text"}
                autoComplete="off"
                {...register("title.en", { required: "Required" })}
              />
            </FormItem>
          </Tab.Panel>
          <Tab.Panel>
            <FormItem label={""}>
              <Input
                type={"text"}
                autoComplete="off"
                {...register("title.ru", { required: "Required" })}
              />
            </FormItem>
          </Tab.Panel>
        </Tab.Panels>
      </Tab.Group>
    </>
  )
};

export default ListingRoom1;