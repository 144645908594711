import { useMutation, useQueryClient } from "@tanstack/react-query";
import { guestsApi } from "api/guest";
import GuestForm from "components/GuestForm/GuestForm";
import useConvertDates from "hooks/useConvertDates";
import useLanguages from "hooks/useLanguages";
import { FC, useState } from "react";
import { Modal } from "shared/Modal/Modal";

interface GuestCardProps {
  guest: IGuest
}

const GuestCard: FC<GuestCardProps> = ({ guest }) => {
  const queryClient = useQueryClient();
  const [isShown, setShow] = useState<boolean>(false);
  const { t } = useLanguages();
  const { _id, firstName, lastName, birthDate, gender } = guest;
  const date = useConvertDates(birthDate);

  const { mutate: remove } = useMutation({
    mutationKey: ['remove-guest'],
    mutationFn: guestsApi.remove,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["guests"] });
      setShow(false);
    }
  });

  return (
    <>
      <div onClick={() => setShow(true)} className={`nc-GuestCard group relative bg-white dark:bg-neutral-900 border border-neutral-200 dark:border-neutral-700 rounded-xl shadow-sm hover:shadow-md transition-shadow`}>
        <div className="flex p-3 gap-4">
          <div className="bg-primary-50 dark:bg-neutral-800 h-fit p-3 rounded-xl text-neutral-700 dark:text-neutral-300">
            <h2 className="uppercase text-lg font-medium">{firstName[0]}.{lastName[0]}</h2>
          </div>
          <div className="flex-1 flex flex-col lg:flex-row justify-between lg:items-center">
            <div className="flex-1" >
              <h2 className="text-base md:text-xl font-medium">{firstName} {lastName}</h2>
              <div className="inline-flex space-x-3">
                <p>{date.format("DD.MM.YYYY")}</p>
                <span>•</span>
                <p>{t(`gender.${gender}`)}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal title={`${firstName} ${lastName}`} isOpen={isShown} open={() => setShow(false)}>
        <GuestForm
          toggle={() => setShow(false)}
          guest={guest}
          onDelete={() => remove(_id)}
        />
      </Modal>
    </>
  )
};

export default GuestCard;