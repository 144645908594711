import { Dialog } from "@headlessui/react"
import { XMarkIcon } from "@heroicons/react/24/outline"
import { FC, ReactNode } from "react"

interface ModalProps {
  title: string
  isOpen: boolean
  open: () => void
  children: ReactNode
}

export const Modal: FC<ModalProps> = ({ title, isOpen, open, children }) => {
  return (
    <Dialog open={isOpen} onClose={open} as={"div"} className="relative z-[9999] focus:outline-none">
      <div className="fixed inset-0 z-10 w-screen backdrop-blur-sm overflow-y-auto">
        <div className="flex min-h-full items-center justify-center p-4 space-y-3">
          <Dialog.Panel className={"w-full max-w-md rounded-xl border border-neutral-200 dark:border-neutral-700 bg-white dark:bg-neutral-800 p-4 backdrop-blur-2xl duration-300 ease-out data-[closed]:transform-[scale(95%)] data-[closed]:opacity-0 transition-all"}>
            <Dialog.Title className={"px-2 pb-2 w-full inline-flex justify-end items-center"}>
              <h2 className="flex-1 font-medium text-center">{title}</h2>
              <button onClick={open}>
                <XMarkIcon className="w-4 h-4" />
              </button>
            </Dialog.Title>
            {children}
          </Dialog.Panel>
        </div>
      </div>
    </Dialog>
  )
}