import { useMutation, useQueryClient } from "@tanstack/react-query";
import api from "api";
import { useApp } from "hooks/useApp";
import { FC } from "react";

export interface BtnLikeIconProps {
  className?: string;
  colorClass?: string;
  hotelId: string
}

const BtnLikeIcon: FC<BtnLikeIconProps> = ({
  className = "",
  colorClass = "text-white bg-black bg-opacity-30 hover:bg-opacity-50",
  hotelId
}) => {
  const queryClient = useQueryClient();
  const { isLoggedIn, user } = useApp();
  const isLiked = user?.favorites.includes(hotelId);
  const { mutate } = useMutation({
    mutationKey: ["add-favorite"],
    mutationFn: async (id: string) => {
      const res = await api.put(`/favorites/${id}`);
      return res.data
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["current-user"]});
      queryClient.invalidateQueries({ queryKey: ["favorites"]})
    }
  });

  return (
    <div
      className={`nc-BtnLikeIcon w-8 h-8 ${isLoggedIn && user?.role === "user" ? "flex" : "hidden"} items-center justify-center rounded-full cursor-pointer ${
        isLiked ? "nc-BtnLikeIcon--liked" : ""
      }  ${colorClass} ${className}`}
      data-nc-id="BtnLikeIcon"
      title="Save"
      onClick={() => mutate(hotelId)}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="h-5 w-5"
        fill={isLiked ? "currentColor" : "none"}
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={1.5}
          d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z"
        />
      </svg>
    </div>
  );
};

export default BtnLikeIcon;
