import { FC, useCallback } from "react";
import { Helmet } from "react-helmet";
import SocialsList from "shared/SocialsList/SocialsList";
import Label from "components/Label/Label";
import Input from "shared/Input/Input";
import Textarea from "shared/Textarea/Textarea";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import useLanguages from "hooks/useLanguages";
import { useForm } from "react-hook-form";
import { useMutation } from "@tanstack/react-query";
import api from "api";
import toast from "react-hot-toast";

export interface PageContactProps {
  className?: string;
}

export type ContactParams = {
  name: string
  email: string
  message: string
}

const info = [
  {
    title: "address.label",
    desc: "address.value",
  },
  {
    title: "email.label",
    desc: "email.value",
  },
  {
    title: "phone.label",
    desc: "phone.value",
  },
];

const PageContact: FC<PageContactProps> = ({ className = "" }) => {
  const { t } = useLanguages();
  const { register, watch, handleSubmit, reset } = useForm<ContactParams>();
  const { mutate } = useMutation({
    mutationKey: ["contact-us"],
    mutationFn: async (values: ContactParams) => {
      const res = await api.post("/contact-us", values)
      return res.data;
    },
    onSuccess: () => {
      toast.success(t('alert.message-sent'));
      reset({
        name: "",
        email: "",
        message: ""
      });
    },
    onError: () => {
      toast.error(t('alert.something-went-wrong'));
    }
  })

  const onSubmit = useCallback(async (values: ContactParams) => {
    mutate(values)
  }, []);

  return (
    <div
      className={`nc-PageContact overflow-hidden ${className}`}
      data-nc-id="PageContact"
    >
      <Helmet>
        <title>{t('sitemap.contact-us')} | Oteller</title>
      </Helmet>
      <div className="mb-24 lg:mb-32">
        <div className="mt-20 md:mt-32 container max-w-7xl mx-auto">
          <div className="flex-shrink-0 grid grid-cols-1 sm:grid-cols-2 gap-12 ">
            <div className="max-w-sm space-y-8">
              <h2 className="text-xl font-semibold text-primary-700 dark:text-primary-500">{t('sitemap.contact-us')}</h2>
              <h2 className="text-4xl font-bold">{t('general.contact-description')}</h2>
              {info.map((item, index) => (
                <div key={index}>
                  <h3 className="uppercase font-semibold text-sm dark:text-neutral-200 tracking-wider">
                    {t(`contact.${item.title}`)}
                  </h3>
                  <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
                    {t(`contact.${item.desc}`)}
                  </span>
                </div>
              ))}
              <div>
                <SocialsList className="mt-2" />
              </div>
            </div>
            <div>
              <form className="grid grid-cols-1 gap-6" onSubmit={handleSubmit(onSubmit)}>
                <label className="block">
                  <Label>{t('fields.fullName')}</Label>
                  <Input
                    type="text"
                    className="mt-1"
                    autoComplete="off"
                    defaultValue={watch("name")}
                    {...register("name", {
                      required: "Required"
                    })}
                  />
                </label>
                <label className="block">
                  <Label>{t('fields.email')}</Label>
                  <Input
                    type="email"
                    className="mt-1"
                    autoComplete="off"
                    defaultValue={watch("email")}
                    {...register("email", {
                      required: "Required"
                    })}
                  />
                </label>
                <label className="block">
                  <Label>{t('fields.message')}</Label>
                  <Textarea
                    className="mt-1"
                    rows={6}
                    defaultValue={watch("message")}
                    {...register("message", {
                      required: "Required"
                    })}
                  />
                </label>
                <div>
                  <ButtonPrimary type="submit">{t('button.send')}</ButtonPrimary>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PageContact;
