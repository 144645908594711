import { TrashIcon } from "@heroicons/react/24/outline";
import { BASE_URL } from "api";
import useLanguages from "hooks/useLanguages";
import { ChangeEvent, DragEvent, FC, useMemo, useState } from "react";
import { useFormContext } from "react-hook-form";
import toast from "react-hot-toast";
import NcImage from "shared/NcImage/NcImage";

interface Image {
  file?: File
  url?: string
}

const ListingHotel4: FC = () => {
  const { t } = useLanguages();
  const [ isDragging, setDrag ] = useState<boolean>(false);
  const { watch, setValue } = useFormContext<ListingRoomType>();
  const imagesFromApi = watch("images");
  const formFiles = watch("files");

  const images: Image[] = useMemo(() => [
    ...(imagesFromApi ? imagesFromApi.map((imageURL) => ({ url: imageURL })) : []),
    ...(formFiles ? formFiles.map((file) => ({ file })) : [])
  ], [imagesFromApi, formFiles]);

  const onUpload = (e: ChangeEvent<HTMLInputElement> | DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
    
    let files;
    if (e.type === 'drop') {
      files = (e as DragEvent).dataTransfer.files;
    } else {
      files = (e as ChangeEvent<HTMLInputElement>).target.files;
    }

    if (!files) {
      return;
    }

    if (files.length + images.length > 10) {
      toast.error(t('alert.upload-10-files'));
    } else {
      const newFiles = Array.from(files).filter((file) =>
        formFiles ? !formFiles.some((formFile) => formFile.name === file.name)
          : true
      );

      if (newFiles.length < files.length) {
        toast.error(t('alert.duplicate-files'))
      };

      setValue("files", [...(formFiles || []), ...newFiles])
    };

    if (e.type !== 'drop') {
      (e as ChangeEvent<HTMLInputElement>).target.value = ""
    }
  };

  const onRemove = (image: Image) => {
    if (image.file) {
      setValue("files", formFiles ? formFiles.filter((formFile) => formFile.name !== image.file?.name) : [])
    } else if (image.url) {
      setValue("images", imagesFromApi.filter((imageURL) => imageURL !== image.url))
    }
  };

  const handleDragOver = (e: DragEvent) => {
    e.preventDefault();
    setDrag(true);
  };

  const handleDragEnter = (e: DragEvent) => {
    e.preventDefault();
    setDrag(true);
  };

  const handleDragLeave = (e: DragEvent) => {
    e.preventDefault();
    setDrag(false);
  };

  const handleDrop = (e: DragEvent) => {
    onUpload(e);
  };

  const renderImages = useMemo(() => {
    return images.map((image, i) => (
      <div key={i} className="py-4 relative w-full inline-flex items-center gap-4">
        <div className="w-16 h-14 rounded-lg overflow-hidden">
          <NcImage
            className="w-16 h-14 object-cover"
            src={image.file ?
              URL.createObjectURL(image.file) :
              `${BASE_URL}/uploads/rooms/${image.url}`}
            alt={`hotel-image-${i}`}
          />
        </div>
        <p className="flex-1">{image.file ? image.file.name : image.url}</p>
        <div>
          <button
            onClick={() => onRemove(image)}
            type={"button"}
            className="p-2 bg-neutral-200 dark:bg-neutral-700 hover:bg-red-600 dark:hover:bg-red-600 hover:text-white dark:hover:text-white rounded-lg transition-colors">
            <TrashIcon className="w-5 h-5" />
          </button>
        </div>
      </div>
    ))
  }, [images]);

  return (
    <>
      <h2 className="text-2xl font-semibold">{t('listing-hotel.pictures')}</h2>
      <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
      <div className="space-y-8">
        <div>
          <div className="mt-5 ">
            <div 
            onDragOver={handleDragOver}
            onDragEnter={handleDragEnter}
            onDragLeave={handleDragLeave}
            onDrop={handleDrop}
            className={`mt-1 flex justify-center px-6 pt-5 pb-6 border-2 ${isDragging ? "border-primary-6000" : "border-neutral-300"} dark:border-neutral-6000 border-dashed rounded-md`}>
              <div className="space-y-1 text-center">
                <svg
                  className="mx-auto h-12 w-12 text-neutral-400"
                  stroke="currentColor"
                  fill="none"
                  viewBox="0 0 48 48"
                  aria-hidden="true"
                >
                  <path
                    d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  ></path>
                </svg>
                <div className="flex text-sm text-neutral-6000 dark:text-neutral-300">
                  <label
                    htmlFor="file-upload-2"
                    className="relative cursor-pointer  rounded-md font-medium text-primary-6000 hover:text-primary-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-primary-500"
                  >
                    <span>{t('listing-hotel.upload-file')}</span>
                    <input
                      id="file-upload-2"
                      name="file-upload-2"
                      type="file"
                      multiple
                      accept="image/.jpg,.jpeg,.png,.webp"
                      onChange={onUpload}
                      className="sr-only"
                    />
                  </label>
                  <p className="pl-1">{t('listing-hotel.drag-n-drop')}</p>
                </div>
                <p className="text-xs text-neutral-500 dark:text-neutral-400">{t('listing-hotel.image-types')}</p>
              </div>
            </div>
            <div className="pt-6 divide-y-[1px] divide-neutral-200 dark:divide-neutral-700">{renderImages}</div>
          </div>
        </div>
      </div>
    </>
  )
};

export default ListingHotel4;