import React from "react";
import { Link } from "react-router-dom";
import logoImg from "images/logo-light.svg";
import logoLightImg from "images/logo-dark.svg";

export interface LogoProps {
  className?: string;
}

const Logo: React.FC<LogoProps> = ({
  className = "w-24",
}) => {
  return (
    <Link
      to="/"
      className={`ttnc-logo inline-block text-primary-6000 focus:outline-none focus:ring-0 ${className}`}
    >

      <img
        className={`block max-h-12 ${logoLightImg ? "dark:hidden" : ""}`}
        src={logoImg}
        alt="Logo"
      />
      {logoLightImg && (
        <img
          className="hidden max-h-12 dark:block"
          src={logoLightImg}
          alt="Logo-Light"
        />
      )}
    </Link>
  );
};

export default Logo;
