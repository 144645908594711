import { useMutation } from "@tanstack/react-query";
import api from "api";
import FormItem from "components/FormItem";
import { OtpMethod } from "containers/PageSignUp/OtpMethod";
import useLanguages from "hooks/useLanguages";
import { FC, useCallback } from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import Input from "shared/Input/Input";

type ChooseMethodType = {
  phonenumber?: string
  email?: string
}

interface ChooseMethodProps {
  next: () => void
  method: string
  setMethod: (method: string) => void
  onChange: (prev: any) => void
}

const ChooseMethod: FC<ChooseMethodProps> = ({ next, method, setMethod, onChange }) => {
  const { t } = useLanguages();
  const { register, watch, handleSubmit } = useForm<ChooseMethodType>({ defaultValues: {
    phonenumber: "",
    email: ""
  }});
  const { mutate } = useMutation({
    mutationKey: ["send-otp"],
    mutationFn: async (values: ChooseMethodType) => {
      const res = await api.post('/auth/forgot', values)
      return res.data
    },
    onSuccess: () => {
      next();
      toast.success(t('alert.otp-sent'))
      if (method === "email") {
        onChange((prev: any) => ({ ...prev, email: watch("email") }))
      }
      if (method === "phone") {
        onChange((prev: any) => ({ ...prev, phonenumber: watch('phonenumber') }))
      }
    },
    onError: (err: any) => {
      if (err.response.status === 404) {
        toast.error(t('alert.wrong-credentials'))
      } else {
        toast.error(t('alert.something-went-wrong'))
      }
    }
  });

  const onSubmit = useCallback(async (values: ChooseMethodType) => {
    if (method === "email") {
      return mutate({ email: values.email })
    }

    if (method === "phone") {
      return mutate({ phonenumber: values.phonenumber })
    }
  }, [method]);

  return (
    <form className="space-y-5" onSubmit={handleSubmit(onSubmit)}>
      <div>
        <OtpMethod
          defaultValue={method}
          setMethod={setMethod}
        />
      </div>
      {method === "email" ? (
        <FormItem label={t('fields.email')}>
          <Input
            id={"email"}
            value={watch('email')}
            type={"text"}
            {...register("email")}
          />
        </FormItem>
      ) : (
        <FormItem label={t('fields.phonenumber')}>
          <Input
            id={"phonenumber"}
            value={watch("phonenumber")}
            type={"text"}
            {...register('phonenumber')}
          />
        </FormItem>
      )}
      <div>
        <ButtonPrimary disabled={!method || (method === "email" && !watch("email")) || (method === "phone" && !watch("phonenumber"))} className="w-full">{t('button.continue')}</ButtonPrimary>
      </div>
    </form>
  )
};

export default ChooseMethod;