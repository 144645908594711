
import { CheckCircleIcon } from "@heroicons/react/24/solid";
import useLanguages from "hooks/useLanguages";
import { FC } from "react";
import ButtonPrimary from "shared/Button/ButtonPrimary";

export interface PayPageProps {
  className?: string;
}

const PayPage: FC<PayPageProps> = ({ className = "" }) => {
  const { t } = useLanguages();

  const renderContent = () => {
    return (
      <div className="w-full flex flex-col items-center sm:rounded-2xl sm:border border-neutral-200 dark:border-neutral-700 space-y-4 px-0 sm:p-6 xl:p-8">
        <div className="space-y-3 flex flex-col items-center">
          <CheckCircleIcon className="w-20 h-20" color="rgb(20, 184, 166)" />
          <h2 className="text-3xl lg:text-4xl font-semibold inline-flex space-x-3 items-center">
            {t('general.payment-done')}
          </h2>
        </div>
        <div className="text-center space-y-3">
          <p>{t('general.booking-payment')}</p>
          <p className="text-lg font-medium">{t('general.have-a-good-trip')}</p>
        </div>
        <div>
          <ButtonPrimary href={"/bookings"}>{t('button.bookings')}</ButtonPrimary>
        </div>
      </div>
    );
  };

  return (
    <div className={`nc-PayPage ${className}`} data-nc-id="PayPage">
      <main className="container mt-11 mb-24 lg:mb-32 ">
        <div className="max-w-4xl mx-auto">{renderContent()}</div>
      </main>
    </div>
  );
};

export default PayPage;
