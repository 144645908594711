import NcInputNumber from "components/NcInputNumber/NcInputNumber";
import FormItem from "components/FormItem";
import useLanguages from "hooks/useLanguages";
import { FC } from "react";
import { useFormContext } from "react-hook-form";
import Input from "shared/Input/Input";

const roomTypes = ["Single room", "Double room", "Triple room", "Quad", "Suite", "Deluxe room"]

const ListingRoom2: FC = () => {
  const { t } = useLanguages();
  const { watch, register, setValue } = useFormContext<ListingRoomType>();

  return (
    <>
      <h2 className="text-2xl font-semibold">{t('listing-room.size-of-room')}</h2>
      <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>

      <div className="space-y-8">
        <FormItem label={t('listing-room.price')}>
          <Input
            autoComplete="off"
            type={"number"}
            min={0}
            defaultValue={watch("price")}
            {...register("price", { required: "Required" })}
          />
        </FormItem>
        <FormItem label={t('listing-room.type')}>
          <select className="p-3 rounded-2xl capitalize"
            defaultValue={watch("type")}
            {...register("type", { required: "Required"})}
          >
            {roomTypes.map((type) => (
              <option value={type}>{t(`room-types.${type}`)}</option>
            ))}
          </select>
        </FormItem>
        <FormItem label={t('listing-room.quantity')}>
          <Input
            autoComplete="off"
            min={0}
            type={"number"}
            defaultValue={watch("quantity")}
            {...register("quantity", { required: "Required" })} />
        </FormItem>
        <NcInputNumber
          onChange={(value) => setValue("guestCapacity", value)}
          label={t('listing-room.guests')}
          defaultValue={watch("guestCapacity")}
        />
        <NcInputNumber
          onChange={(value) => setValue("bedCount", value)}
          label={t('listing-room.beds')}
          defaultValue={watch("bedCount")}
        />
        <NcInputNumber
          onChange={(value) => setValue("bathCount", value)}
          label={t('listing-room.bath')}
          defaultValue={watch('bathCount')}
        />
      </div>
    </>
  )
};

export default ListingRoom2;