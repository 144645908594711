import FormItem from "components/FormItem";
import useCounter from "hooks/useCounter";
import useLanguages from "hooks/useLanguages";
import { FC } from "react";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import Input from "shared/Input/Input";

interface ValidateOTPProps {
  defaultValue: string
  setStep: (value: number) => void
  onChange: (value: string) => void
  onSubmit: (e: any) => void
}

const ValidateOTP: FC<ValidateOTPProps> = ({ defaultValue, setStep, onChange, onSubmit }) => {
  const { t } = useLanguages();
  const { minutes, seconds, isCompleted } = useCounter(60);

  return (
    <form className="space-y-3" onSubmit={onSubmit}>
      <FormItem label={t('fields.otp')}>
        <Input
          type={"text"}
          defaultValue={defaultValue}
          onChange={(e) => onChange(e.target.value)}
        />
      </FormItem>
      <div className="mt-4">
        {!isCompleted ? <p className="text-center text-red-600 font-medium">0{minutes}:{seconds >= 10 ? "" : 0}{seconds}</p> : (
          <button
            type={"button"}
            className="w-full text-center text-red-600 hover:text-red-700"
            onClick={() => setStep(2)}>{t('alert.expired-otp')}</button>
        )}
      </div >
      <div>
        <ButtonPrimary disabled={!defaultValue} type={"submit"} className="w-full">{t('button.confirm')}</ButtonPrimary>
      </div>
    </form>
  )
};

export default ValidateOTP;