import api from "api";

class Rooms {
  async create({ id, values }: { id: string; values: FormData }) {
    const res = await api.post(`/hotels/${id}/rooms`, values);
    return res.data;
  };

  async update({ id, roomId, values}: { id: string, roomId: string, values: FormData}){
    const res = await api.put(`/hotels/${id}/rooms/${roomId}`, values);
    return res.data
  };

  async remove({ id, roomId }: { id: string, roomId: string }){
    const res = await api.delete(`/hotels/${id}/rooms/${roomId}`);
    return res.data;
  }
};

export const roomsApi = new Rooms();
