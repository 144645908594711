import { FC, Fragment, useMemo, useState } from 'react'
import SearchDetailLayout from './SearchDetailLayout'
import { Squares2X2Icon } from '@heroicons/react/24/outline'
import { useNavigate, useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import api, { IMAGE_PATH } from 'api';
import useSearch from 'hooks/useSearch';
import useLanguages from 'hooks/useLanguages';
import Badge from 'shared/Badge/Badge';
import LikeSaveBtns from 'components/LikeSaveBtns';
import StartRating from 'components/StartRating/StartRating';
import icons from 'contains/icons';
import ButtonSecondary from 'shared/Button/ButtonSecondary';
import RoomCard from 'components/RoomCard/RoomCard';
import { Dialog, Transition } from '@headlessui/react';
import ButtonClose from 'shared/ButtonClose/ButtonClose';
import Comments from './Comments';
import SearchDetailSidebar from './SearchDetailSidebar';
import Spinner from 'shared/Spinner/Spinner';

const SearchDetailHeader: FC<{ images: string[] }> = ({ images }) => {
  const { t } = useLanguages();
  const router = useNavigate();

  const handleOpenModalImageGallery = () => {
    router(`?modal=PHOTO_TOUR_SCROLLABLE`);
  };

  return (
    <header className="rounded-md sm:rounded-xl">
      <div className="relative grid grid-cols-3 sm:grid-cols-4 gap-1 sm:gap-2">
        <div
          className="col-span-2 row-span-3 sm:row-span-2 relative rounded-md sm:rounded-xl overflow-hidden cursor-pointer "
          onClick={handleOpenModalImageGallery}
        >
          <img
            className="absolute inset-0 object-cover rounded-md sm:rounded-xl w-full h-full"
            src={images ? `${IMAGE_PATH}/hotels/${images[0]}` : ""}
            alt=""
            sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 50vw"
          />
          <div className="absolute inset-0 bg-neutral-900 bg-opacity-20 opacity-0 hover:opacity-100 transition-opacity"></div>
        </div>
        {images?.filter((_, i) => i >= 1 && i < 5).map((image, index) => (
          <div
            key={index}
            className={`relative rounded-md sm:rounded-xl overflow-hidden ${index >= 3 ? "hidden sm:block" : ""
              }`}
          >
            <div className="aspect-w-4 aspect-h-3 sm:aspect-w-6 sm:aspect-h-5">
              <img
                className="absolute inset-0 object-cover rounded-md sm:rounded-xl w-full h-full"
                src={`${IMAGE_PATH}/hotels/${image}` || ""}
                alt=""
                sizes="400px"
              />
            </div>

            <div
              className="absolute inset-0 bg-neutral-900 bg-opacity-20 opacity-0 hover:opacity-100 transition-opacity cursor-pointer"
              onClick={handleOpenModalImageGallery}
            />
          </div>
        ))}

        <button
          className="absolute hidden md:flex md:items-center md:justify-center left-3 bottom-3 px-4 py-2 rounded-xl bg-neutral-100 text-neutral-500 hover:bg-neutral-200 z-10"
          onClick={handleOpenModalImageGallery}
        >
          <Squares2X2Icon className="w-5 h-5" />
          <span className="ml-2 text-neutral-800 text-sm font-medium">
            {t('button.show-all-photos')}
          </span>
        </button>
      </div>
    </header>
  )
}

const SearchDetailContainer: FC<{ hotel: IHotel }> = ({ hotel }) => {
  const { id } = useParams();
  const { _id, title, description, images, amenities, rooms, address, region, rating } = hotel;
  const { t, i18n } = useLanguages();
  const [isOpen, setOpen] = useState<boolean>(false);

  const renderInfo = useMemo(() => {
    const point = rating?.score / rating?.count;
    const formattedPoint = isNaN(point) ? 0 : point.toFixed(1);

    return (
      <div className='listingSection__wrap !space-y-6'>
        <div className='flex justify-between items-center'>
          <Badge name={rooms?.length ? t('availability.available') : t('availability.unavailable')} color={rooms?.length ? "green" : "red"} />
          <LikeSaveBtns hotelId={_id} />
        </div>

        <h2 className='text-2xl sm:text-3xl lg:text-4xl font-semibold'>
          {title && title[i18n.language as "en" | "ru" | "tm"]}
        </h2>

        <div className="flex items-center space-x-4">
          <StartRating reviewCount={rating?.count} point={formattedPoint as number} />
          <span>·</span>
          <span>
            <i className="las la-map-marker-alt"></i>
            <span className="ml-1">{address && address[i18n.language as "en" | "ru" | "tm"]}, {t(`regions.${region}`)}</span>
          </span>
        </div>

      </div>
    )
  }, [_id, title, rooms, rating, address, region, t, i18n]);

  const renderDescription = useMemo(() => {
    return (
      <div className='listingSection__wrap'>
        <h2 className='text-2xl font-semibold'>{t('general.description')}</h2>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
        <div className='text-neutral-6000 dark:text-neutral-300'>
          {description && description[i18n.language as "en" | "tm" | "ru"]}
        </div>
      </div>
    )
  }, [description, t, i18n]);

  const renderRooms = useMemo(() => {
    return (
      <div className='listingSection__wrap'>
        <h2 className='text-2xl font-semibold'>{t('button.rooms')}</h2>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
        <div className='grid grid-cols-1 gap-5 lg:grid-cols-2'>
          {rooms?.map((room) => (
            <RoomCard key={room._id} data={room} href={""} bookHref={`/checkout?hotelId=${id}&roomId=${room._id}`} />
          ))}
        </div>
      </div>
    )
  }, [rooms, t]);


  const renderModalAmenities = () => {
    return (
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-50 overflow-y-auto"
          onClose={() => setOpen(false)}
        >
          <div className="min-h-screen px-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-40" />
            </Transition.Child>

            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="inline-block py-8 h-screen w-full max-w-4xl">
                <div className="inline-flex pb-2 flex-col w-full text-left align-middle transition-all transform overflow-hidden rounded-2xl bg-white dark:bg-neutral-900 dark:border dark:border-neutral-700 dark:text-neutral-100 shadow-xl h-full">
                  <div className="relative flex-shrink-0 px-6 py-4 border-b border-neutral-200 dark:border-neutral-800 text-center">
                    <h3
                      className="text-lg font-medium leading-6 text-gray-900"
                      id="headlessui-dialog-title-70"
                    >
                      {t('listing-hotel.amenities')}
                    </h3>
                    <span className="absolute left-3 top-3">
                      <ButtonClose onClick={() => setOpen(false)} />
                    </span>
                  </div>
                  <div className="px-8 overflow-auto text-neutral-700 dark:text-neutral-300 divide-y divide-neutral-200">
                    {amenities?.filter((_, i) => i < 1212).map((item) => (
                      <div
                        key={item}
                        className="flex items-center py-2.5 sm:py-4 lg:py-5 space-x-5 lg:space-x-8"
                      >
                        <i
                          className={`text-4xl text-neutral-6000 las ${icons.find((icon) => icon.label === item)?.value}`}
                        ></i>
                        <span>{t(`amenities.${item}`)}</span>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    )
  }

  const renderAmenities = useMemo(() => {
    return (
      <div className='listingSection__wrap'>
        <h2 className='text-2xl font-semibold'>{t('listing-hotel.amenities')}</h2>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
        <div className='grid grid-cols-1 xl:grid-cols-3 gap-6 text-sm text-neutral-700 dark:text-neutral-300'>
          {amenities?.filter((_, i: number) => i < 12).map((amenity) => {
            return (
              <div key={amenity} className='flex items-center space-x-3'>
                <i className={`text-3xl las ${icons.find((icon) => icon.label === amenity)?.value}`}></i>
                <span>{t(`amenities.${amenity}`)}</span>
              </div>
            )
          })}
        </div>
        {amenities?.length > 12 && (
          <>
            <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
            <div>
              <ButtonSecondary onClick={() => setOpen(true)}>
                {t('button.show-more')}
              </ButtonSecondary>
            </div>
          </>
        )}
        {renderModalAmenities()}
      </div>
    )
  }, [t, amenities]);

  return (
    <div className='nc-SearchDetailPage' data-nc-id={"SearchDetailPage"}>
      <SearchDetailHeader images={images} />

      <main className='relative z-10 mt-11 flex flex-col lg:flex-row'>
        <div className='w-full pb-20 lg:w-3/5 xl:w-2/3 space-y-8 lg:space-y-10 lg:pr-10'>
          {renderInfo}
          {renderDescription}
          {rooms?.length ? renderRooms : null}
          {renderAmenities}
          <Comments />
        </div>

        <div className='hidden lg:block flex-grow mt-14 lg:mt-0'>
          <div className='sticky top-28'>
            <SearchDetailSidebar />
          </div>
        </div>
      </main>
    </div>
  )
}

const SearchDetailPage: FC = () => {
  const { id } = useParams();
  const { t } = useLanguages();
  const { startDate, endDate, adultCount, childrenCount, roomCount } = useSearch();
  const guestCount = adultCount + childrenCount;

  const { data, isLoading } = useQuery({
    queryKey: ["search-details", startDate, endDate, guestCount, roomCount, id],
    queryFn: async () => {
      const res = await api.get(`/hotels/${id}/availability`, {
        params: {
          startDate,
          endDate,
          guestCount,
          roomCount
        }
      });
      return res.data
    }
  });
  const images = useMemo<string[]>(() => data ? data?.images : [], [data]);
  const hotel = useMemo<IHotel>(() => data ? data : {}, [data]);

  return isLoading ? (
    <div className="h-96 flex flex-col justify-center items-center gap-10">
      <div>
        <Spinner />
      </div>
      <h2 className="font-normal text-sm text-neutral-500 dark:text-neutral-400">{t('general.loading')}</h2>
    </div>
  ) : (
    <SearchDetailLayout images={images} cheapestPrice={hotel?.cheapestPrice}>
      <SearchDetailContainer hotel={hotel} />
    </SearchDetailLayout >
  )
}

export default SearchDetailPage