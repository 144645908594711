import { useMutation, useQueryClient } from "@tanstack/react-query"
import { authClient } from "api/auth"
import { SignInParams } from "containers/PageLogin/PageLogin"
import toast from "react-hot-toast"
import useLanguages from "./useLanguages"
import { OtpParams } from "containers/PageSignUp/PageSignUp"
import { SignUpParams } from "containers/PageSignUp/Register"
import { useLocation, useNavigate } from "react-router-dom"

interface UseAuthProps {
  nextStep?: (value: number) => void
}

interface UseAuthValue {
  login: (values: SignInParams) => void
  logout: () => void
  signUp: (values: OtpParams) => void
  verifyOTP: (values: SignUpParams & { otp: string }) => void
  loginLoading: boolean
  registerLoading: boolean
  otpLoading: boolean
}

const useAuth = ({ nextStep }: UseAuthProps): UseAuthValue => {
  const { t } = useLanguages();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const location = useLocation();
  const from = location?.state?.from?.pathname || "/";

  const { mutate: login, isPending: loginLoading } = useMutation({
    mutationKey: ["login"],
    mutationFn: authClient.login,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["current-user"] });
      navigate(from, { replace: true });
    },
    onError: (err: any) => {
      if (err?.response?.status === 406) {
        toast.error(t(`alert.wrong-credentials`))
      } else {
        toast.error(t("alert.something-went-wrong"));
      }
    }
  });

  const { mutate: signUp, isPending: registerLoading } = useMutation({
    mutationKey: ["register"],
    mutationFn: authClient.register,
    onSuccess: () => {
      nextStep && nextStep(2)
    },
    onError: (err: any) => {
      if (err?.response?.data === "Already exists") {
        toast.error(t("alert.already-exists"))
      } else {
        toast.error(t('alert.something-went-wrong'))
      }
    }
  });

  const { mutate: verifyOTP, isPending: otpLoading } = useMutation({
    mutationKey: ["verify-otp"],
    mutationFn: authClient.otp,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["current-user"] });
      navigate('/', { replace: true });
    },
    onError: (err: any) => {
      if (err?.response?.data === "Invalid") {
        toast.error(t('alert.invalid-otp'));
      }
      if (err?.response?.data === "Expired or does not exist") {
        toast.error(t("alert.expired-otp"))
      } else {
        if (err?.response?.data) {
          toast.error(t("alert.something-went-wrong"))
        }
      }
    }
  });

  const { mutate: logout } = useMutation({
    mutationKey: ["logout"],
    mutationFn: authClient.logout,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["current-user"] });
    }
  });

  return {
    login,
    logout,
    signUp,
    verifyOTP,
    loginLoading,
    registerLoading,
    otpLoading
  }
};

export default useAuth;