import useLanguages from "hooks/useLanguages";
import useListing from "hooks/useListing";
import { useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import { Modal } from "shared/Modal/Modal";

const DeleteRoom = () => {
  const { t } = useLanguages();
  const [isOpen, setOpen] = useState<boolean>(false);
  const [searchParams] = useSearchParams();
  const roomId = searchParams.get("roomId");
  const { id } = useParams();
  const { removeRoom } = useListing();

  return (
    <>
      <ButtonPrimary type={"button"} onClick={() => setOpen(true)} className="bg-red-600 !ring-red-500 hover:bg-red-500">{t('button.delete')}</ButtonPrimary>
      <Modal isOpen={isOpen} open={() => setOpen(!isOpen)} title="">
        <div className="space-y-5">
          <div className="space-y-3">
            <h2 className="pl-2 text-lg font-medium">{t('alert.delete-room')}</h2>
            <p className="bg-orange-100 dark:bg-orange-300 text-neutral-700 p-2 rounded-xl text-sm">{t('alert.delete-room-desc')}</p>
          </div>
          <div className="mt-6 flex justify-end space-x-2">
            <ButtonSecondary type={"button"} onClick={() => setOpen(false)}>{t('button.cancel')}</ButtonSecondary>
            <ButtonPrimary type={"button"} onClick={() => removeRoom({ id: id as string, roomId: roomId as string })}>{t('button.delete')}</ButtonPrimary>
          </div>
        </div>
      </Modal>
    </>
  )
};

export default DeleteRoom;