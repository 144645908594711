import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import { FC } from "react";
import { Helmet } from "react-helmet";
import SectionGridHotels from "./SectionGridHotels";

const SearchPage: FC = () => {
  return (
    <div className="nc-SearchPage relative" data-nc-id="SearchPage">
      <Helmet>
        <title>Oteller</title>
      </Helmet>

      <BgGlassmorphism />

      <div className="container pb-24 lg:pb-28">
        <SectionGridHotels />
      </div>
    </div>
  )
};

export default SearchPage;