import { useMutation, useQueryClient } from "@tanstack/react-query";
import api from "api";
import { useApp } from "hooks/useApp";
import React, { FC } from "react";

interface LikeButtonProps {
  hotelId: string
};

const LikeSaveBtns: FC<LikeButtonProps> = ({ hotelId }) => {
  const queryClient = useQueryClient();
  const { user, isLoggedIn } = useApp();
  const isLiked = user?.favorites.includes(hotelId);
  const { mutate } = useMutation({
    mutationKey: ["add-favorite"],
    mutationFn: async (id: string) => {
      const res = await api.put(`/favorites/${id}`);
      return res.data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["current-user"] });
      queryClient.invalidateQueries({ queryKey: ["favorites"] });
    }
  })

  return (
    <div className={`flow-root ${isLoggedIn && user?.role === "user" ? "block" : "hidden"}`} onClick={() => mutate(hotelId)}>
      <div className="flex text-neutral-700 dark:text-neutral-300 text-sm -mx-3 -my-1.5">
        <span className="py-1.5 px-3 flex rounded-lg hover:bg-neutral-100 dark:hover:bg-neutral-800 cursor-pointer">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-5 w-5"
            fill={isLiked ? "#4f46e5" : "none"}
            viewBox="0 0 24 24"
            stroke={isLiked ? "#4f46e5" : "currentColor"}
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={1.5}
              d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z"
            />
          </svg>
        </span>
      </div>
    </div>
  );
};

export default LikeSaveBtns;
