import useLanguages from "hooks/useLanguages";
import useListing from "hooks/useListing";
import { useState } from "react";
import { useParams } from "react-router-dom";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import { Modal } from "shared/Modal/Modal";

const DeleteHotel = () => {
  const { id } = useParams();
  const { remove } = useListing();
  const { t } = useLanguages();
  const [isOpen, setOpen] = useState<boolean>(false);

  return (
    <>
      <button type={"button"} onClick={() => setOpen(true)} className="flex-shrink-0 block !leading-none font-medium px-5 py-2.5 text-sm sm:text-base sm:px-6 sm:py-3 text-neutral-500 dark:text-neutral-300 border-b-2 border-transparent focus:outline-none hover:dark:border-red-700 hover:border-red-700 transition-colors">{t('button.hotel-delete')}</button>
      <Modal title="" isOpen={isOpen} open={() => setOpen(!isOpen)}>
      <div className="space-y-5">
          <div className="space-y-3">
            <h2 className="pl-2 text-lg font-medium">{t('alert.delete-hotel')}</h2>
            <p className="bg-orange-100 dark:bg-orange-300 text-neutral-700 p-2 rounded-xl text-sm">{t('alert.delete-hotel-desc')}</p>
          </div>
          <div className="mt-6 flex justify-end space-x-2">
            <ButtonSecondary type={"button"} onClick={() => setOpen(false)}>{t('button.cancel')}</ButtonSecondary>
            <ButtonPrimary type={"button"} onClick={() => remove(id as string)}>{t('button.delete')}</ButtonPrimary>
          </div>
        </div>
      </Modal>  
    </>
  )
};

export default DeleteHotel;