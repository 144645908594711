import Logo from "shared/Logo/Logo";
import SocialsList1 from "shared/SocialsList1/SocialsList1";
import { CustomLink } from "data/types";
import React from "react";
import useLanguages from "hooks/useLanguages";
import DownloadApp from "./DownloadApp";

export interface WidgetFooterMenu {
  id: string;
  title: string;
  menus: CustomLink[];
}

const widgetMenus: WidgetFooterMenu[] = [
  {
    id: "5",
    title: "sitemap",
    menus: [
      { href: "/", label: "home" },
      { href: "/about-us", label: "about-us" },
      { href: "/account", label: "account" },
      { href: "/contact-us", label: "customer-support" },
      { href: "/become-partner", label: "become-partner" },
      { href: "/privacy-policy", label: "privacy-policy" },
    ],
  },
  {
    id: "1",
    title: "regions",
    menus: [
      { href: "/hotels?region=arkadag", label: "arkadag" },
      { href: "/hotels?region=ashgabat", label: "ashgabat" },
      { href: "/hotels?region=balkan", label: "balkan" },
      { href: "/hotels?region=ahal", label: "ahal" },
      { href: "/hotels?region=dashoguz", label: "dashoguz" },
      { href: "/hotels?region=lebap", label: "lebap" },
      { href: "/hotels?region=mary", label: "mary" },
    ],
  }
];

const Footer: React.FC = () => {
  const { t } = useLanguages();
  
  const renderWidgetMenuItem = (menu: WidgetFooterMenu, index: number) => {
    return (
      <div key={index} className="text-sm">
        <h2 className="font-semibold text-neutral-700 dark:text-neutral-200">
          {t(`${menu.title}.title`)}
        </h2>
        <ul className="mt-5 space-y-4">
          {menu.menus.map((item, index) => (
            <li key={index}>
              <a
                key={index}
                className="text-neutral-6000 dark:text-neutral-300 hover:text-black dark:hover:text-white"
                href={item.href}
              >
                {t(`${menu.title}.${item.label}`)}
              </a>
            </li>
          ))}
        </ul>
      </div>
    );
  };

  return (
    <div className="nc-Footer relative py-24 lg:py-28 border-t border-neutral-200 dark:border-neutral-700">
      <div className="container grid grid-cols-2 gap-y-10 gap-x-5 sm:gap-x-8 md:grid-cols-4 lg:grid-cols-5 lg:gap-x-10 ">
        <div className="grid grid-cols-4 gap-5 col-span-2 md:col-span-4 lg:md:col-span-1 lg:flex lg:flex-col">
          <div className="col-span-2 md:col-span-1">
            <Logo />
          </div>
          <div className="col-span-2 flex items-center md:col-span-3">
            <SocialsList1 className="flex items-center space-x-3 lg:space-x-0 lg:flex-col lg:space-y-2.5 lg:items-start" />
          </div>
        </div>
        {widgetMenus.map(renderWidgetMenuItem)}
        <DownloadApp />
      </div>
    </div>
  );
};

export default Footer;
