import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import useLanguages from "hooks/useLanguages";
import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { Helmet } from "react-helmet"
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import { FormProvider, useForm } from "react-hook-form";
import ListingHotel2 from "./ListingHotel2";
import ListingHotel3 from "./ListingHotel3";
import ListingHotel1 from "./ListingHotel1";
import { useNavigate, useSearchParams } from "react-router-dom";
import toast from "react-hot-toast";
import { useQuery } from "@tanstack/react-query";
import api from "api";
import useListing from "hooks/useListing";

const ListingPage: FC = () => {
  const [searchParams] = useSearchParams();
  const hotelId = searchParams.get("hotelId");
  const { data } = useQuery({
    queryKey: ["editable-hotel"], queryFn: async () => {
      const res = await api.get(`/hotels/${hotelId}`);
      return res.data
    }, enabled: !!hotelId
  });
  const navigate = useNavigate();
  const { t } = useLanguages();
  const [index, setIndex] = useState<number>(1);
  const formMethods = useForm<ListingHotelType>();
  const { watch, handleSubmit, reset } = formMethods;
  const { create, update } = useListing();

  useEffect(() => {
    if (data) {
      reset({
        title: data?.title,
        description: data?.description,
        address: data?.address,
        region: data?.region,
        phonenumber: data?.phonenumber.substring(4),
        stars: data?.stars,
        amenities: data?.amenities,
        images: data?.images
      })
    }
  }, [data]);

  const onSubmit = useCallback(async (values: ListingHotelType) => {
    const { files, phonenumber, ...rest } = values;
    const formData = new FormData();

    if (!files?.length && !values?.images?.length) {
      toast.error(t("listing-hotel.upload-file"));
      return;
    }

    if (files && files?.length < 3) {
      toast.error(t('alert.upload-min-files'));
      return;
    };

    formData.append("data", JSON.stringify({ ...rest, phonenumber: `+993${phonenumber}` }))
    files && files.forEach((image) => formData.append("files", image));

    if (hotelId) {
      update({ id: hotelId, values: formData });
    } else {
    create(formData)
    }
  }, [hotelId]);

  const isDisabled = useMemo(() => {
    if (index === 1) {
      if (
        !watch("title.tm") ||
        !watch("title.ru") ||
        !watch("title.en") ||
        !watch("address.tm") ||
        !watch("address.ru") ||
        !watch("address.en") ||
        !watch("description.tm") ||
        !watch("description.ru") ||
        !watch("description.en")
      ) {
        return true
      } else {
        return false
      }
    }

    if (index === 2) {
      if (!watch("amenities")?.length) {
        return true
      } else {
        return false
      }
    }
  }, [watch()]);

  const renderPages = useMemo(() => {
    if (index === 1) {
      return <ListingHotel1 />
    }
    if (index === 2) {
      return <ListingHotel2 />
    }
    if (index === 3) {
      return <ListingHotel3 />
    }
  }, [index]);

  return (
    <div className="nc-ListingPage px-4 max-w-3xl mx-auto pb-24 pt-14 sm:py-24 lg:pb-32" data-nc-id="ListingPage">
      <Helmet>
        <title>{`${t("general.list-your-hotel")}`} | Oteller</title>
      </Helmet>

      <BgGlassmorphism />

      <FormProvider {...formMethods}>
        <form className="space-y-11" onSubmit={handleSubmit(onSubmit)}>
          <div>
            <span className="text-4xl font-semibold">{index}</span>{" "}
            <span className="text-lg text-neutral-500 dark:text-neutral-400">/ 3</span>
          </div>

          <div>
            <p className="text-neutral-500 dark:text-neutral-400">{t('alert.dont-refresh')}</p>
          </div>


          <div className="relative listingSection__wrap">
            {renderPages}
          </div>

          <div className="flex justify-end space-x-5">
            <ButtonSecondary type={"button"} onClick={() => index === 1 ? navigate(-1) : setIndex(prev => prev -= 1)}>{t('button.go-back')}</ButtonSecondary>
            {index < 3 && <ButtonPrimary disabled={isDisabled} type={"button"} onClick={() => {
              window.scrollTo(0, 0);
              setIndex(prev => prev += 1)
            }}>{t('button.continue')}</ButtonPrimary>
            }
            {index === 3 && <ButtonPrimary type={"submit"}>{hotelId ? t("button.update") : t("button.save")}</ButtonPrimary>}
          </div>
        </form>
      </FormProvider>
    </div>
  )
};

export default ListingPage;