import { Fragment, FC } from "react";
import { Popover, Transition } from "@headlessui/react";
import { CalendarIcon } from "@heroicons/react/24/outline";
import DatePicker from "react-datepicker";
import DatePickerCustomHeaderTwoMonth from "components/DatePickerCustomHeaderTwoMonth";
import DatePickerCustomDay from "components/DatePickerCustomDay";
import { useFormContext } from "react-hook-form";
import { SearchForm } from "components/Hero/Search";
import useConvertDates from "hooks/useConvertDates";
import localizeDayNames from "utils/localizeDayNames";
import useLanguages from "hooks/useLanguages";

export interface DatesRangeProps {
  className?: string;
}

const DatesRange: FC<DatesRangeProps> = ({
  className = "flex-1",
}) => {
  const { t, i18n } = useLanguages();
  const { setValue, watch } = useFormContext<SearchForm>();
  const checkIn = useConvertDates(watch('startDate'));
  const checkOut = useConvertDates(watch("endDate"));

  const onChangeDate = (dates: [Date | null, Date | null]) => {
    const [start, end] = dates;
    setValue("startDate", start);
    setValue("endDate", end);
  };

  const renderInput = () => {
    return (
      <>
        <div className="text-neutral-300 dark:text-neutral-400">
          <CalendarIcon className="w-5 h-5 lg:w-7 lg:h-7" />
        </div>
        <div className="flex-grow text-left">
          <span className="block xl:text-lg font-semibold">
            {watch("startDate") ? checkIn.format("MMM DD") : "Add dates"}
            {watch("endDate")
              ? " - " +
                checkOut.format("MMM DD")
              : ""}
          </span>
          <span className="block mt-1 text-sm text-neutral-400 leading-none font-light">
            {t('search.check-in-check-out')}
          </span>
        </div>
      </>
    );
  };

  return (
    <Popover className={`DatesRange z-10 relative flex ${className}`}>
      {({ open }) => (
        <>
          <Popover.Button
            className={`flex-1 flex relative p-3 items-center space-x-3 focus:outline-none ${
              open ? "shadow-lg" : ""
            }`}
          >
            {renderInput()}
          </Popover.Button>

          <Transition
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1"
          >
            <Popover.Panel className="absolute left-auto xl:-right-10 right-0 z-10 mt-3 top-full w-screen max-w-sm px-4 sm:px-0 lg:max-w-3xl">
              <div className="overflow-hidden rounded-3xl shadow-lg ring-1 ring-black ring-opacity-5 bg-white dark:bg-neutral-800 p-8">
                <DatePicker
                  selected={watch("startDate")}
                  onChange={onChangeDate}
                  startDate={watch("startDate")}
                  endDate={watch("endDate")}
                  minDate={new Date()}
                  selectsRange
                  monthsShown={2}
                  showPopperArrow={false}
                  inline
                  renderCustomHeader={(p) => (
                    <DatePickerCustomHeaderTwoMonth {...p} />
                  )}
                  renderDayContents={(day, date) => (
                    <DatePickerCustomDay dayOfMonth={day} date={date} />
                  )}
                  formatWeekDay={(day) => {
                    const dayName = localizeDayNames(day, i18n.language);
                    return dayName
                  }}
                />
              </div>
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  );
};

export default DatesRange;
