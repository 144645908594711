import useLanguages from "hooks/useLanguages";
import { FC, FormEvent, useState } from "react";
import { Helmet } from "react-helmet"
import ChooseMethod from "./ChooseMethod";
import ChangePassword from "./ChangePassword";
import ValidateOTP from "./ValidateOTP";
import { useMutation } from "@tanstack/react-query";
import api from "api";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";

type ForgotPasswordType = {
  phonenumber: string
  email: string
  password: string
  otp: string
}

type ChangePasswordType = {
  phonenumberOrEmail: string
  password: string
  otp: string
}

const ForgetPasswordPage: FC = () => {
  const { t } = useLanguages();
  const navigate = useNavigate();
  const [step, setStep] = useState<number>(1);
  const [method, setMethod] = useState<string>("");
  const [form, setForm] = useState<ForgotPasswordType>({
    phonenumber: "",
    email: "",
    password: "",
    otp: ""
  });
  const { mutate } = useMutation({
    mutationKey: ["change-password"],
    mutationFn: async (data: ChangePasswordType) => {
      const res = await api.post('/auth/change-password', data);
      return res.data
    },
    onSuccess: () => {
      toast.success(t('alert.password-changed'));
      navigate('/sign-in');
    },
    onError: (err: any) => {
      if (err?.response?.data === "Invalid") {
        toast.error(t('alert.invalid-otp'));
      }
      if (err?.response?.data === "Expired or does not exist") {
        toast.error(t("alert.expired-otp"))
      } else {
        if (err?.response?.data) {
          toast.error(t("alert.something-went-wrong"))
        }
      }
    }
  })

  const onSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    mutate({
      phonenumberOrEmail: method === "email" ? form?.email : form?.phonenumber,
      password: form?.password,
      otp: form?.otp
    })
  }

  const conditionalStep = () => {
    switch (step) {
      case 1:
        return <ChangePassword next={() => setStep(2)} onChange={setForm} />
      case 2:
        return <ChooseMethod
          next={() => setStep(3)}
          onChange={setForm}
          method={method}
          setMethod={setMethod}
        />
      case 3:
        return <ValidateOTP
          setStep={setStep}
          defaultValue={form?.otp}
          onChange={(value) => setForm((prev) => ({ ...prev, otp: value }))}
          onSubmit={onSubmit}
        />
      default:
        return <ChangePassword next={() => setStep(2)} onChange={setForm} />
    }
  }

  return (
    <div className={`nc-ForgetPasswordPage`} data-nc-id='ForgetPasswordPage'>
      <Helmet>
        <title>{t(`pages.change-password`)}</title>
      </Helmet>
      <div className='container mb-24 lg:mb-32'>
        <h2 className='my-20 flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center'>{t(`pages.change-password`)}</h2>
        <div className='max-w-md mx-auto space-y-6'>{conditionalStep()}</div>
      </div>
    </div>
  )
};

export default ForgetPasswordPage;