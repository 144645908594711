import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import useLanguages from "hooks/useLanguages";
import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { Helmet } from "react-helmet"
import { FormProvider, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import ListingRoom1 from "./ListingRoom1";
import ListingRoom2 from "./ListingRoom2";
import ListingRoom3 from "./ListingRoom3";
import ListingRoom4 from "./ListingRoom4";
import { useQuery } from "@tanstack/react-query";
import api from "api";
import useListing from "hooks/useListing";
import DeleteRoom from "./DeleteRoom";

const ListingRoomPage: FC = () => {
  const { t } = useLanguages();
  const [searchParams] = useSearchParams();
  const roomId = searchParams.get('roomId');
  const { id } = useParams();
  const { data } = useQuery({
    queryKey: ["room", roomId],
    queryFn: async () => {
      const res = await api.get(`/hotels/${id}/rooms/${roomId}`);
      return res.data
    },
    enabled: !!roomId
  });
  const navigate = useNavigate();
  const [index, setIndex] = useState<number>(1);
  const formMethods = useForm<ListingRoomType>();
  const { watch, handleSubmit, reset } = formMethods;
  const { createRoom, updateRoom } = useListing();

  useEffect(() => {
    if (data) {
      reset({
        title: data?.title,
        type: data?.type,
        price: data?.price,
        bedCount: data?.bedCount,
        bathCount: data?.bathCount,
        quantity: data?.quantity,
        unavailableDates: data?.unavailableDates,
        guestCapacity: data?.guestCapacity,
        images: data?.images
      })
    }
  }, [data]);

  const isDisabled = useMemo(() => {
    if (index === 1) {
      if (!watch("title.tm") || !watch("title.en") || !watch("title.ru")) {
        return true
      } else {
        return false
      }
    };

    if (index === 2) {
      if (!watch("price") || !watch("quantity")) {
        return true
      } else {
        return false
      }
    }
  }, [watch(), index]);

  const renderPages = useMemo(() => {
    if (index === 1) {
      return <ListingRoom1 />
    }

    if (index === 2) {
      return <ListingRoom2 />
    }

    if (index === 3) {
      return <ListingRoom3 />
    }

    if (index === 4) {
      return <ListingRoom4 />
    }
  }, [index]);

  const onSubmit = useCallback(async (values: ListingRoomType) => {
    const { files, ...rest } = values;
    const formData = new FormData();

    if (!files && !values.images?.length) {
      toast.error(t("listing-hotel.upload-file"));
      return;
    }

    if (files && files.length < 3) {
      toast.error(t('alert.upload-min-files'));
      return;
    };

    formData.append("data", JSON.stringify({ ...rest }))
    files && files.forEach((image) => formData.append("files", image));

    if (roomId) {
      updateRoom({ id: id as string, roomId, values: formData });
    } else {
      createRoom({ id: id as string, values: formData })
    }
  }, [roomId]);

  return (
    <div className="nc-ListingPage px-4 max-w-3xl mx-auto pb-24 pt-14 sm:py-24 lg:pb-32" data-nc-id="ListingPage">
      <Helmet>
        <title>{`${t("general.list-your-hotel")}`} | Oteller</title>
      </Helmet>

      <BgGlassmorphism />

      <FormProvider {...formMethods}>
        <form className="space-y-11" onSubmit={handleSubmit(onSubmit)}>
          <div>
            <span className="text-4xl font-semibold">{index}</span>{" "}
            <span className="text-lg text-neutral-500 dark:text-neutral-400">/ 4</span>
          </div>

          <div>
            <p className="text-neutral-500 dark:text-neutral-400">{t('alert.dont-refresh')}</p>
          </div>


          <div className="relative listingSection__wrap md:bg-white dark:md:bg-neutral-900">
            {renderPages}
          </div>

          <div className={`flex ${roomId ? "justify-between" : "justify-end"} space-x-5`}>
            {roomId && <DeleteRoom />}
            <div className="space-x-5">
              <ButtonSecondary type={"button"} onClick={() => index === 1 ? navigate(-1) : setIndex(prev => prev -= 1)}>{t('button.go-back')}</ButtonSecondary>
              {index < 4 && <ButtonPrimary disabled={isDisabled} type={"button"} onClick={() => {
                window.scrollTo(0, 0);
                setIndex(prev => prev += 1)
              }}>{t('button.continue')}</ButtonPrimary>
              }
              {index === 4 && <ButtonPrimary type={"submit"}>{roomId ? t("button.update") : t("button.save")}</ButtonPrimary>}
            </div>
          </div>
        </form>
      </FormProvider>
    </div>
  )
};

export default ListingRoomPage;