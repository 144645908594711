import { Popover, Transition } from "@headlessui/react";
import { GlobeAltIcon } from "@heroicons/react/24/outline";
import { FC, Fragment, useCallback } from "react";
import useLanguages from "hooks/useLanguages";

export const headerLanguage = [
  {
    name: "TKM",
    value: "tm"
  },
  {
    name: "РУ",
    value: "ru"
  },
  {
    name: "EN",
    value: "en"
  },
];

interface LangDropdownProps {
  panelClassName?: string;
}

const LangDropdown: FC<LangDropdownProps> = ({
  panelClassName = "z-10 w-screen max-w-[120px] px-4 mt-4 right-0 sm:px-0",
}) => {
  const { i18n, changeLanguageHandler } = useLanguages();

  const handleChange = useCallback((lang: string) => {
    changeLanguageHandler(lang);
  }, [i18n.language, changeLanguageHandler]);

  return (
    <div className="LangDropdown">
      <Popover className="relative">
        {({ open, close }) => (
          <>
            <Popover.Button
              className={`
                ${open ? "" : "text-opacity-80"}
             group px-3 py-1.5 border-neutral-300 hover:border-neutral-400 dark:border-neutral-700 rounded-full inline-flex items-center text-sm text-gray-700 dark:text-neutral-300 font-medium hover:text-opacity-100 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75`}
            >
              <GlobeAltIcon className="w-5 h-5 opacity-80" />
            </Popover.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel className={`absolute ${panelClassName}`}>
                <div className="overflow-hidden rounded-2xl shadow-lg ring-1 ring-black ring-opacity-5">
                  <div className="relative grid gap-8 bg-white dark:bg-neutral-800 p-7">
                    {headerLanguage.map((item, index) => (
                      <div
                        key={index}
                        onClick={() => { 
                          handleChange(item.value)
                          close()
                        }}
                        className={`flex items-center p-2 -m-3 transition duration-150 ease-in-out rounded-lg hover:bg-neutral-100 dark:hover:bg-neutral-700 focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-50 ${
                          item.value === i18n.language ? "bg-gray-100 dark:bg-neutral-700" : "opacity-80"
                          }`}
                      >
                        <div className="">
                          <p className="text-sm font-medium ">{item.name}</p>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    </div>
  );
};
export default LangDropdown;
