import axios from "axios";

export const BASE_URL = process.env.REACT_APP_BASE_URL

export const IMAGE_PATH = "/api/v1/uploads"

const api = axios.create({
  baseURL: "/api/v1",
  withCredentials: true,
});

export default api;
