import { createContext, ReactNode, useState } from "react";

interface SearchContextValues {
  saveSearch: (
    region: string,
    startDate: Date | null,
    endDate: Date | null,
    adultCount: number,
    childrenCount: number,
    roomCount: number,
    rangePrices?: number[],
    hotelId?: string
  ) => void,
  clearSearch: () => void
  saveRegion: (region: string) => void
  saveGuests: (
    adultCount: number,
    childrenCount: number,
    roomCount: number
  ) => void
  saveDates: (
    startDate: Date | null,
    endDate: Date | null
  ) => void
  saveSearchHotel: (
    startDate: Date | null,
    endDate: Date | null,
    adultCount: number,
    childrenCount: number,
    roomCount: number,
  ) => void
  hotelId?: string
  region: string
  startDate: Date | null
  endDate: Date | null
  adultCount: number
  childrenCount: number
  roomCount: number,
  rangePrices: number[],
  setRangePrices: (prices: number[]) => void
}

interface SearchProviderProps {
  children: ReactNode
};

export const SearchContext = createContext<SearchContextValues | undefined>(undefined);

export const SearchProvider = ({ children }: SearchProviderProps) => {
  const [region, setRegion] = useState<string>(sessionStorage.getItem("region") || "");
  const [startDate, setStartDate] = useState<Date | null>(new Date(sessionStorage.getItem("startDate") || new Date()));
  const [endDate, setEndDate] = useState<Date | null>(new Date(sessionStorage.getItem("endDate") || new Date().setDate(new Date().getDate() + 1)));
  const [adultCount, setAdult] = useState<number>(parseInt(sessionStorage.getItem("adultCount")!) || 1);
  const [childrenCount, setChildren] = useState<number>(parseInt(sessionStorage.getItem("childrenCount")!) || 0);
  const [roomCount, setRoom] = useState<number>(parseInt(sessionStorage.getItem("roomCount")!) || 1);
  const [hotelId, setHotelId] = useState<string>(() => sessionStorage.getItem("hotelId") || "");
  const [rangePrices, setRangePrices] = useState<number[]>([0, 4000]);

  const saveRegion = (region: string) => {
    setRegion(region);
    sessionStorage.setItem("region", region)
  }

  const saveGuests = (
    adultCount: number,
    childrenCount: number,
    roomCount: number
  ) => {
    setAdult(adultCount);
    setChildren(childrenCount);
    setRoom(roomCount);

    sessionStorage.setItem("adultCount", adultCount.toString())
    sessionStorage.setItem("childrenCount", childrenCount.toString())
    sessionStorage.setItem("roomCount", roomCount.toString())
  }

  const saveDates = (startDate: Date | null, endDate: Date | null) => {
    setStartDate(startDate);
    setEndDate(endDate);

    sessionStorage.setItem("startDate", String(startDate))
    sessionStorage.setItem("endDate", String(endDate))
  }

  const saveSearch = (
    region: string,
    startDate: Date | null,
    endDate: Date | null,
    adultCount: number,
    childrenCount: number,
    roomCount: number,
    rangePrices?: number[],
    hotelId?: string
  ) => {
    setRegion(region);
    setStartDate(startDate);
    setEndDate(endDate);
    setAdult(adultCount);
    setChildren(childrenCount);
    setRoom(roomCount);

    sessionStorage.setItem("region", region)
    sessionStorage.setItem("startDate", String(startDate))
    sessionStorage.setItem("endDate", String(endDate))
    sessionStorage.setItem("adultCount", adultCount.toString())
    sessionStorage.setItem("childrenCount", childrenCount.toString())
    sessionStorage.setItem("roomCount", roomCount.toString())

    if (rangePrices) {
      setRangePrices([rangePrices[0], rangePrices[1]])
    }

    if (hotelId) {
      setHotelId(hotelId);
      sessionStorage.setItem("hotelId", hotelId)
    };
  };

  const saveSearchHotel = (
    startDate: Date | null,
    endDate: Date | null,
    adultCount: number,
    childrenCount: number,
    roomCount: number,
  ) => {
    setStartDate(startDate);
    setEndDate(endDate);
    setAdult(adultCount);
    setChildren(childrenCount);
    setRoom(roomCount);

    sessionStorage.setItem("startDate", String(startDate))
    sessionStorage.setItem("endDate", String(endDate))
    sessionStorage.setItem("adultCount", adultCount.toString())
    sessionStorage.setItem("childrenCount", childrenCount.toString())
    sessionStorage.setItem("roomCount", roomCount.toString())
  }

  const clearSearch = () => {
    setRegion("");
    setStartDate(new Date());
    setEndDate(new Date(new Date().setDate(new Date().getDate() + 1)));
    setAdult(1);
    setChildren(0);
    setRoom(1);
    setRangePrices([0, 4000]);

    sessionStorage.setItem("region", "")
    sessionStorage.setItem("startDate", String(new Date()))
    sessionStorage.setItem("endDate", String(new Date(new Date().setDate(new Date().getDate() + 1))))
    sessionStorage.setItem("adultCount", "1")
    sessionStorage.setItem("childrenCount", "")
    sessionStorage.setItem("roomCount", "1")
  }

  return (
    <SearchContext.Provider
      value={{
        region,
        startDate,
        endDate,
        adultCount,
        childrenCount,
        roomCount,
        rangePrices,
        hotelId,
        saveSearch,
        saveSearchHotel,
        saveRegion,
        saveGuests,
        saveDates,
        clearSearch,
        setRangePrices
      }}
    >
      {children}
    </SearchContext.Provider>
  )
};

export const SearchConsumer = SearchContext.Consumer;