import { useQuery } from "@tanstack/react-query";
import api from "api";
import useLanguages from "hooks/useLanguages";
import moment from "moment";
import { useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import CommonLayout from "./CommonLayout";
import { normalizeCode } from "utils/normalizeCode";
import { Modal } from "shared/Modal/Modal";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import useBooking from "hooks/useBooking";

const status = ["pending", "booked", "cancelled", "rejected"];

const HotelBookings = () => {
  const [isActive, setActive] = useState<string>("pending");
  const [isOpen, setOpen] = useState<boolean>(false);
  const [selectedBooking, setBooking] = useState<IBooking | null>();
  const guestCount = selectedBooking?.guests.length;
  const startDate = selectedBooking?.dates[0];
  const endDate = selectedBooking?.dates[selectedBooking?.dates.length - 1];
  const days = moment(endDate).diff(startDate, "days");
  const code = selectedBooking?.code && normalizeCode(selectedBooking?.code);
  const { changeStatus } = useBooking();
  const { t } = useLanguages();
  const { id } = useParams();
  const { data } = useQuery({
    queryKey: ["hotel-bookings", id, isActive],
    queryFn: async () => {
      const res = await api.get(`/bookings/hotel/${id}?status=${isActive}`);
      return res.data;
    }
  });
  const bookings = useMemo<IBooking[]>(() => data || [], [data]);

  const onSubmit = (type: "booked" | "rejected") => {
    if (type === "booked") {
      changeStatus({ id: selectedBooking?._id || "", value: "booked" })
    } else {
      changeStatus({ id: selectedBooking?._id || "", value: "rejected" })
    }

    setOpen(false);
    setBooking(null);
  }

  const onFilter = (status: string) => {
    setActive(status)
  }

  return (
    <CommonLayout>
      <div className="max-[1000px]:block hidden pb-5">
        <h2 className="font-medium text-orange-700">{t('alert.larger-screen')}</h2>
      </div>
      <div className="space-y-6 relative">
        <div className="flex flex-wrap gap-1 mg:gap-3">
          {status.map((item) => <button
            type={"button"}
            className={`px-2 py-1 md:px-3 md:py-2 ${isActive === item ? "bg-primary-6000 hover:bg-primary-500 text-white" : "bg-neutral-200 dark:bg-neutral-800"} text-sm md:text-base rounded-lg transition-colors`}
            onClick={() => onFilter(item)}
          >{t(`status.${item}`)}</button>)}
        </div>
        <div>
          <table className="bg-neutral-100 dark:bg-neutral-800 rounded-xl w-full shadow-md">
            <thead>
              <tr>
                <th className="py-3 lg:w-[20%]">{t('table.customer')}</th>
                <th className="max-[1000px]:hidden py-3 w-[8%]">{t('table.book-code')}</th>
                <th className="max-[1000px]:hidden py-3 w-[10%]">{t('table.guests')}</th>
                <th className="max-[1000px]:hidden py-3 w-[14%]">{t('table.room')}</th>
                <th className="max-[1000px]:hidden py-3 w-[20%]">{t('table.dates')}</th>
                <th className="max-[1000px]:hidden py-3 w-[12%]">{t('table.payment-method')}</th>
                <th>{t('table.status')}</th>
              </tr>
            </thead>
            <tbody className=" bg-white dark:bg-neutral-700 divide-y-[1px] divide-neutral-200 dark:divide-neutral-800 space-y-3">
              {bookings.map((booking) => {
                const guestCount = booking.guests.length;
                const startDate = booking.dates[0];
                const endDate = booking.dates[booking.dates.length - 1];
                const code = normalizeCode(booking.code);

                return (
                  <tr key={booking._id} onClick={() => {
                    if (booking.status !== "cancelled") {
                      setBooking(booking)
                      setOpen(true)
                    }
                  }} className="cursor-pointer hover:bg-neutral-50 hover:dark:bg-neutral-800 transition-colors">
                    <td className="py-3 text-center">
                      <p>{booking.userId.firstName} {booking.userId.lastName}</p>
                      <a href={`tel:${booking.userId.phonenumber}`} className="underline">{booking.userId.phonenumber}</a>
                    </td>
                    <td className="max-[1000px]:hidden py-3 text-center">{code}</td>
                    <td className="max-[1000px]:hidden py-3 text-center cursor-pointer">{guestCount} {guestCount > 1 ? t("counter.guests.plural") : t('counter.guests.single')}</td>
                    <td className="max-[1000px]:hidden py-3 text-center">{booking.roomType} ({booking.roomQuantity})</td>
                    <td className="max-[1000px]:hidden py-3 text-center">{moment(startDate).format("DD.MM.YYYY")} - {moment(endDate).format("DD.MM.YYYY")}</td>
                    <td className="max-[1000px]:hidden py-3 text-center">{t(`checkout.${booking.paymentMethod}`)}</td>
                    <td className="text-center">
                      <div className="py-3 flex justify-center items-center">
                        <p className={`px-3 py-1  text-center text-white w-fit rounded-xl ${booking.status === "pending" ? "bg-orange-500" : booking.status === "booked" ? "bg-green-600" : "bg-red-600"} text`}>{t(`status.${booking.status}`)}</p>
                      </div>
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>
      </div>
      <Modal isOpen={isOpen} open={() => setOpen(false)} title={`${selectedBooking?.userId.firstName} ${selectedBooking?.userId.lastName}`}>
        <div className="relative flex flex-col divide-y-[1px] divide-neutral-200 dark:divide-neutral-700">
          <div className="py-2 inline-flex justify-between items-center">
            <p>{t('table.book-code')}</p>
            <p>{code}</p>
          </div>
          <div className="py-2 inline-flex justify-between items-center">
            <p>{t('table.status')}</p>
            <p>{t(`status.${selectedBooking?.status}`)}</p>
          </div>
          <div className="py-2 inline-flex justify-between items-center">
            <p>{t('table.guests')}</p>
            <p>{guestCount} {guestCount as number > 1 ? t("counter.guests.plural") : t('counter.guests.single')}</p>
          </div>
          <div className="py-2 inline-flex justify-between items-center">
            <p>{t('table.room')}</p>
            <p>{t(`room-types.${selectedBooking?.roomType}`)} ({selectedBooking?.roomQuantity})</p>
          </div>
          <div className="py-2 inline-flex justify-between items-center">
            <p>{t('table.dates')}</p>
            <p>{moment(startDate).format("DD.MM.YYYY")} - {moment(endDate).format("DD.MM.YYYY")}</p>
          </div>
          <div className="py-2 inline-flex justify-between items-center">
            <p>{t('table.payment-method')}</p>
            <p>{t(`checkout.${selectedBooking?.paymentMethod}`)}</p>
          </div>
          <div className="py-2 inline-flex justify-between items-center">
            <p>{t('checkout.total')}</p>
            <p>{selectedBooking?.price as number * days} TMT</p>
          </div>
        </div>
        <div className="relative flex justify-end gap-3">
          <ButtonSecondary onClick={() => onSubmit("rejected")}>{t('button.reject')}</ButtonSecondary>
          <ButtonPrimary onClick={() => onSubmit("booked")}>{t('button.accept')}</ButtonPrimary>
        </div>
      </Modal>
    </CommonLayout>
  )
};

export default HotelBookings;