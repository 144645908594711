import { StarIcon } from '@heroicons/react/24/solid'
import { BASE_URL } from 'api'
import { useApp } from 'hooks/useApp'
import useConvertDates from 'hooks/useConvertDates'
import { FC } from 'react'
import Avatar from 'shared/Avatar/Avatar'
import ReviewOptions from './ReviewOptions'

interface ReviewItemProps extends IReview {
  hasListingTitle?: string
}

const ReviewItem: FC<ReviewItemProps> = ({ hasListingTitle, _id, userId, comment, rating, createdAt }) => {
  const date = useConvertDates(createdAt);
  const { user } = useApp();
  const stars: any[] = [];
  
  for (let i = 0; i < rating; i++) {
    stars.push(
      <StarIcon key={i} className="w-4 h-4 text-yellow-500" />
    );
  }
  
  for (let i = rating; i < 5; i++) {
    stars.push(
      <StarIcon key={i} className="w-4 h-4 text-gray-300" />
    );
  }

  return (
    <div key={_id} className="nc-Review flex space-x-4 py-8" data-nc-id={"Review"}>
      <div className="pt-0.5">
        <Avatar
          sizeClass="h-10 w-10 text-lg"
          radius="rounded-full"
          userName={userId?.firstName}
          imgUrl={userId?.image ? `${BASE_URL}/uploads/users/${userId.image}` : ""}
        />
      </div>
      <div className="flex-grow">
        <div className="flex justify-between space-x-3">
          <div className="flex flex-col">
            <div className="text-sm font-semibold">
              <span>{userId?.firstName} {userId?.lastName}</span>
              {hasListingTitle && (
                <>
                  <span className="text-neutral-500 dark:text-neutral-400 font-normal">
                    {` review in `}
                  </span>
                  <a href="/">The Lounge & Bar</a>
                </>
              )}
            </div>
            <span className="text-sm text-neutral-500 dark:text-neutral-400 mt-0.5">
              {date.format("ll")}
            </span>
          </div>
          <div className="flex text-yellow-500">
            {stars}
          </div>
        </div>
        <span className="block mt-3 text-neutral-6000 dark:text-neutral-300">
          {comment}
        </span>
      </div>
      {userId?._id === user?._id && <ReviewOptions id={_id} />}
    </div>
  )
}

export default ReviewItem