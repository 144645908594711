import { MapPinIcon, MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import { useState, useRef, FC, useMemo } from "react";
import { useFormContext } from "react-hook-form";
import { MobileSearchForm } from "./Search";
import useLanguages from "hooks/useLanguages";
import { useQuery } from "@tanstack/react-query";
import api from "api";

interface Props {
  className?: string;
}

const LocationInput: FC<Props> = ({
  className = ""
}) => {
  const { t } = useLanguages();
  const { watch, setValue } = useFormContext<MobileSearchForm>();
  const [query, setQuery] = useState<string>(watch("region") ? t(`regions.${watch("region")}`) : "");
  const [hasSelected, setHasSelected] = useState<boolean>(false);
  const containerRef = useRef(null);
  const inputRef = useRef(null);

  const { data } = useQuery({
    queryKey: ["location"],
    queryFn: async () => {
      const res = await api.get('/hotels/regions');
      return res.data
    }
  });
  const regions = useMemo<IRegion[]>(() => data || [], [data]);

  const filteredRegions = useMemo(() => regions ? query !== "" ?
    regions.filter(item =>
      t(`regions.${item._id}`).toLowerCase().includes(query.toLowerCase())) : regions : []
    , [query, regions, t]);

  const handleSelectLocation = (item: string) => {
    setTimeout(() => {
      setQuery(t(`regions.${item}`));
      setValue("region", item);
      setHasSelected(true);
    }, 0);
  };

  const handleBlur = () => {
    if (!hasSelected && filteredRegions.length > 0) {
      const closestMatch = filteredRegions.find((item) =>
        t(`regions.${item._id}`).toLowerCase().startsWith(query.toLowerCase())
      );
      if (closestMatch) {
        const regionName = t(`regions.${closestMatch._id}`);
        setQuery(regionName);
        setValue("region", closestMatch._id);
      } else {
        setQuery(watch("region") ? t(`regions.${watch("region")}`) : "");
      }
    } else if (!hasSelected) {
      setQuery(watch("region") ? t(`regions.${watch("region")}`) : "");
    }
  };

  const renderSearch = useMemo(() => {
    if (filteredRegions.length === 0) return null;

    return (
      <div className="mt-3">
        {filteredRegions.map((item) => {
          return (
            <div
              className="py-2 mb-1 flex items-center space-x-3 text-sm"
              onClick={() => handleSelectLocation(item._id)}
              key={item._id}
            >
              <MapPinIcon className="w-5 h-5 text-neutral-500 dark:text-neutral-400" />
              <span className="">{t(`regions.${item._id}`)}</span>
            </div>
          );
        })}
      </div>
    )
  }, [filteredRegions, t, handleSelectLocation]);

  return (
    <div className={`${className}`} ref={containerRef}>
      <div className="p-5">
        <span className="block font-semibold text-xl sm:text-2xl">
          {t('search.where-to')}
        </span>
        <div className="relative mt-5">
          <input
            className={`block w-full bg-transparent border px-4 py-3 pr-12 border-neutral-900 dark:border-neutral-200 rounded-xl focus:ring-0 focus:outline-none text-base leading-none placeholder-neutral-500 dark:placeholder-neutral-300 truncate font-bold placeholder:truncate`}
            placeholder={t('search.location')}
            value={query}
            onChange={(e) => setQuery(e.currentTarget.value)}
            onBlur={handleBlur}
            ref={inputRef}
          />
          <span className="absolute right-2.5 top-1/2 -translate-y-1/2">
            <MagnifyingGlassIcon className="w-5 h-5 text-neutral-700 dark:text-neutral-400" />
          </span>
        </div>
        <div className="mt-7">
          {renderSearch}
        </div>
      </div>
    </div>
  );
};

export default LocationInput;
