import useLanguages from "hooks/useLanguages";
import { FC } from "react";
import btnIos from "images/btn-ios.png";
import btnAndroid from "images/btn-android.png"
import otellerQRcode from "images/qr-code.png"

const DownloadApp: FC = () => {
  const { t } = useLanguages();

  return (
    <div className="hidden md:flex md:col-span-2 flex-col gap-5 sm:flex-row sm:space-x-3 bg-primary-50 dark:bg-neutral-800 rounded-lg h-fit p-3">
      <div className="space-y-3 max-w-md">
        <h2 className="text-lg font-semibold">{t('general.download.title')}</h2>
        <p className="text-sm text-neutral-700 dark:text-neutral-400">{t('general.download.description')}</p>
        <div className="max-w-[260px w-full flex flex-col items-center gap-3 min-[320px]:gap-0 min-[320px]:flex-row min-[320px]:space-x-3">
          <a target="_blank" rel={"noreferrer"} href="/">
            <img src={btnIos} alt="ios" />
          </a>
          <a target="_blank" rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.oteller">
            <img src={btnAndroid} alt="android" />
          </a>
        </div>
      </div>
      <div className="self-center">
        <div className="pt-2 px-2 w-40 sm:w-36 xl:w-40 border border-neutral-200 bg-white rounded-lg overflow-hidden">
          <p className="text-[10px] text-center text-neutral-600">{t('general.download.scan-me')}</p>
          <img className="w-full object-cover" src={otellerQRcode} alt="qrCode" />
        </div>
      </div>
    </div>
  )
};

export default DownloadApp;