import { Popover, Transition } from "@headlessui/react";
import {
  UserCircleIcon,
  HeartIcon,
  HomeIcon,
  ArrowRightOnRectangleIcon,
} from "@heroicons/react/24/outline";
import { BASE_URL } from "api";
import { useApp } from "hooks/useApp";
import useAuth from "hooks/useAuth";
import useLanguages from "hooks/useLanguages";
import { Fragment } from "react";
import { Link } from "react-router-dom";
import Avatar from "shared/Avatar/Avatar";

const solutions = [
  {
    name: "account",
    href: "/account",
    icon: UserCircleIcon,
    isPermit: ["user", "host", "admin", "operator"]
  },
  {
    name: "hotels",
    href: "/author",
    icon: HomeIcon,
    isPermit: ["host"]
  },
  {
    name: "dashboard",
    href: "/dashboard",
    icon: UserCircleIcon,
    isPermit: ["admin", "operator"]
  },
  {
    name: "wishlist",
    href: "/wishlist",
    icon: HeartIcon,
    isPermit: ["user"]
  },
  {
    name: "booking",
    href: "/bookings",
    icon: HomeIcon,
    isPermit: ["user"]
  }
]

const solutionsFoot = [
  {
    name: "logout",
    icon: ArrowRightOnRectangleIcon,
  },
];

export default function AvatarDropdown() {
  const { t } = useLanguages();
  const { user } = useApp();
  const { logout } = useAuth({});

  const filteredSolutions = solutions.filter(item => {
    if (user && user.role) {
      return item.isPermit.includes(user.role);
    }

    return false;
  });

  return (
    <div className="AvatarDropdown">
      <Popover className="relative">
        {({ open }) => (
          <>
            <Popover.Button
              className={`inline-flex items-center focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75`}
            >
              <Avatar sizeClass="w-8 h-8 sm:w-9 sm:h-9" imgUrl={user?.image ? `${BASE_URL}/uploads/users/${user?.image}` : ""} userName={user?.firstName} />
            </Popover.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel className="absolute z-10 w-screen max-w-[260px] px-4 mt-4 -right-10 sm:right-0 sm:px-0">
                <div className="overflow-hidden rounded-3xl shadow-lg ring-1 ring-black ring-opacity-5">
                  <div className="relative grid gap-6 bg-white dark:bg-neutral-800 p-7">
                    {filteredSolutions.map((item, index) => (
                      <Link
                        key={index}
                        to={item.href}
                        className="flex items-center p-2 -m-3 transition duration-150 ease-in-out rounded-lg hover:bg-gray-100 dark:hover:bg-gray-700 focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-50"
                      >
                        <div className="flex items-center justify-center flex-shrink-0 text-neutral-500 dark:text-neutral-300">
                          <item.icon aria-hidden="true" className="w-6 h-6" />
                        </div>
                        <div className="ml-4">
                          <p className="text-sm font-medium ">{t(`sitemap.${item.name}`)}</p>
                        </div>
                      </Link>
                    ))}
                  </div>
                  <hr className="h-[1px] border-t border-neutral-300 dark:border-neutral-700" />
                  <div className="relative grid gap-6 bg-white dark:bg-neutral-800 p-7">
                    {solutionsFoot.map((item, index) => (
                      <button
                        onClick={() => logout()}
                        key={index}
                        className="flex items-center p-2 -m-3 transition duration-150 ease-in-out rounded-lg hover:bg-gray-50 dark:hover:bg-gray-700 focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-50"
                      >
                        <div className="flex items-center justify-center flex-shrink-0 text-neutral-500 dark:text-neutral-300">
                          <item.icon aria-hidden="true" className="w-6 h-6" />
                        </div>
                        <div className="ml-4">
                          <p className="text-sm font-medium ">{t(`button.${item.name}`)}</p>
                        </div>
                      </button>
                    ))}
                  </div>
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    </div>
  );
}
