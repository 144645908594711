import useLanguages from "hooks/useLanguages";
import { FC } from "react"
import { Link } from "react-router-dom"

export const AuthButton: FC = () => {
  const { t } = useLanguages();

  return (
    <div className="inline-flex items-center space-x-2">
      <Link to={"/sign-up"} className="text-opacity-90 group px-4 py-2 text-white bg-primary-6000 hover:bg-primary-500 rounded-full inline-flex items-center text-sm font-medium hover:text-opacity-100 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 transition-colors">{t('button.sign-up')}</Link>
      <Link to={"/sign-in"} className="text-opacity-90 group px-4 py-2 text-white bg-primary-6000 hover:bg-primary-500 rounded-full inline-flex items-center text-sm font-medium hover:text-opacity-100 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 transition-colors">{t('button.sign-in')}</Link>
    </div>
  )
}