import { EyeIcon, EyeSlashIcon } from "@heroicons/react/24/outline";
import React, { InputHTMLAttributes, useState } from "react";

export interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  sizeClass?: string;
  fontClass?: string;
  rounded?: string;
}

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  (
    {
      className = "",
      sizeClass = "h-11 px-4 py-3",
      fontClass = "text-sm font-normal",
      rounded = "rounded-2xl",
      children,
      type = "text",
      ...args
    },
    ref
  ) => {
    const [isShow, setShow] = useState<boolean>(false);

    return (
      <>

        <input
          ref={ref}
          type={type === "password" ? isShow ? "text" : "password" : type}
          className={`block w-full border-neutral-200 focus:border-primary-300 focus:ring focus:ring-primary-200 focus:ring-opacity-50 bg-white dark:border-neutral-700 dark:focus:ring-primary-6000 dark:focus:ring-opacity-25 dark:bg-neutral-900 ${rounded} ${fontClass} ${sizeClass} ${className}`}
          {...args}
        />
        {
          type === "password" && (
            <button type={"button"} onClick={() => setShow(!isShow)} className="absolute right-4 top-7 bottom-0">
              {isShow ? <EyeSlashIcon className="w-5 h-5" /> : <EyeIcon className="w-5 h-5" />}
            </button >
          )}
      </>
    );
  }
);

export default Input;
