import { useQuery } from "@tanstack/react-query";
import api, { BASE_URL, IMAGE_PATH } from "api";
import StartRating from "components/StartRating/StartRating";
import useLanguages from "hooks/useLanguages";
import useSearch from "hooks/useSearch";
import moment from "moment";
import { useMemo } from "react";
import { useSearchParams } from "react-router-dom";

const CheckoutSidebar = () => {
  const { t, i18n } = useLanguages();
  const [searchParams] = useSearchParams();
  const { startDate, endDate } = useSearch();

  const hotelId = searchParams.get("hotelId");
  const roomId = searchParams.get("roomId");

  const { data: hotelData } = useQuery({
    queryKey: ["checkout-hotel"],
    queryFn: async () => {
      const res = await api.get(`/hotels/${hotelId}`);
      return res.data
    }
  });
  const hotel = useMemo<IHotel>(() => hotelData || {}, [hotelData]);

  const { data: roomData } = useQuery({
    queryKey: ["checkout-room"],
    queryFn: async () => {
      const res = await api.get(`/hotels/${hotelId}/rooms/${roomId}`);
      return res.data
    }
  });
  const room = useMemo<IRoom>(() => roomData || {}, [roomData]);

  const point = hotel?.rating?.score / hotel?.rating?.count;
  const formattedPoint = isNaN(point) ? 0 : point.toFixed(1);
  const totalDays = moment(endDate).diff(startDate, "days");

  return (
    <div className="w-full flex flex-col sm:rounded-2xl lg:border border-neutral-200 dark:border-neutral-700 space-y-6 sm:space-y-8 px-0 sm:p-6 xl:p-8">
      <div className="flex flex-col sm:flex-row sm:items-center">
        <div className="flex-shrink-0 w-full sm:w-40">
          <div className=" aspect-w-4 aspect-h-3 sm:aspect-h-4 rounded-2xl overflow-hidden">
            <img
              alt={hotel?.title?.tm}
              className="absolute inset-0 object-cover"
              sizes="200px"
              src={hotel?.images ? `${IMAGE_PATH}/hotels/${hotel.images[0]}` : ""}
            />
          </div>
        </div>
        <div className="py-5 sm:px-5 space-y-3">
          <div>
            <span className="text-sm text-neutral-500 dark:text-neutral-400 line-clamp-1">
              {hotel?.address ? hotel?.address[i18n.language as "tm" | "en" | "ru"] : ''}, {t(`regions.${hotel?.region}`)}
            </span>
            <span className="text-base font-medium mt-1 block">
              {hotel?.title ? hotel?.title[i18n.language as "tm" | "en" | "ru"] : ""}
            </span>
          </div>
          <div className="w-10 border-b border-neutral-200  dark:border-neutral-700"></div>
          <StartRating reviewCount={hotel?.rating?.count} point={formattedPoint as number} />
        </div>
      </div>
      <div className="flex flex-col space-y-4">
        <h3 className="text-2xl font-semibold">{t('checkout.price-detail')}</h3>
        <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
          <span>{room?.price || 0} TMT x {totalDays} {totalDays > 1 ? t("counter.days.plural") : t('counter.days.single')}</span>
          <span>{room?.price * totalDays} TMT</span>
        </div>

        <div className="border-b border-neutral-200 dark:border-neutral-700"></div>
        <div className="flex justify-between font-semibold">
          <span>{t('checkout.total')}</span>
          <span>{room?.price * totalDays} TMT</span>
        </div>
      </div>
    </div>
  );
};

export default CheckoutSidebar;