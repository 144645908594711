import useLanguages from "hooks/useLanguages";
import { FC } from "react";
import NcImage from "shared/NcImage/NcImage";

type HotelCardType = {
  title: ITranslation
  image?: string
  address: ITranslation
  city: ITranslation
  contact?: {
    phone: string
  }
}

const HotelCard: FC<HotelCardType> = ({ title, image, contact, address, city }) => {
  const { i18n } = useLanguages();

  return (
    <div className="py-6 nc-HotelCard group flex gap-4 relative">
      <div className="flex-grow-0 flex-shrink-0 rounded-2xl w-24 h-24 md:w-36 md:h-36 overflow-hidden">
        <NcImage className="w-24 h-24 md:w-36 md:h-36 object-cover" src={image} alt={title.en} />
      </div>
      <div className="py-5 flex flex-col justify-between">
        <h2 className="text-lg sm:text-xl md:text-2xl font-semibold">{title[i18n.language as "en" | "tm" | "ru"]}</h2>
        <div>
          {contact?.phone &&
            <div>
              <i className="las la-phone"></i>
              <a className="text-xs sm:text-sm md:text-base" href={`tel:${contact?.phone}`}>{contact?.phone}</a>
            </div>
          }
          <div className="inline-flex items-center gap-1">
            <i className="las la-map"></i>
            <p className="text-xs sm:text-sm md:text-base">{city[i18n.language as "en" | "tm" | "ru"]}</p>
          </div>
        </div>
      </div>
    </div>
  )
};

export default HotelCard;