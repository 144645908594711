import { FC, useCallback } from "react";
import LocationInput from "./LocationInput";
import GuestsInput from "./GuestInput";
import DatesInput from "./DatesInput";
import useSearch from "hooks/useSearch";
import { FormProvider, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import useLanguages from "hooks/useLanguages";
import { useNavigate } from "react-router-dom";

export type SearchForm = {
  region: string,
  startDate: Date | null,
  endDate: Date | null,
  adultCount: number,
  childrenCount: number,
  roomCount: number,
}

const Search: FC<{}> = () => {
  const navigate = useNavigate();
  const { t } = useLanguages();
  const { region, startDate, endDate, adultCount, childrenCount, roomCount, saveSearch } = useSearch();
  const formMethods = useForm<SearchForm>({
    defaultValues: {
      region,
      startDate,
      endDate,
      adultCount,
      childrenCount,
      roomCount
    }
  });
  const { handleSubmit } = formMethods;

  const onSubmit = useCallback(async (values: SearchForm) => {
    if(!values.region){
      toast.error(t('alert.region-is-required'));
      return;
    };

    if(!values.startDate || !values.endDate){
      toast.error(t('alert.select-dates'));
      return;
    }

    saveSearch(
      values.region,
      values.startDate,
      values.endDate,
      values.adultCount,
      values.childrenCount,
      values.roomCount
    );
    navigate("/search");
  }, []);

  const renderForm = () => {
    return (
      <FormProvider {...formMethods}>
        <form onSubmit={handleSubmit(onSubmit)} className="w-full relative mt-8 flex rounded-full shadow-xl dark:shadow-2xl bg-white dark:bg-neutral-800 ">
          <LocationInput className="flex-1" />
          <div className="self-center border-r border-slate-200 dark:border-slate-700 h-8"></div>
          <DatesInput className="flex-1" />
          <div className="self-center border-r border-slate-200 dark:border-slate-700 h-8"></div>
          <GuestsInput className="flex-1" />
        </form>
      </FormProvider>
    );
  };

  return renderForm();
};

export default Search;
