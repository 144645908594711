const icons = [
  { label: "wi-fi", value: "las la-wifi" },
  { label: "parking", value: "las la-parking" },
  { label: "gym", value: "las la-dumbbell" },
  { label: "breakfast", value: "las la-coffee" },
  { label: "bikes", value: "las la-bicycle" },
  { label: "library", value: "las la-book-open" },
  { label: "spa", value: "las la-spa" },
  { label: "pool", value: "las la-swimmer" },
  { label: "restaurant", value: "las la-utensils" },
  { label: "bar", value: "las la-glass-martini-alt" },
  { label: "sauna", value: "las la-bath" },
];

export default icons;
