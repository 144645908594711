import { FC, useMemo, useState } from "react";
import CommonLayout from "./CommonLayout";
import useLanguages from "hooks/useLanguages";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { useQuery } from "@tanstack/react-query";
import { guestsApi } from "api/guest";
import { Helmet } from "react-helmet";
import { Modal } from "shared/Modal/Modal";
import GuestForm from "components/GuestForm/GuestForm";
import GuestCard from "components/GuestCard/GuestCard";

const AccountGuests: FC = () => {
  const { t } = useLanguages();
  const [isShow, setShow] = useState<boolean>(false);
  const { data } = useQuery({
    queryKey: ["guests"],
    queryFn: guestsApi.getAll,
  });
  const guests = useMemo<IGuest[]>(() => data || [], [data]);

  return (
    <>
      <div>
        <Helmet>
          <title>{t('pages.guests')} | Oteller</title>
        </Helmet>
        <CommonLayout>
          <div className="space-y-6 sm:space-y-8">
            <div className="inline-flex items-center space-x-4">
              <h2 className="text-3xl font-semibold">{t('pages.guests')}</h2>
              <ButtonPrimary onClick={() => setShow(true)}>{t('button.add')}</ButtonPrimary>
            </div>
            <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
            <div className="mt-8 grid grid-cols-1 gap-6 md:gap-8 xl:grid-cols-2">
              {guests.map((guest) => <GuestCard key={guest._id} guest={guest} />)}
            </div>
          </div>
        </CommonLayout>

      </div>
      <Modal title={t('pages.add-guest')} isOpen={isShow} open={() => setShow(false)}>
        <GuestForm toggle={() => setShow(false)} />
      </Modal>
    </>
  )
};

export default AccountGuests;