import { ArrowRightIcon } from "@heroicons/react/24/outline";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import api from "api";
import FiveStartIconForRate from "components/FiveStartIconForRate/FiveStartIconForRate";
import ReviewItem from "components/ReviewItem/ReviewItem";
import { useApp } from "hooks/useApp";
import useLanguages from "hooks/useLanguages";
import { FC, useCallback, useMemo } from "react";
import { useForm } from "react-hook-form";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import ButtonCircle from "shared/Button/ButtonCircle";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import Input from "shared/Input/Input";

const Comments: FC = () => {
  const { id } = useParams();
  const { isLoggedIn } = useApp();
  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useLanguages();
  const { data } = useQuery({
    queryKey: ["reviews", id],
    queryFn: async () => {
      const res = await api.get(`/reviews`, {
        params: {
          hotelId: id
        }
      });
      return res.data
    }
  });
  const reviews = useMemo<IReview[]>(() => data || [], [data]);

  const renderReviews = useMemo(() => {
    return reviews.map((review) => {
      return <ReviewItem {...review} key={review._id} />
    })
  }, [reviews]);

  return (
    <div className="listingSection__wrap">
      <h2 className="text-2xl font-semibold">{t('general.reviews')}</h2>
      <div className="w-14 border-b border-neutral-200 dark:border-neutral-700" />
      {isLoggedIn ? <CommentForm /> : (
        <div className="flex flex-col justify-center items-center gap-2">
          <h2 className="text-lg text-neutral-500 dark:text-neutral-400 font-medium">{t('alert.leave-review')}</h2>
          <ButtonPrimary onClick={() => navigate("/sign-in", { state: { from: location }})}>{t('button.sign-in')}</ButtonPrimary>
        </div>
      )}
      <div className="divide-y divide-neutral-100 dark:divide-neutral-800">
        {renderReviews}
      </div>
    </div>
  )
};

const CommentForm: FC = () => {
  const { id } = useParams();
  const { t } = useLanguages();
  const queryClient = useQueryClient();
  const { register, setValue, watch, reset, handleSubmit, formState: { errors } } = useForm<CommentFormType>({
    defaultValues: {
      comment: "",
      rating: 0
    }
  });
  const { mutate } = useMutation({
    mutationKey: ["add-review"],
    mutationFn: async (values: CommentFormType) => {
      const res = await api.post('/reviews', { ...values, hotelId: id });
      return res.data
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["reviews"] });
      queryClient.invalidateQueries({ queryKey: ["search-result"] });
      reset({
        comment: "",
        rating: 0
      })
    }
  });

  const onSubmit = useCallback(async (values: CommentFormType) => {
    mutate(values);
  }, []);

  return (
    <form className="space-y-5" onSubmit={handleSubmit(onSubmit)}>
      <FiveStartIconForRate
        defaultPoint={watch("rating")}
        onChange={(value) => setValue("rating", value)}
        iconClass="w-6 h-6"
        className="space-x-0.5"
      />
      <div className="relative">
        <Input
          sizeClass="h-16 px-4 py-3"
          className={`${errors.comment?.message ? "!border-red-600 !ring-red-100" : ""}`}
          rounded="rounded-3xl"
          placeholder={t('placeholder.share-your-thoughts')}
          {...register("comment", {
            required: "Required"
          })}
        />
        <ButtonCircle
          className="absolute right-2 top-1/2 transform -translate-y-1/2"
          size="w-12 h-12 "
        >
          <ArrowRightIcon className="w-5 h-5" />
        </ButtonCircle>
      </div>
    </form>
  )
}

export default Comments;