import { FC } from "react";
import Search from "./Search";

const Hero: FC = () => {
  return (
    <div className="nc-HeroForm w-full max-w-6xl py-5 lg:py-0">
      <Search />
    </div>
  )
};

export default Hero;