import useLanguages from "hooks/useLanguages";
import { FC } from "react";
import { Helmet } from "react-helmet";

const PrivacyPage: FC = () => {
  const { t } = useLanguages();

  return (
    <div className="nc-PagePrivacy overflow-hidden relative" data-nc-id="PagePrivacy">
      <Helmet>
        <title>{t('sitemap.privacy-policy')} | Oteller</title>
      </Helmet>
      <div className="container py-16 lg:py-24 space-y-3">
        <p className="font-medium">{t('privacy.last-updated')} 20.08.2024</p>
        <Heading text={t('privacy.introduction.title')} />
        <p className="">{t('privacy.introduction.body')}</p>

        <Heading text={t('privacy.information-we-collect.title')} />
        <p>{t('privacy.information-we-collect.desc')}</p>
        <ul className="pl-4">
          <li>{t('privacy.information-we-collect.list.name')}</li>
          <li>{t('privacy.information-we-collect.list.email')}</li>
          <li>{t('privacy.information-we-collect.list.phonenumber')}</li>
          <li>{t('privacy.information-we-collect.list.address')}</li>
        </ul>

        <Heading text={t('privacy.personal-information.title')} />
        <ul>
          <li>
            <span className="font-medium">
              {t('privacy.personal-information.account-information.title')}
            </span>
            {t('privacy.personal-information.account-information.body')}
          </li>
          <li>
            <span className="font-medium">
              {t('privacy.personal-information.booking-information.title')}
            </span>
            {t('privacy.personal-information.booking-information.body')}
          </li>
          <li>
            <span className="font-medium">
              {t('privacy.personal-information.payment-information.title')}
            </span>
            {t('privacy.personal-information.payment-information.body')}
          </li>
        </ul>

        <Heading text={t('privacy.usage-data.title')} />
        <p>{t('privacy.usage-data.body')}</p>

        <Heading text={t('privacy.how-we-use-your-information.title')} />
        <p>{t('privacy.how-we-use-your-information.desc')}</p>
        <ul>
          <li>{t('privacy.how-we-use-your-information.purpose1')}</li>
          <li>{t('privacy.how-we-use-your-information.purpose2')}</li>
          <li>{t('privacy.how-we-use-your-information.purpose3')}</li>
          <li>{t('privacy.how-we-use-your-information.purpose4')}</li>
          <li>{t('privacy.how-we-use-your-information.purpose5')}</li>
          <li>{t('privacy.how-we-use-your-information.purpose6')}</li>
          <li>{t('privacy.how-we-use-your-information.purpose7')}</li>
        </ul>

        <Heading text={t('privacy.sharing-your-information.title')} />
        <p>{t('privacy.sharing-your-information.desc')}</p>
        <ul>
          <li>
            <span className="font-medium">
              {t('privacy.sharing-your-information.service-providers.title')}
            </span>
            {t('privacy.sharing-your-information.service-providers.body')}
          </li>
          <li>
            <span className="font-medium">
              {t('privacy.sharing-your-information.legal-obligations.title')}
            </span>
            {t('privacy.sharing-your-information.legal-obligations.body')}
          </li>
          <li>
            <span className="font-medium">
              {t('privacy.sharing-your-information.with-your-consent.title')}
            </span>
            {t('privacy.sharing-your-information.with-your-consent.body')}
          </li>
        </ul>

        <Heading text={t("privacy.data-security.title")} />
        <p>{t('privacy.data-security.body')}</p>

        <Heading text={t('privacy.your-rights.title')} />
        <p>{t('privacy.your-rights.desc')}</p>
        <ul>
          <li>
            <span className="font-medium">
              {t('privacy.your-rights.access.title')}
            </span>
            {t('privacy.your-rights.access.body')}
          </li>
          <li>
            <span className="font-medium">
              {t('privacy.your-rights.correction.title')}
            </span>
            {t('privacy.your-rights.correction.body')}
          </li>
          <li>
            <span className="font-medium">
              {t('privacy.your-rights.deletion.title')}
            </span>
            {t('privacy.your-rights.deletion.body')}
          </li>
        </ul>
        <p>
          {t('privacy.your-rights.contact')}
          <a className="font-semibold underline" href="mailto:contact@oteller.com.tm">
            contact@oteller.com.tm
          </a>
        </p>

        <Heading text={t("privacy.childrens-privacy.title")} />
        <p>{t('privacy.childrens-privacy.body')}</p>

        <Heading text={t("privacy.changes-to-this-privacy-policy.title")} />
        <p>{t("privacy.changes-to-this-privacy-policy.body")}</p>

        <Heading text={t('privacy.contact-us.title')} />
        <p>{t('privacy.contact-us.body')}</p>
        <ul>
          <li>
            <span className="font-medium">{t('privacy.contact-us.email')}</span>
            <a href="mailto:contact@oteller.com.tm" className="underline">contact@oteller.com.tm</a>
          </li>
          <li>
            <span className="font-medium">{t('privacy.contact-us.phone')}</span>
            <a href="tel:+99312480613" className="underline">+993 12 48 06 13</a>
          </li>
        </ul>
      </div>
    </div>
  )
};

const Heading: FC<{ text: string }> = ({ text }) => {
  return <h2 className="text-xl font-semibold">{text}</h2>
}

export default PrivacyPage;