import { Fragment, useCallback, useState } from "react";
import { Dialog, Popover, Transition } from "@headlessui/react";
import NcInputNumber from "components/NcInputNumber/NcInputNumber";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonThird from "shared/Button/ButtonThird";
import ButtonClose from "shared/ButtonClose/ButtonClose";
import Slider from "rc-slider";
import convertNumbThousand from "utils/convertNumbThousand";
import useLanguages from "hooks/useLanguages";
import useSearch from "hooks/useSearch";
import { useForm } from "react-hook-form";
import DatesRange from "./DatesRange";

const regions = ["arkadag", "ashgabat", "balkan", "ahal", "dashoguz", "lebap", "mary"];

const TabFilters = () => {
  const { region, adultCount, childrenCount, roomCount, startDate, endDate, rangePrices, saveSearch, saveDates, saveRegion, saveGuests, setRangePrices } = useSearch();
  const { watch, setValue } = useForm({
    defaultValues: {
      region,
      adultCount,
      childrenCount,
      roomCount,
      startDate,
      endDate,
      min: rangePrices[0],
      max: rangePrices[1]
    }
  });
  const [isOpenMoreFilter, setisOpenMoreFilter] = useState(false);
  const [isOpenMoreFilterMobile, setisOpenMoreFilterMobile] = useState(false);
  const { t } = useLanguages();

  const onChangeDates = (startDate: Date | null, endDate: Date | null) => {
    setValue("startDate", startDate);
    setValue("endDate", endDate);
  };

  const onChangePrices = () => {
    setRangePrices([watch("min"), watch("max")]);
  }

  const onSave = useCallback(() => {
    saveSearch(
      watch("region"),
      watch("startDate"),
      watch("endDate"),
      watch("adultCount"),
      watch("childrenCount"),
      watch("roomCount"),
      [watch("min"), watch("max")]
    )
  }, []);

  const closeModalMoreFilter = () => setisOpenMoreFilter(false);
  const openModalMoreFilter = () => setisOpenMoreFilter(true);

  const closeModalMoreFilterMobile = () => setisOpenMoreFilterMobile(false);
  const openModalMoreFilterMobile = () => setisOpenMoreFilterMobile(true);

  const renderRegion = () => {
    return (
      <Popover className="relative">
        {({ open, close }) => (
          <>
            <Popover.Button
              className={`flex items-center justify-center px-4 py-2 text-sm rounded-full border border-neutral-300 dark:border-neutral-700 hover:border-neutral-400 dark:hover:border-neutral-500 focus:outline-none ${open ? "!border-primary-500 " : ""
                }`}
            >
              <span>{t('regions.title')}</span>
              <i className="las la-angle-down ml-2"></i>
            </Popover.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel className="absolute z-10 w-screen max-w-sm px-4 mt-3 left-0 sm:px-0 lg:max-w-xs">
                <div className="overflow-hidden rounded-2xl shadow-xl bg-white dark:bg-neutral-900 border border-neutral-200 dark:border-neutral-700">
                  <div className="relative flex flex-col px-5 py-6 space-y-1">
                    {regions.map((item) => (
                      <div
                        onClick={() => setValue("region", item)}
                        key={item}
                        className={`px-2 py-1.5 rounded-lg w-full inline-flex justify-between ${watch("region") === item ? "bg-primary-6000 hover:bg-primary-700 text-white" : "text-neutral-700 dark:text-neutral-200 hover:bg-neutral-100 hover:dark:bg-neutral-700"} items-center transition-colors`}>
                        <h2>{t(`regions.${item}`)}</h2>
                      </div>
                    ))}
                  </div>
                  <div className="p-5 bg-neutral-50 dark:bg-neutral-900 dark:border-t dark:border-neutral-800 flex items-center justify-end">
                    <ButtonPrimary
                      onClick={() => {
                        saveRegion(watch("region"))
                        close()
                      }}
                      sizeClass="px-4 py-2 sm:px-5"
                    >
                      {t('button.save')}
                    </ButtonPrimary>
                  </div>
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    );
  };

  const renderMobileRegion = () => {
    return (
      <div className="relative grid grid-cols-2 gap-2 p-3">
        {regions.map((item) => (
          <div
            onClick={() => setValue("region", item)}
            key={item}
            className={`px-2 py-1.5 rounded-lg w-full inline-flex justify-between ${watch("region") === item ? "bg-primary-6000 hover:bg-primary-700 text-white" : "text-neutral-700 dark:text-neutral-200 hover:bg-neutral-100 hover:dark:bg-neutral-700"} items-center transition-colors`}>
            <h2>{t(`regions.${item}`)}</h2>
          </div>
        ))}
      </div>
    )
  }

  const renderGuestsAndRooms = () => {
    return (
      <Popover className="relative">
        {({ open, close }) => (
          <>
            <Popover.Button
              className={`flex items-center justify-center px-4 py-2 text-sm rounded-full border border-neutral-300 dark:border-neutral-700 hover:border-neutral-400 dark:hover:border-neutral-500 focus:outline-none ${open ? "!border-primary-500 " : ""
                }`}
            >
              <span>{t('search.guests')}</span>
              <i className="las la-angle-down ml-2"></i>
            </Popover.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel className="absolute z-10 w-screen max-w-sm px-4 mt-3 left-0 sm:px-0 lg:max-w-md">
                <div className="overflow-hidden rounded-2xl shadow-xl bg-white dark:bg-neutral-900   border border-neutral-200 dark:border-neutral-700">
                  <div className="relative flex flex-col px-5 py-6 space-y-5">
                    <NcInputNumber
                      label={t('fields.adults')}
                      min={1}
                      max={10}
                      defaultValue={watch("adultCount")}
                      onChange={(value) => setValue("adultCount", value)}
                    />
                    <NcInputNumber
                      label={t('fields.children')}
                      min={0}
                      max={10}
                      defaultValue={watch("childrenCount")}
                      onChange={(value) => setValue("childrenCount", value)}
                    />
                    <NcInputNumber
                      label={t("fields.rooms")}
                      min={1}
                      max={10}
                      defaultValue={watch("roomCount")}
                      onChange={(value) => setValue("roomCount", value)}
                    />
                  </div>
                  <div className="p-5 bg-neutral-50 dark:bg-neutral-900 dark:border-t dark:border-neutral-800 flex items-center justify-end">
                    <ButtonPrimary
                      onClick={() => {
                        saveGuests(watch("adultCount"), watch("childrenCount"), watch("roomCount"))
                        close()
                      }}
                      sizeClass="px-4 py-2 sm:px-5"
                    >
                      {t('button.save')}
                    </ButtonPrimary>
                  </div>
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    );
  };

  const renderTabsPriceRage = () => {
    return (
      <Popover className="relative">
        {({ open, close }) => (
          <>
            <Popover.Button
              className={`flex items-center justify-center px-4 py-2 text-sm rounded-full border ${open ? "border-primary-500" : "border-neutral-200 dark:border-neutral-700"} focus:outline-none `}
            >
              <span>
                {`${convertNumbThousand(watch("min"))} TMT - ${convertNumbThousand(watch("max"))} TMT`}{" "}
              </span>
            </Popover.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel className="absolute z-10 w-screen max-w-sm px-4 mt-3 left-0 sm:px-0 ">
                <div className="overflow-hidden rounded-2xl shadow-xl bg-white dark:bg-neutral-900 border border-neutral-200 dark:border-neutral-700">
                  <div className="relative flex flex-col px-5 py-6 space-y-8">
                    <div className="space-y-5">
                      <span className="font-medium">{t('search.prices')}</span>
                      <Slider
                        range
                        className="text-red-400"
                        min={0}
                        max={4000}
                        defaultValue={[watch("min"), watch("max")]}
                        allowCross={false}
                        onChange={(e: number | number[]) => {
                          const value = e as number[]
                          setValue("min", value[0]);
                          setValue("max", value[1]);
                        }}
                      />
                    </div>

                    <div className="flex justify-between space-x-5">
                      <div>
                        <label
                          htmlFor="minPrice"
                          className="block text-sm font-medium text-neutral-700 dark:text-neutral-300"
                        >
                          {t('search.min')}
                        </label>
                        <div className="mt-1 relative rounded-md">
                          <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                            <span className="text-neutral-500 sm:text-sm">
                              TMT
                            </span>
                          </div>
                          <input
                            type="text"
                            name="minPrice"
                            disabled
                            id="minPrice"
                            className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-12 pr-3 sm:text-sm border-neutral-200 rounded-full text-neutral-900"
                            value={watch("min")}
                          />
                        </div>
                      </div>
                      <div>
                        <label
                          htmlFor="maxPrice"
                          className="block text-sm font-medium text-neutral-700 dark:text-neutral-300"
                        >
                          {t('search.max')}
                        </label>
                        <div className="mt-1 relative rounded-md">
                          <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                            <span className="text-neutral-500 sm:text-sm">
                              TMT
                            </span>
                          </div>
                          <input
                            type="text"
                            disabled
                            name="maxPrice"
                            id="maxPrice"
                            max={4000}
                            className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-12 pr-3 sm:text-sm border-neutral-200 rounded-full text-neutral-900"
                            value={watch("max")}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="p-5 bg-neutral-50 dark:bg-neutral-900 dark:border-t dark:border-neutral-800 flex items-center justify-between">
                    <ButtonThird onClick={() => {
                      setValue("min", 0);
                      setValue("max", 4000);
                      setRangePrices([0, 4000]);
                      close()
                    }} sizeClass="px-4 py-2 sm:px-5">
                      {t('button.clear')}
                    </ButtonThird>
                    <ButtonPrimary
                      onClick={() => {
                        onChangePrices();
                        close()
                      }}
                      sizeClass="px-4 py-2 sm:px-5"
                    >
                      {t('button.save')}
                    </ButtonPrimary>
                  </div>
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    );
  };

  const renderTabMoreFilter = () => {
    return (
      <div>
        <div
          className={`flex items-center justify-center px-4 py-2 text-sm rounded-full border border-neutral-200 dark:border-neutral-700 focus:outline-none cursor-pointer`}
          onClick={openModalMoreFilter}
        >
          <span>{t('search.dates')}</span>
        </div>

        <Transition appear show={isOpenMoreFilter} as={Fragment}>
          <Dialog
            as="div"
            className="fixed inset-0 z-50 overflow-y-auto"
            onClose={closeModalMoreFilter}
          >
            <div className="min-h-screen text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-40 dark:bg-opacity-60" />
              </Transition.Child>

              <span
                className="inline-block h-screen align-middle"
                aria-hidden="true"
              >
                &#8203;
              </span>
              <Transition.Child
                className="inline-block py-8 px-2 h-screen w-full max-w-4xl"
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <div className="inline-flex flex-col w-full max-w-4xl text-left align-middle transition-all transform overflow-hidden rounded-2xl bg-white dark:bg-neutral-900 dark:border dark:border-neutral-700 dark:text-neutral-100 shadow-xl">
                  <div className="relative flex-shrink-0 px-6 py-4 border-b border-neutral-200 dark:border-neutral-800 text-center">
                    <Dialog.Title
                      as="h3"
                      className="text-lg font-medium leading-6 text-gray-900"
                    >
                      {t('search.dates')}
                    </Dialog.Title>
                    <span className="absolute left-3 top-3">
                      <ButtonClose onClick={closeModalMoreFilter} />
                    </span>
                  </div>

                  <div className="flex-grow overflow-y-auto">
                    <div className="px-10 divide-y divide-neutral-200 dark:divide-neutral-800">
                      <div className="py-7">
                        <h2 className="text-xl font-medium">{t('search.check-in-check-out')}</h2>
                        <div className="mt-6 relative">
                          <DatesRange
                            startDate={watch("startDate")}
                            endDate={watch("endDate")}
                            onChangeDate={onChangeDates}
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="p-6 flex-shrink-0 bg-neutral-50 dark:bg-neutral-900 dark:border-t dark:border-neutral-800 flex items-center justify-end">
                    <ButtonPrimary
                      onClick={() => {
                        saveDates(watch("startDate"), watch("endDate"))
                        closeModalMoreFilter()
                      }}
                      sizeClass="px-4 py-2 sm:px-5"
                    >
                      {t('button.save')}
                    </ButtonPrimary>
                  </div>
                </div>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition>
      </div>
    );
  };

  const renderTabMoreFilterMobile = () => {
    return (
      <div>
        <div
          className={`flex lg:hidden items-center justify-center px-4 py-2 text-sm rounded-full border border-neutral-200 dark:border-neutral-700 focus:outline-none cursor-pointer`}
          onClick={openModalMoreFilterMobile}
        >
          <span>{t('search.filter')} (4)</span>
        </div>

        <Transition appear show={isOpenMoreFilterMobile} as={Fragment}>
          <Dialog
            as="div"
            className="fixed inset-0 z-50 overflow-y-auto"
            onClose={closeModalMoreFilterMobile}
          >
            <div className="min-h-screen text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-40 dark:bg-opacity-60" />
              </Transition.Child>

              <span
                className="inline-block h-screen align-middle"
                aria-hidden="true"
              >
                &#8203;
              </span>
              <Transition.Child
                className="inline-block py-8 px-2 h-screen w-full max-w-4xl"
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <div className="inline-flex flex-col w-full max-w-4xl text-left align-middle transition-all transform overflow-hidden rounded-2xl bg-white dark:bg-neutral-900 dark:border dark:border-neutral-700 dark:text-neutral-100 shadow-xl h-full">
                  <div className="relative flex-shrink-0 px-6 py-4 border-b border-neutral-200 dark:border-neutral-800 text-center">
                    <Dialog.Title
                      as="h3"
                      className="text-lg font-medium leading-6 text-gray-900 dark:text-white"
                    >
                      {t('search.filter')}
                    </Dialog.Title>
                    <span className="absolute left-3 top-3">
                      <ButtonClose onClick={closeModalMoreFilterMobile} />
                    </span>
                  </div>

                  <div className="flex-grow overflow-y-auto">
                    <div className="px-4 sm:px-6 divide-y divide-neutral-200 dark:divide-neutral-800">

                      <div className="py-7">
                        <h3 className="text-xl font-medium">{t('regions.title')}</h3>
                        <div className="mt-6 relative ">
                          {renderMobileRegion()}
                        </div>
                      </div>

                      <div className="py-7">
                        <h3 className="text-xl font-medium">{t('search.prices')}</h3>
                        <div className="mt-6 relative ">
                          <div className="relative flex flex-col space-y-8">
                            <div className="space-y-5">
                              <Slider
                                range
                                className="text-red-400"
                                min={0}
                                max={4000}
                                defaultValue={[watch("min"), watch("max")]}
                                allowCross={false}
                                onChange={(e: number | number[]) => {
                                  const value = e as number[]
                                  setValue("min", value[0]);
                                  setValue("max", value[1]);
                                }}
                              />
                            </div>

                            <div className="flex justify-between space-x-5">
                              <div>
                                <label
                                  htmlFor="minPrice"
                                  className="block text-sm font-medium text-neutral-700 dark:text-neutral-300"
                                >
                                  {t('search.min')}
                                </label>
                                <div className="mt-1 relative rounded-md">
                                  <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                    <span className="text-neutral-500 sm:text-sm">
                                      TMT
                                    </span>
                                  </div>
                                  <input
                                    type="text"
                                    name="minPrice"
                                    disabled
                                    id="minPrice"
                                    className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-12 pr-3 sm:text-sm border-neutral-200 rounded-full text-neutral-900"
                                    value={watch("min")}
                                  />
                                </div>
                              </div>
                              <div>
                                <label
                                  htmlFor="maxPrice"
                                  className="block text-sm font-medium text-neutral-700 dark:text-neutral-300"
                                >
                                  {t('search.max')}
                                </label>
                                <div className="mt-1 relative rounded-md">
                                  <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                    <span className="text-neutral-500 sm:text-sm">
                                      TMT
                                    </span>
                                  </div>
                                  <input
                                    type="text"
                                    disabled
                                    name="maxPrice"
                                    id="maxPrice"
                                    className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-12 pr-3 sm:text-sm border-neutral-200 rounded-full text-neutral-900"
                                    value={watch("max")}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* ---- */}
                      <div className="py-7">
                        <h3 className="text-xl font-medium">{t('search.guests')}</h3>
                        <div className="mt-6 relative flex flex-col space-y-5">
                          <NcInputNumber
                            onChange={(value) => setValue("adultCount", value)}
                            defaultValue={watch("adultCount")}
                            label={t('fields.adults')}
                            min={1}
                            max={10}
                          />
                          <NcInputNumber
                            onChange={(value) => setValue("childrenCount", value)}
                            defaultValue={watch("childrenCount")}
                            label={t('fields.children')}
                            min={0}
                            max={10}
                          />
                          <NcInputNumber
                            onChange={(value) => setValue("roomCount", value)}
                            defaultValue={watch("roomCount")}
                            label={t('fields.rooms')}
                            min={1}
                            max={10}
                          />
                        </div>
                      </div>

                      <div className="py-7">
                        <h3 className="text-xl font-medium">{t('search.dates')}</h3>
                        <div className="mt-6 relative ">
                          <DatesRange
                            startDate={watch("startDate")}
                            endDate={watch("endDate")}
                            onChangeDate={onChangeDates}
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="p-4 sm:p-6 flex-shrink-0 bg-neutral-50 dark:bg-neutral-900 dark:border-t dark:border-neutral-800 flex items-center justify-end">
                    <ButtonPrimary
                      onClick={() => {
                        onSave()
                        closeModalMoreFilterMobile()
                      }}
                      sizeClass="px-4 py-2 sm:px-5"
                    >
                      {t('button.save')}
                    </ButtonPrimary>
                  </div>
                </div>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition>
      </div>
    );
  };

  return (
    <div className="flex lg:space-x-4">
      <div className="hidden lg:flex space-x-4">
        {renderRegion()}
        {renderGuestsAndRooms()}
        {renderTabsPriceRage()}
        {renderTabMoreFilter()}
      </div>
      {renderTabMoreFilterMobile()}
    </div>
  );
};

export default TabFilters;