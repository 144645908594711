import React, { useEffect } from "react";
import Header from "components/Header/Header";

let OPTIONS = {
  root: null,
  rootMargin: "0px",
  threshold: 1.0,
};
let OBSERVER: IntersectionObserver | null = null;

const SiteHeader = () => {
  const anchorRef = React.useRef<HTMLDivElement>(null);
  const [isTopOfPage, setIsTopOfPage] = React.useState(window.pageYOffset < 5);

  const intersectionCallback = (entries: IntersectionObserverEntry[]) => {
    entries.forEach((entry) => {
      setIsTopOfPage(entry.isIntersecting);
    });
  };

  useEffect(() => {
    OBSERVER = new IntersectionObserver(intersectionCallback, OPTIONS);
    anchorRef.current && OBSERVER.observe(anchorRef.current);
  }, []);

  return (
    <>
      <Header className={isTopOfPage ? "" : "shadow-sm dark:border-b dark:border-neutral-700"} />
      <div ref={anchorRef} className="h-1 absolute invisible"></div>
    </>
  );
};

export default SiteHeader;
