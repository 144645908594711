import { SearchContext } from "context/SearchContext"
import { useContext } from "react"

const useSearch = () => {
  const context = useContext(SearchContext);

  if(!context){
    throw new Error("useSearch must be used in SearchProvider");
  };

  return context;
};

export default useSearch;