import { useMutation, useQueryClient } from "@tanstack/react-query";
import api from "api";
import toast from "react-hot-toast";
import useLanguages from "./useLanguages";

const useBooking = () => {
  const queryClient = useQueryClient();
  const { t, i18n } = useLanguages();

  const { mutate: changeStatus } = useMutation({
    mutationKey: ["change-status"],
    mutationFn: async ({ id, value }: { id: string, value: "booked" | "rejected" }) => {
      const res = await api.put(`/bookings/${id}?lang=${i18n.language}`, { status: value });
      return res.data
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["hotel-bookings"] });
    },
    onError: (err: any) => {
      toast.error(t('alert.something-went-wrong'));
    }
  });

  return { changeStatus };
};

export default useBooking;