import { Menu } from "@headlessui/react";
import { EllipsisVerticalIcon, TrashIcon } from "@heroicons/react/24/outline";
import useLanguages from "hooks/useLanguages";
import useUser from "hooks/useUser";
import { FC } from "react";

interface ReviewOptionsProps {
  id: string
}

const ReviewOptions: FC<ReviewOptionsProps> = ({ id }) => {
  const { t } = useLanguages();
  const { removeReview } = useUser();

  return (
    <div className="relative w-fit text-right">
      <Menu >
        <Menu.Button className={"inline-flex items-center gap-2 rounded-md py-1.5 px-3 text-sm/6 font-semibold focus:outline-none"}>
          <EllipsisVerticalIcon className="w-5 h-5" />
        </Menu.Button>
        <Menu.Items className={"absolute right-4 w-32 origin-top-left rounded-xl border bg-neutral-50 shadow-sm dark:bg-neutral-800 border-white/5 p-1 text-sm/6 dark:text-white transition duration-100 ease-out [--anchor-gap:var(--spacing-1)] focus:outline-none data-[closed]:scale-95 data-[closed]:opacity-0"}>
          <Menu.Item>
            <button onClick={() => removeReview(id)} className="group flex w-full items-center gap-2 rounded-lg py-1.5 px-3 data-[focus]:bg-white/10 hover:text-red-600">
              <TrashIcon className="w-5 h-5 " />
              {t('button.delete')}
              <kbd className="ml-auto hidden font-sans text-xs text-white/50 group-data-[focus]:inline">⌘D</kbd>
            </button>
          </Menu.Item>
        </Menu.Items>
      </Menu>
    </div>
  )
};

export default ReviewOptions;