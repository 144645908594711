import { useMutation } from "@tanstack/react-query";
import { userApi } from "api/user";
import FormItem from 'components/FormItem';
import { useApp } from "hooks/useApp";
import useLanguages from "hooks/useLanguages";
import { FC, useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import Input from "shared/Input/Input";
import Select from "shared/Select/Select";
import Textarea from "shared/Textarea/Textarea";

type FieldType = {
  name: keyof IRequest
  type: "text" | "email" | "select" | "textarea"
}

const fields: FieldType[] = [
  { name: "subject", type: "select" },
  { name: "firstName", type: "text" },
  { name: "lastName", type: "text" },
  { name: "email", type: "email" },
  { name: "phonenumber", type: "text" },
  { name: "message", type: "textarea" },
]

const PartnerForm: FC = () => {
  const { t } = useLanguages();
  const { user } = useApp();
  const [alertMessage, setMessage] = useState<{ type: string, message: string }>({ type: "", message: "" });
  const { register, setValue, watch, reset, handleSubmit, formState: { isDirty } } = useForm<IRequest>();
  const { mutate, isPending } = useMutation({
    mutationKey: ["send-request"],
    mutationFn: userApi.sendRequest,
    onSuccess: () => {
      setMessage({ type: "success", message: "alert.request-accepted" });
      reset({
        subject: undefined,
        firstName: "",
        lastName: "",
        email: "",
        phonenumber: "",
        message: ""
      });
    },
    onError: (err: any) => {
      setMessage({ type: "error", message: "alert.request-failed" });
    }
  })

  useEffect(() => {
    if (user) {
      reset({
        userId: user?._id,
        firstName: user?.firstName,
        lastName: user?.lastName,
        email: user?.email,
        phonenumber: user?.phonenumber
      })
    };
  }, [user]);

  const onSubmit = useCallback(async (values: IRequest) => {
    mutate(values)
  }, []);

  return (
    <div className="flex-shrink-0 mb-0 lg:mb-14 lg:mr-10 lg:w-2/5 space-y-5">
      <h2 className="font-semibold text-3xl sm:text-4xl mt-6 sm:mt-11">{t('general.become-partner')}</h2>
      <p className="mt-6 text-neutral-500 dark:text-neutral-400">{t('general.become-partner-description')}</p>
      <form className="lg:my-10 space-y-4" onSubmit={handleSubmit(onSubmit)}>
        {fields.map((item, i) => {
          if (item.type === "select") {
            return (
              <FormItem key={i} label={t(`fields.${item.name}`)}>
                <Select defaultValue={watch("subject")} onChange={(e) => setValue(item.name, e.target.value)}>
                  <option value="">{t('subjects.placeholder')}</option>
                  <option value="hosting">{t('subjects.hosting')}</option>
                  <option value="advertising">{t('subjects.advertising')}</option>
                  <option value="shareholder">{t('subjects.shareholder')}</option>
                </Select>
              </FormItem>
            )
          } else if (item.type === "textarea") {
            return (
              <FormItem key={item.name} label={t(`fields.${item.name}`)}>
                <Textarea defaultValue={watch(item.name)} {...register(item.name)}></Textarea>
              </FormItem>
            )
          } else {
            return (
              <FormItem key={item.name} label={t(`fields.${item.name}`)}>
                <Input type={item.type} defaultValue={watch(item.name)} {...register(item.name, { required: "Required" })} />
              </FormItem>
            )
          }
        })}
        {alertMessage.message && <p className={`my-4 ${alertMessage.type === "success" ? `bg-green-100 dark:bg-green-200` : "bg-red-200 dark:bg-red-300"} dark:text-neutral-900 rounded-xl px-4 py-2 text-sm`}>{t(alertMessage.message)}</p>}
        <ButtonPrimary loading={isPending} disabled={!isDirty || isPending}>{t("button.send")}</ButtonPrimary>
      </form>
    </div>
  )
};

export default PartnerForm;