import useSearch from "hooks/useSearch";
import useConvertDates from "hooks/useConvertDates";
import { FC } from "react";
import ModalSearchForm from "./ModalSearchForm";
import useLanguages from "hooks/useLanguages";

interface MobileFooterProps {
  cheapestPrice: number
}

const MobileFooter: FC<MobileFooterProps> = ({ cheapestPrice }) => {
  const { t } = useLanguages();
  const { startDate, endDate, adultCount, childrenCount, roomCount } = useSearch();
  const checkIn = useConvertDates(startDate);
  const checkOut = useConvertDates(endDate);
  const guestCount = adultCount + childrenCount

  return (
    <div className="block lg:hidden fixed bottom-0 inset-x-0 py-2 sm:py-3 bg-white dark:bg-neutral-800 border-t border-neutral-200 dark:border-neutral-6000 z-40">
      <div className="container flex items-center justify-between">
        <div className="">
          <span className="block font-semibold">
            {guestCount} {guestCount > 1 ? t("counter.guests.plural") : t('counter.guests.single')}{", "} 
            {roomCount} {roomCount > 1 ? t('counter.rooms.plural') : t('counter.rooms.single')}
          </span>
          <ModalSearchForm
            renderChildren={({ openModal }) => (
              <span
                onClick={openModal}
                className="block text-sm underline font-medium"
              >
                {checkIn.format("MMM DD")} - {checkOut.format("MMM DD")}
              </span>
            )}
          />
        </div>
        <div className="inline-flex items-center">
          <p className="text-lg text-primary-6000 font-semibold">{cheapestPrice} TMT</p>
          <span>/{t('general.night')}</span>
        </div>
      </div>
    </div>
  );
};

export default MobileFooter;
