import ReactDOM from "react-dom/client";
import "rc-slider/assets/index.css";
import "./styles/index.scss";
import "./index.css";
import "./fonts/line-awesome-1.3.0/css/line-awesome.css";
import "utils/i18n"
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { QueryClientProvider, QueryClient } from "@tanstack/react-query";
import { AppProvider } from "context/AppContext";
import { SearchProvider } from "context/SearchContext";

const queryClient = new QueryClient();

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <QueryClientProvider client={queryClient}>
    <AppProvider>
      <SearchProvider>
        <App />
      </SearchProvider>
    </AppProvider>
  </QueryClientProvider>
);

reportWebVitals();
