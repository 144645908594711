import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import HotelCard from "components/HotelCard";
import hotels from "data/hotels";
import { FC, useMemo } from "react";
import { Helmet } from "react-helmet";
import { useSearchParams } from "react-router-dom";

const HotelsPage: FC = () => {
  const [searchParams] = useSearchParams();
  const region = searchParams.get("region");

  const filteredHotels = useMemo(() => {
    if (region) {
      return hotels.find((hotel) => hotel.province === region)
      ?.hotels.sort((a, b) => a.title.en.localeCompare(b.title.en)).map(
          (hotel) => {
            return <HotelCard
              title={hotel.title}
              image={hotel.image}
              contact={hotel.contact}
              address={hotel.address}
              city={hotel.city}
            />
          });
    }
  }, [region]);

  return (
    <div className="nc-HotelsPage relative" data-nc-id="HotelsPage">
      <Helmet>
        <title>Oteller</title>
      </Helmet>

      <BgGlassmorphism />

      <div className="container py-16 divide-y-[1px] divide-neutral-200 dark:divide-neutral-700">{filteredHotels}</div>
    </div>
  )
};

export default HotelsPage;