import { Tab } from "@headlessui/react";
import FormItem from "components/FormItem";
import useLanguages from "hooks/useLanguages";
import { FC } from "react"
import { useFormContext } from "react-hook-form";
import Input from "shared/Input/Input";
import PhoneInput from "shared/Input/PhoneInput";
import Textarea from "shared/Textarea/Textarea";

const regions: string[] = [
  "arkadag",
  "ashgabat",
  "balkan",
  "ahal",
  "lebap",
  "dashoguz",
  "mary"
]

const ListingHotel1: FC = () => {
  const { t } = useLanguages();
  const { watch, register } = useFormContext<ListingHotelType>();

  return (
    <>
      <div>
        <h2 className="text-2xl font-semibold">{t('listing-hotel.info')}</h2>
        <p className="text-neutral-500 dark:text-neutral-400">{t('alert.fill-fields')}</p>
      </div>
      <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
      <Tab.Group>
        <Tab.List className={"flex "}>
          {["tm", "en", "ru"].map((lang) => (
            <Tab key={lang}>
              {({ selected }) => (
                <div
                  className={`flex-shrink-0 block !leading-none font-medium px-3 py-2.5 text-center uppercase focus:outline-none focus:border-none focus:ring-0 ${selected ? "opacity-100 border-b-2 border-primary-6000" : "opacity-80"}`}>
                  {lang}
                </div>
              )}
            </Tab>
          ))}
        </Tab.List>
        <Tab.Panels>
          <Tab.Panel>
            <div className="space-y-8">
              <FormItem label={t('fields.hotel-name')}>
                <Input
                  type={"text"}
                  autoComplete="off"
                  {...register("title.tm", { required: "Required" })} />
              </FormItem>
              <FormItem label={t('fields.address')}>
                <Input
                  type={"text"}
                  autoComplete="off"
                  {...register("address.tm", { required: "Required" })} />
              </FormItem>
              <FormItem label={t('fields.description')}>
                <Textarea
                  autoComplete="off"
                  {...register("description.tm", { required: "Required" })}></Textarea>
              </FormItem>
            </div>
          </Tab.Panel>
          <Tab.Panel>
            <div className="space-y-8">
              <FormItem label={t('fields.hotel-name')}>
                <Input
                  type={"text"}
                  autoComplete="off"
                  {...register("title.en", { required: "Required" })} />
              </FormItem>
              <FormItem label={t('fields.address')}>
                <Input
                  type={"text"}
                  autoComplete="off"
                  {...register("address.en", { required: "Required" })} />
              </FormItem>
              <FormItem label={t('fields.description')}>
                <Textarea
                  autoComplete="off"
                  {...register("description.en", { required: "Required" })}></Textarea>
              </FormItem>
            </div>
          </Tab.Panel>
          <Tab.Panel>
            <div className="space-y-8">
              <FormItem label={t('fields.hotel-name')}>
                <Input
                  type={"text"}
                  autoComplete="off"
                  {...register("title.ru", { required: "Required" })} />
              </FormItem>
              <FormItem label={t('fields.address')}>
                <Input
                  type={"text"}
                  autoComplete="off"
                  {...register("address.ru", { required: "Required" })} />
              </FormItem>
              <FormItem label={t('fields.description')}>
                <Textarea
                  autoComplete="off"
                  {...register("description.ru", { required: "Required" })}></Textarea>
              </FormItem>
            </div>
          </Tab.Panel>
        </Tab.Panels>
      </Tab.Group>
      <FormItem label={t('fields.phonenumber')} className="relative">
        <PhoneInput
          autoComplete="off"
          {...register("phonenumber", { required: "Required" })}
        />
      </FormItem>
      <FormItem label={t('fields.region')}>
        <select className="p-3 rounded-2xl capitalize" {...register('region', { required: "Required" })} defaultValue={watch("region")}>
          {regions.map((region) => (
            <option key={region} value={region}>{t(`regions.${region}`)}</option>
          ))}
        </select>
      </FormItem>
    </>

  )
}

export default ListingHotel1;