import { Popover, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/24/solid";
import hotels from "data/hotels";
import useLanguages from "hooks/useLanguages";
import { Fragment } from "react";
import { Link } from "react-router-dom";

interface SolutionItem {
  name: string;
  description?: string;
  href: string;
}

const solutions: SolutionItem[] = [
  {
    name: "arkadag",
    href: "/hotels?region=arkadag"
  },
  {
    name: "ashgabat",
    href: "/hotels?region=ashgabat"
  },
  {
    name: "balkan",
    href: "/hotels?region=balkan"
  },
  {
    name: "awaza",
    href: "/hotels?region=awaza"
  },

  {
    name: "ahal",
    href: "/hotels?region=ahal"
  },
  {
    name: "dashoguz",
    href: "/hotels?region=dashoguz"
  },
  {
    name: "lebap",
    href: "/hotels?region=lebap"
  },
  {
    name: "mary",
    href: "/hotels?region=mary"
  },

];

export default function DropdownTravelers() {
  const { t } = useLanguages();

  return (
    <div className="DropdownTravelers">
      <Popover className="relative">
        {({ open, close }) => (
          <>
            <Popover.Button
              className={`${open ? "" : "text-opacity-90"}
                group py-2 rounded-md text-sm sm:text-base font-medium hover:text-opacity-100 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75`}
            >
              <div className={` inline-flex items-center `} role="button">
                <span>{t('general.hotels')}</span>
                <ChevronDownIcon
                  className={`${open ? "-rotate-180" : "text-opacity-70 "}
                  ml-2 h-5 w-5 text-neutral-700 group-hover:text-opacity-80 transition ease-in-out duration-150 `}
                  aria-hidden="true"
                />
              </div>
            </Popover.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel className="absolute z-40 w-screen max-w-lg px-4 mt-4 transform -translate-x-1/2 left-1/2 sm:px-0">
                <div className="overflow-hidden rounded-2xl shadow-lg ring-1 ring-black ring-opacity-5">
                  <div className="relative grid grid-cols-2 gap-8 bg-white dark:bg-neutral-800 p-7 ">
                    {solutions.map((item, index) => {
                      const count = hotels.find((hotel) => hotel.province === item.name)?.hotels

                      return <Link
                        key={index}
                        to={item.href}
                        onClick={() => close()}
                        className={`flex items-center p-2 -m-3 transition duration-150 ease-in-out rounded-lg hover:bg-neutral-50 dark:hover:bg-neutral-700 focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-50`}
                      >
                        <div className="flex items-center justify-center flex-shrink-0 w-10 h-10 bg-primary-50 rounded-md overflow-hidden text-primary-500 sm:h-12 sm:w-12">
                          {count && <img className="w-full h-full object-cover" src={count[0].image} alt={count[0].title["tm"]} />}
                        </div>
                        <div className="ml-4 space-y-0.5">
                          <p className="text-sm font-medium ">{t(`regions.${item.name}`)}</p>
                          <p className="text-xs text-neutral-500 dark:text-neutral-300">
                            {count?.length} {count && count?.length > 1 ? t("counter.hotels.plural") : t("counter.hotels.single")}
                          </p>
                        </div>
                      </Link>
                    })}
                  </div>
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    </div>
  );
}
