import {useState, useEffect} from 'react'


const useCounter = (fromSeconds: number) => {
	const [current, setCurrent] = useState<number>(fromSeconds)
	const [isCompleted, setCompleted] = useState<boolean>(false)
	const [isReset, setReset] = useState<boolean>(false)

	const reset = () => {
		setCompleted(false)
		setCurrent(fromSeconds)
		setReset(prev => !prev)
	}

	useEffect(() => {
		const interval = setInterval(() => {
			setCurrent(prev => {
				if (prev === 0) {
					setCompleted(true)
					clearInterval(interval)
					return 0
				}
				return prev - 1
			})
		}, 1000)

		return () => {
			clearInterval(interval)
		}
	}, [isReset])

	return {
		minutes: Math.floor(current / 60),
		seconds: current % 60,
		reset,
		isCompleted,
	}
}

export default useCounter
