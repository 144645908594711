import { Fragment } from "react";
import { Popover, Transition } from "@headlessui/react";
import { FC } from "react";
import ButtonClear from "./ButtonClear";
import ButtonSubmit from "./ButtonSubmit";
import { UserPlusIcon } from "@heroicons/react/24/outline";
import NcInputNumber from "components/NcInputNumber/NcInputNumber";
import { useFormContext } from "react-hook-form";
import useLanguages from "hooks/useLanguages";
import { SearchForm } from "./Search";

export interface GuestsInputProps {
  fieldClassName?: string;
  className?: string;
  buttonSubmitHref?: string;
  hasButtonSubmit?: boolean;
}

const GuestsInput: FC<GuestsInputProps> = ({
  fieldClassName = "[ nc-hero-field-padding ]",
  className = "[ nc-flex-1 ]"
}) => {
  const { t } = useLanguages();
  const { watch, setValue } = useFormContext<SearchForm>();

  const handleChangeData = (value: number, type: keyof GuestObject) => {
    if (type === "adultCount") {
      setValue("adultCount", value)
    }
    if (type === "childrenCount") {
      setValue("childrenCount", value);
    }
    if (type === "roomCount") {
      setValue("roomCount", value);
    }
  };

  const totalGuests = watch("adultCount") + watch("childrenCount");

  return (
    <Popover className={`flex relative ${className}`}>
      {({ open }) => (
        <>
          <div
            className={`flex-1 z-10 flex items-center focus:outline-none ${open ? "nc-hero-field-focused" : ""
              }`}
          >
            <Popover.Button
              className={`relative z-10 flex-1 flex text-left items-center ${fieldClassName} space-x-3 focus:outline-none`}
              onClickCapture={() => document.querySelector("html")?.click()}
            >
              <div className="text-neutral-300 dark:text-neutral-400">
                <UserPlusIcon className="w-5 h-5 lg:w-7 lg:h-7" />
              </div>
              <div className="flex-grow">
                <span className="block xl:text-lg font-semibold">
                  {totalGuests || ""} {totalGuests > 1 ? t('counter.guests.plural') : t('counter.guests.single')}, {watch('roomCount') ? `${watch("roomCount")} ${watch("roomCount") > 1 ? t('counter.rooms.plural') : t('counter.rooms.single')}` : ""}
                </span>
                <span className="block mt-1 text-sm text-neutral-400 leading-none font-light">
                  {t('search.guests')}
                </span>
              </div>

              {!!totalGuests && open && (
                <ButtonClear
                  onClick={() => {
                    setValue("adultCount", 1);
                    setValue("childrenCount", 0);
                    setValue("roomCount", 1);
                  }}
                />
              )}
            </Popover.Button>

            <div className="pr-2 xl:pr-4">
              <ButtonSubmit />
            </div>
          </div>

          {open && (
            <div className="h-8 absolute self-center top-1/2 -translate-y-1/2 z-0 -left-0.5 right-1 bg-white dark:bg-neutral-800"></div>
          )}
          <Transition
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1"
          >
            <Popover.Panel className="absolute right-0 z-10 w-full sm:min-w-[340px] max-w-sm bg-white dark:bg-neutral-800 top-full mt-3 py-5 sm:py-6 px-4 sm:px-8 rounded-3xl shadow-xl">
              <NcInputNumber
                className="w-full"
                defaultValue={watch("adultCount")}
                onChange={(value) => handleChangeData(value, "adultCount")}
                max={10}
                min={1}
                label={t('fields.adults')}
                desc={t('placeholder.over-twelve')}
              />
              <NcInputNumber
                className="w-full mt-6"
                defaultValue={watch("childrenCount")}
                onChange={(value) => handleChangeData(value, "childrenCount")}
                max={10}
                min={0}
                label={t('fields.children')}
                desc={t('placeholder.upto-twelve')}
              />

              <NcInputNumber
                className="w-full mt-6"
                defaultValue={watch("roomCount")}
                onChange={(value) => handleChangeData(value, "roomCount")}
                max={10}
                min={1}
                label={t('fields.rooms')}
              />
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  );
};

export default GuestsInput;
