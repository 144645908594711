import { FC, useEffect, useMemo } from "react";
import Glide from "@glidejs/glide";
import useNcId from "hooks/useNcId";
import RegionCard from "components/RegionCard";

export interface SectionRegionsProps {
  className?: string;
  itemClassName?: string;
  loading?: boolean
  heading?: string;
  regions: IRegion[]
  subHeading?: string;
  itemPerRow?: 4 | 5;
  sliderStyle?: "style1" | "style2";
  uniqueClassName: string;
}

const SectionRegions: FC<SectionRegionsProps> = ({
  className = "",
  itemClassName = "",
  regions,
  itemPerRow = 4.3,
  uniqueClassName,
}) => {

  const UNIQUE_CLASS =
    "SectionRegions__" + uniqueClassName + useNcId();

  let MY_GLIDEJS = useMemo(() => {
    return new Glide(`.${UNIQUE_CLASS}`, {
      perView: itemPerRow,
      gap: 32,
      bound: true,
      breakpoints: {
        1280: {
          perView: itemPerRow - 1,
        },
        1024: {
          gap: 20,
          perView: itemPerRow - 2,
        },
        768: {
          gap: 20,
          perView: itemPerRow - 2.2,
        },
        640: {
          gap: 20,
          perView: itemPerRow - 3,
        },
        500: {
          gap: 20,
          perView: 1.3,
        },
        320: {
          gap: 20,
          perView: 1.1
        }
      },
    });
  }, [UNIQUE_CLASS, regions]);

  useEffect(() => {
    setTimeout(() => {
      MY_GLIDEJS.mount();
    }, 100);
  }, [MY_GLIDEJS, UNIQUE_CLASS, regions]);

  return (
    <div className={`nc-SectionRegions ${className}`}>
      <div className={`${UNIQUE_CLASS} flow-root`}>
        <div className="glide__track" data-glide-el="track">
          <ul className="glide__slides">
            {regions?.map((item, index) => (
              <li key={index} className={`glide__slide ${itemClassName}`}>
                <RegionCard size="large" region={item} />
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default SectionRegions;
