import { FC } from "react";
import Avatar from "shared/Avatar/Avatar";
import { Helmet } from "react-helmet";
import { useApp } from "hooks/useApp";
import { BASE_URL } from "api";
import useHotels from "hooks/useHotels";
import HotelCardH from "components/HotelCardH";
import useLanguages from "hooks/useLanguages";
import useConvertDates from "hooks/useConvertDates";
import Spinner from "shared/Spinner/Spinner";

export interface AuthorPageProps {
  className?: string;
}

const AuthorPage: FC<AuthorPageProps> = ({ className = "" }) => {
  const { t } = useLanguages();
  const { user } = useApp();
  const date = useConvertDates(user.createdAt);
  const { hotels, isLoading } = useHotels({
    params: {
      hostId: user?._id
    }
  });

  const renderSidebar = () => {
    return (
      <div className=" w-full flex flex-col items-center text-center sm:rounded-2xl sm:border border-neutral-200 dark:border-neutral-700 space-y-6 sm:space-y-7 px-0 sm:p-6 xl:p-8">
        <Avatar
          hasChecked={false}
          hasCheckedClass="w-6 h-6 -top-0.5 right-2"
          sizeClass="w-28 h-28"
          imgUrl={user?.image ? `${BASE_URL}/uploads/users/${user?.image}` : ""}
          userName={user?.firstName}
        />

        <div className="space-y-3 text-center flex flex-col items-center">
          <h2 className="text-3xl font-semibold">{user?.firstName} {user?.lastName}</h2>
        </div>

        <div className="border-b border-neutral-200 dark:border-neutral-700 w-14"></div>

        <div className="space-y-4">
          <div className="flex items-center space-x-4">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6 text-neutral-400"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={1.5}
                d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6"
              />
            </svg>
            <span className="text-neutral-6000 dark:text-neutral-300">{user?.address}</span>
          </div>

          <div className="flex items-center space-x-4">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6 text-neutral-400"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={1.5}
                d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
              />
            </svg>
            <span className="text-neutral-6000 dark:text-neutral-300">
              {t('general.joined-at')} {date.format("MMM DD, YYYY")}
            </span>
          </div>
        </div>
      </div>
    );
  };

  const renderSection1 = () => {
    return (
      <div className="listingSection__wrap">
        <div>
          <h2 className="text-2xl font-semibold">{t('general.my-hotels')}</h2>
        </div>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>

        <div>
          {isLoading ?
            <div className="h-96 flex flex-col justify-center items-center gap-10">
              <div>
                <Spinner />
              </div>
              <h2 className="font-normal text-sm text-neutral-500 dark:text-neutral-400">{t('general.loading')}</h2>
            </div> :
            <div className="grid grid-cols-1 gap-6 md:gap-7">
              {hotels.filter((_, i) => i < 4).map((stay) => (
                <HotelCardH key={stay._id} href={`/my-hotel/${stay._id}`} data={stay} />
              ))}
            </div>}
        </div>
      </div>
    );
  };

  return (
    <div className={`nc-AuthorPage ${className}`} data-nc-id="AuthorPage">
      <Helmet>
        <title>{`${user?.firstName} ${user?.lastName}`} | Oteller</title>
      </Helmet>
      <main className="container mt-12 mb-24 lg:mb-32 flex flex-col lg:flex-row">
        <div className="block flex-grow mb-24 lg:mb-0">
          <div className="lg:sticky lg:top-24">{renderSidebar()}</div>
        </div>
        <div className="w-full lg:w-3/5 xl:w-2/3 space-y-8 lg:space-y-10 lg:pl-10 flex-shrink-0">
          {renderSection1()}
        </div>
      </main>
    </div>
  );
};

export default AuthorPage;
