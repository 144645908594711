const amenities: string[] = [
  "wi-fi",
  "parking",
  "breakfast",
  "bikes",
  "library",
  "gym",
  "spa",
  "pool",
  "restaurant",
  "bar",
  "sauna"
]

export default amenities;