import { ReactNode } from "react";
import {  useNavigate, useSearchParams } from "react-router-dom";
import MobileFooter from "components/MobileFooter/MobileFooter";
import ImageGallery from "components/ImageGallery/ImageGallery";

interface SearchDetailLayoutProps {
  children: ReactNode,
  images: string[],
  cheapestPrice: number
}

const SearchDetailLayout = ({ children, images, cheapestPrice }: SearchDetailLayoutProps) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const modal = searchParams?.get("modal");

  const handleCloseModalImageGallery = () => {
    let params = new URLSearchParams(document.location.search);
    params.delete("modal");
    navigate(`?${params.toString()}`);
  };

  return (
    <div className="SearchDetailPage">
      <ImageGallery
        isShowModal={modal === "PHOTO_TOUR_SCROLLABLE"}
        onClose={handleCloseModalImageGallery}
        images={images}
      />
      <div className="container ListingDetailPage__content">{children}</div>
      <MobileFooter cheapestPrice={cheapestPrice} />
    </div>
  );
};

export default SearchDetailLayout;
