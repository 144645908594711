import { FC, useMemo } from "react";
import CommonLayout from "./CommonLayout";
import useLanguages from "hooks/useLanguages";
import { Helmet } from "react-helmet"
import { useQuery } from "@tanstack/react-query";
import api from "api";
import HotelCard2 from "components/HotelCard2/HotelCard2";

const AccountSavelists: FC = () => {
  const { t } = useLanguages();
  const { data } = useQuery({
    queryKey: ["favorites"],
    queryFn: async () => {
      const res = await api.get('/favorites');
      return res.data;
    }
  });
  const hotels = useMemo<IHotel[]>(() => data || [], [data]);

  return (
    <div>
      <Helmet>
        <title>{t('pages.wishlist')} | Oteller</title>
      </Helmet>
      <CommonLayout>
        <div className="space-y-6 sm:space-y-8">
          <div>
            <h2 className="text-3xl font-semibold">{t('pages.wishlist')}</h2>
          </div>
          <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
          <div className="mt-8">
            <div className="grid grid-cols-1 gap-6 md:gap-8 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
              {hotels.map((hotel) => (
                <HotelCard2 href={`/search/${hotel._id}`} key={hotel._id} data={hotel} />
              ))}
            </div>
          </div>
        </div>
      </CommonLayout>
    </div>
  );
};

export default AccountSavelists;
