import { ChevronRightIcon } from "@heroicons/react/24/outline";
import { useQuery } from "@tanstack/react-query";
import api from "api";
import { useApp } from "hooks/useApp";
import useLanguages from "hooks/useLanguages";
import moment from "moment";
import { FC, useMemo, useState } from "react";
import { CheckmarkIcon } from "react-hot-toast";
import { Modal } from "shared/Modal/Modal";

interface CheckoutGuestsProps {
  handleChange: (id: string) => void
  guestIds: string[]
}

const CheckoutGuests: FC<CheckoutGuestsProps> = ({ handleChange, guestIds }) => {
  const { t } = useLanguages();
  const [isShown, setShow] = useState<boolean>(false);
  const { user } = useApp();
  const { data } = useQuery({
    queryKey: ["my-guests", user?._id],
    queryFn: async () => {
      const res = await api.get('/guests');
      return res.data
    }
  });
  const guests = useMemo<IGuest[]>(() => data || [], [data]);

  return (
    <>
      <div className="space-y-3 flex flex-col">
        {guests.slice(0, 3).map((guest) => {
          const isSelected = guestIds.includes(guest._id);

          return <GuestCard isSelected={isSelected} guest={guest} handleChange={handleChange} />
        })}
        {guests?.length > 3 && <button onClick={() => setShow(true)} className={`py-5 relative inline-flex items-center justify-between bg-neutral-50  dark:bg-neutral-800 hover:dark:bg-neutral-700 hover:bg-neutral-100 px-4 rounded-2xl`}>
          <div className="text-left">
            <h2 className="font-medium">{t('button.show-more')} ({guests?.length})</h2>
          </div>
          <div>
            <ChevronRightIcon className="w-5 h-5" />
          </div>
        </button>}
      </div>
      <Modal isOpen={isShown} open={() => setShow(false)} title={`${t('checkout.guests')}`}>
      <div className="space-y-3 flex flex-col">
        {guests.map((guest) => {
          const isSelected = guestIds.includes(guest._id);

          return <GuestCard isSelected={isSelected} guest={guest} handleChange={handleChange} />
        })}
      </div>
      </Modal>
    </>
  )
};

interface GuestCardProps {
  handleChange: (value: string) => void
  guest: IGuest
  isSelected: boolean
}

const GuestCard = ({ handleChange, guest, isSelected }: GuestCardProps) => {
  return (
    <button onClick={() => handleChange(guest._id)} key={guest._id} className={`relative inline-flex items-center justify-between ${isSelected ? "bg-primary-100 dark:bg-primary-800 hover:bg-primary-200" : "bg-neutral-50  dark:bg-neutral-800 hover:dark:bg-neutral-700 hover:bg-neutral-100"} px-4 py-2 rounded-2xl`}>
      <div className="text-left">
        <h2 className="font-medium">{guest.firstName} {guest.lastName}</h2>
        <span className="text-sm">{moment(guest.birthDate).format("DD.MM.YYYY")} • {guest.documentSerial}</span>
      </div>
      {isSelected && <div>
        <CheckmarkIcon className="w-5 h-5" />
      </div>}
    </button>
  )
}

export default CheckoutGuests;