import { FC } from "react";
import Logo from "shared/Logo/Logo";
import MenuBar from "shared/MenuBar/MenuBar";
import SwitchDarkMode from "shared/SwitchDarkMode/SwitchDarkMode";
import LangDropdown from "./LangDropdown";
import AvatarDropdown from "./AvatarDropdown";
import DropdownTravelers from "./DropdownTravelers";
import { Link } from "react-router-dom";
import useLanguages from "hooks/useLanguages";
import { AuthButton } from "./AuthButton";
import { useApp } from "hooks/useApp";
import MobileSearch from "components/MobileSearch/MobileSearch";

export interface MainNavProps {
  className?: string;
}

const MainNav: FC<MainNavProps> = ({ className = "" }) => {
  const { t } = useLanguages();
  const { isLoggedIn } = useApp();

  return (
    <div className={`nc-MainNav1 nc-MainNav2 relative z-10 ${className}`}>
      <div className="px-4 lg:container py-4 lg:py-5 relative flex justify-between items-center">
        <div className="hidden md:flex justify-start flex-1 items-center space-x-3 sm:space-x-8 lg:space-x-10">
          <Logo />
          <div className="hidden lg:block h-10 border-l border-neutral-200 dark:border-neutral-700"></div>
          <div className="hidden lg:block">
            <DropdownTravelers />
          </div>
        </div>

        <div className="lg:hidden flex-[3] max-w-lg !mx-auto md:px-3">
          <MobileSearch />
        </div>

        <div className="hidden md:flex flex-shrink-0 items-center justify-end flex-1 lg:flex-none text-neutral-700 dark:text-neutral-100">
          <div className="hidden items-center lg:flex space-x-1">
            <SwitchDarkMode />
            <LangDropdown />
            <Link
              to="/list-your-hotel"
              className="
                text-opacity-90
                group px-4 py-2 border border-neutral-300 hover:border-neutral-400 dark:border-neutral-700 rounded-full inline-flex items-center text-sm text-gray-700 dark:text-neutral-300 font-medium hover:text-opacity-100 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75"
            >{t('general.list-your-hotel')}</Link>

            <div></div>

            {isLoggedIn ? <AvatarDropdown /> : <AuthButton />}
          </div>
          <div className="flex items-center space-x-2 lg:hidden">
            {isLoggedIn ? <AvatarDropdown /> : (
              <Link to={"/sign-in"} className="border border-neutral-200 dark:border-neutral-700 rounded-full p-1 px-2.5">
                {t('button.sign-in')}
              </Link>
            )}
            <MenuBar />
          </div>
        </div>
      </div>
    </div>
  )
};

export default MainNav;
