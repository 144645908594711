import FiveStartIconForRate from "components/FiveStartIconForRate/FiveStartIconForRate";
import amenities from "data/amenities";
import useLanguages from "hooks/useLanguages";
import { FC } from "react";
import { useFormContext } from "react-hook-form";
import Checkbox from "shared/Checkbox/Checkbox";

const ListingHotel2: FC = () => {
  const { t } = useLanguages();
  const { watch, setValue } = useFormContext<ListingHotelType>();
  const selectedAmenities = watch("amenities") || [];

  const onSelect = (name: string) => {
    const isChecked = selectedAmenities?.includes(name)

    if (setValue) {
      if (isChecked) {
        setValue("amenities", selectedAmenities?.filter((selected: string) => selected !== name))
      } else {
        setValue("amenities", [...selectedAmenities, name])
      }
    }
  }

  return (
    <>
      <h2 className="text-2xl font-semibold">{t('listing-hotel.category')}</h2>
      <div>
        <FiveStartIconForRate
          iconClass="w-8 h-8"
          defaultPoint={watch("stars") || 0}
          onChange={(value) => setValue("stars", value)}
        />
      </div>
      <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>

      <h2 className="text-2xl font-semibold">{t('listing-hotel.amenities')}</h2>
      <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
      <div className="mt-6 grid gird-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-5">
        {
          amenities.map((amenity) => {
            const check = selectedAmenities?.includes(amenity);

            return <Checkbox
              key={amenity}
              label={t(`amenities.${amenity}`)}
              defaultChecked={check}
              onChange={() => onSelect(amenity)}
              name={amenity}
            />
          })
        }
      </div>
    </>
  )
};

export default ListingHotel2;