import DatePickerCustomDay from "components/DatePickerCustomDay";
import DatePickerCustomHeaderTwoMonth from "components/DatePickerCustomHeaderTwoMonth";
import useLanguages from "hooks/useLanguages";
import { FC, useMemo } from "react";
import DatePicker from "react-datepicker";
import { useFormContext } from "react-hook-form";
import { areDatesEqual } from "utils/compareDates";

const ListingRoom3: FC = () => {
  const { t } = useLanguages();
  const { watch, setValue } = useFormContext<ListingRoomType>();
  const unavailableDatesFromApi = watch("unavailableDates");

  const unavailableDates: Date[] = useMemo(
    () =>
      unavailableDatesFromApi
        ? unavailableDatesFromApi.map((uDateStr) => new Date(uDateStr))
        : [],
    [unavailableDatesFromApi]
  )

  return (
    <>
      <div>
        <h2 className="text-2xl font-semibold">{t('listing-room.set-availability')}</h2>
        <span className="block mt-2 text-neutral-500 dark:text-neutral-400">{t('listing-room.calendar-tip')}</span>
      </div>

      <div className="addListingDatePickerExclude">
        <DatePicker
          onChange={(date) => {
            let newDates = []

            if (!date) {
              return
            }

            if (unavailableDates.some((uDate) => areDatesEqual(uDate, date))) {
              newDates = unavailableDates.filter(
                (item) => !areDatesEqual(item, date)
              )
            } else {
              newDates = [...unavailableDates, date]
            }

            setValue(
              "unavailableDates",
              newDates.map((nDate) => nDate.toISOString())
            )
          }}
          monthsShown={2}
          showPopperArrow={false}
          highlightDates={[
            {
              "react-datepicker__day--highlighted-custom-1": unavailableDates,
            },
          ]}
          inline
          minDate={new Date()}
          renderCustomHeader={(p) => <DatePickerCustomHeaderTwoMonth {...p} />}
          renderDayContents={(day, date) => (
            <DatePickerCustomDay dayOfMonth={day} date={date} />
          )}
        />
      </div>
    </>
  )
};

export default ListingRoom3;