import { FC } from "react";
import { Link } from "react-router-dom";
import NcImage from "shared/NcImage/NcImage";
import useLanguages from "hooks/useLanguages";
import { BASE_URL, IMAGE_PATH } from "api";
import useSearch from "hooks/useSearch";

export interface RegionCardProps {
  className?: string;
  region: IRegion;
  size?: "large" | "normal";
}

const RegionCard: FC<RegionCardProps> = ({
  className = "",
  size = "normal",
  region,
}) => {
  const { t } = useLanguages();
  const { saveRegion } = useSearch();
  const { _id, count, image } = region;

  const onSubmit = (region: string) => {
    saveRegion(region)
  }

  return (
    <Link
      to={`/search`}
      onClick={() => onSubmit(_id)}
      className={`nc-RegionCard flex items-center ${className}`}
      data-nc-id="RegionCard"
    >
      <NcImage
        containerClassName={`flex-shrink-0 ${size === "large" ? "w-24 h-24" : "w-12 h-12"
          } rounded-lg mr-4 overflow-hidden`}
        src={`${IMAGE_PATH}/hotels/${image}`}
      />
      <div>
        <h2
          className={`${size === "large" ? "text-lg" : "text-base"
            } nc-card-title text-neutral-900 dark:text-neutral-100 font-semibold capitalize`}
        >
          {t(`regions.${_id}`)}
        </h2>
        <span
          className={`${size === "large" ? "text-sm" : "text-xs"
            } block mt-[2px] text-neutral-500 dark:text-neutral-400`}
        >
          {count} {count > 1 ? t("counter.hotels.plural") : t("counter.hotels.single")}
        </span>
      </div>
    </Link>
  );
};

export default RegionCard;
