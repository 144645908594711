import StartRating from "components/StartRating/StartRating";
import { Link } from "react-router-dom";
import BtnLikeIcon from "components/BtnLikeIcon/BtnLikeIcon";
import Gallery from "components/Gallery/Gallery";
import { FC } from "react";
import useLanguages from "hooks/useLanguages";
import { IMAGE_PATH } from "api";

export interface HotelCard2Props {
  className?: string;
  data: IHotel;
  href: string
}

const HotelCard2: FC<HotelCard2Props> = ({
  href,
  className = "",
  data,
}) => {
  const { t, i18n } = useLanguages();
  const {
    _id,
    title,
    cheapestPrice,
    images,
    address,
    region,
    rating
  } = data;
  const point = rating?.score / rating?.count;
  const formattedPoint = isNaN(point) ? 0 : point.toFixed(1);
  const hotelImages = images.map((image) => `${IMAGE_PATH}/hotels/thumb_${image}`)

  const renderSliderGallery = () => {
    return (
      <div className="relative w-full">
        <Gallery
          uniqueID={`HotelCard2_${_id}`}
          ratioClass="aspect-w-4 aspect-h-3 "
          galleryImgs={hotelImages}
          href={href}
        />
        <BtnLikeIcon hotelId={_id} className="absolute right-3 top-3 z-[1]" />
      </div>
    );
  };

  const renderContent = () => {
    return (
      <div className={"p-4 space-y-4"}>
        <div className="space-y-2">

          <div className="flex items-center space-x-2">
            <h2
              className={`font-medium capitalize text-base`}
            >
              <span className="line-clamp-1">{title[i18n.language as "tm" | "en" | "ru"]}</span>
            </h2>
          </div>
          <div className="flex items-center text-neutral-500 dark:text-neutral-400 text-sm space-x-2">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={1.5}
                d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"
              />
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={1.5}
                d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"
              />
            </svg>
            <span className="">{address[i18n.language as "tm" | "en" | "ru"]}, {t(`regions.${region}`)}</span>
          </div>
        </div>
        <div className="w-14 border-b border-neutral-100 dark:border-neutral-800"></div>
        <div className="flex justify-between items-center">
          {cheapestPrice && <span className="text-base font-semibold">
            {cheapestPrice} TMT
            {` `}
            <span className="text-sm text-neutral-500 dark:text-neutral-400 font-normal">
              /{t('general.night')}
            </span>
          </span>}
          {!!rating.count && (
            <StartRating reviewCount={rating?.count} point={formattedPoint as number} />
          )}
        </div>
      </div>
    );
  };

  return (
    <div
      className={`nc-HotelCard2 group relative bg-white dark:bg-neutral-900 border border-neutral-100 dark:border-neutral-800 rounded-2xl overflow-hidden will-change-transform hover:shadow-xl transition-shadow ${className}`}
      data-nc-id="HotelCard2"
    >
      {renderSliderGallery()}
      <Link to={href}>{renderContent()}</Link>
    </div>
  );
};

export default HotelCard2;
