import useLanguages from "hooks/useLanguages";
import { FC, useState } from "react";
import { useFormContext } from "react-hook-form";
import GuestInput from "./GuestInput";
import DatesInput from "./DatesInput";
import useConvertDates from "hooks/useConvertDates";
import LocationInput from "./LocationInput";

export interface MobileSearchForm {
  region: string
  startDate: Date | null
  endDate: Date | null
  adultCount: number
  childrenCount: number
  roomCount: number
}

const Search: FC = () => {
  const [fieldNameShow, setFieldNameShow] = useState<"location" | "dates" | "guests">("location");
  const { t } = useLanguages();

  const { watch } = useFormContext<MobileSearchForm>();
  const checkIn = useConvertDates(watch('startDate'));
  const checkOut = useConvertDates(watch("endDate"));

  const renderInputLocation = () => {
    const isActive = fieldNameShow === "location";
    return (
      <div
        className={`w-full bg-white dark:bg-neutral-800 ${isActive
          ? "rounded-2xl shadow-lg"
          : "rounded-xl shadow-[0px_2px_2px_0px_rgba(0,0,0,0.25)]"
          }`}
      >
        {!isActive ? (
          <button
            className={`w-full flex justify-between text-sm font-medium p-4`}
            onClick={() => setFieldNameShow("location")}
          >
            <span className="text-neutral-400">{t('search.where')}</span>
            <span>{watch("region") ? t(`regions.${watch("region")}`) : t('search.location')}</span>
          </button>
        ) : (
          <LocationInput />
        )}
      </div>
    );
  };

  const renderInputDates = () => {
    const isActive = fieldNameShow === "dates";

    return (
      <div
        className={`w-full bg-white dark:bg-neutral-800 overflow-hidden ${isActive
          ? "rounded-2xl shadow-lg"
          : "rounded-xl shadow-[0px_2px_2px_0px_rgba(0,0,0,0.25)]"
          }`}
      >
        {!isActive ? (
          <button
            className={`w-full flex justify-between text-sm font-medium p-4  `}
            onClick={() => setFieldNameShow("dates")}
          >
            <span className="text-neutral-400">{t('search.when')}</span>
            <span>
              {watch("endDate")
                ? `${checkIn.format("DD.MM")}-${checkOut.format("DD.MM")}`
                : t('search.dates')}
            </span>
          </button>
        ) : (
          <DatesInput />
        )}
      </div>
    );
  };

  const renderInputGuests = () => {
    const isActive = fieldNameShow === "guests";
    let guestSelected = "";
    if (watch("adultCount") || watch("childrenCount")) {
      const guest = watch("adultCount") + watch("childrenCount");
      guestSelected += `${guest} ${guest > 1 ? t('counter.guests.plural') : t("counter.guests.single")}`;
    }

    if (watch("roomCount")) {
      guestSelected += `, ${watch("roomCount")} ${watch("roomCount") > 1 ? t("counter.rooms.plural") : t('counter.rooms.single')}`;
    }

    return (
      <div
        className={`w-full bg-white dark:bg-neutral-800 overflow-hidden ${isActive
          ? "rounded-2xl shadow-lg"
          : "rounded-xl shadow-[0px_2px_2px_0px_rgba(0,0,0,0.25)]"
          }`}
      >
        {!isActive ? (
          <button
            className={`w-full flex justify-between text-sm font-medium p-4`}
            onClick={() => setFieldNameShow("guests")}
          >
            <span className="text-neutral-400">{t('search.who')}</span>
            <span>{guestSelected}</span>
          </button>
        ) : (
          <GuestInput />
        )}
      </div>
    );
  };

  return (
    <div>
      <div>
        <form className="w-full space-y-5">
          {renderInputLocation()}
          {renderInputDates()}
          {renderInputGuests()}
        </form>
      </div>
    </div>
  );
};

export default Search;

