import { useApp } from "hooks/useApp"
import { Navigate, Outlet, useLocation } from "react-router-dom";

const AuthGuard = () => {
  const { isLoggedIn } = useApp();
  const location = useLocation();
  const from = location.state?.from?.pathname || "/";

  return !isLoggedIn ? <Outlet /> : <Navigate to={from} replace />
}

export default AuthGuard;