import { useApp } from "hooks/useApp";
import useLanguages from "hooks/useLanguages";
import React from "react";
import { FC } from "react";
import { NavLink } from "react-router-dom";

export interface CommonLayoutProps {
  children?: React.ReactNode;
}

const pages = [
  {
    name: "account",
    href: "/account",
    isPermit: ["user", "host", "admin", "operator"]
  },
  // {
  //   name: "change-password",
  //   href: "/change-password",
  //   isPermit: ["user", "host", "admin", "operator"]
  // },
  {
    name: "guests",
    href: "/guests",
    isPermit: ["user"]
  },
  {
    name: "wishlist",
    href: "/wishlist",
    isPermit: ["user"]
  },
  {
    name: "bookings",
    href: "/bookings",
    isPermit: ["user"]
  }
]

const CommonLayout: FC<CommonLayoutProps> = ({ children }) => {
  const { t } = useLanguages();
  const { user } = useApp();

  const filteredPages = pages.filter(page => {
    if (user && user.role) {
      return page.isPermit.includes(user.role);
    };

    return false;
  });

  return (
    <div className="nc-CommonLayoutProps bg-neutral-50 dark:bg-neutral-900">
      <div className="border-b border-neutral-200 dark:border-neutral-700 pt-12 bg-white dark:bg-neutral-800">
        <div className="container">
          <div className="flex space-x-8 md:space-x-14 overflow-x-auto hiddenScrollbar">
            {filteredPages.map((page, index) => {
              return (
                <NavLink
                  key={page.name}
                  to={page.href}
                  className={({ isActive }) =>
                    `block py-5 md:py-8 border-b-2 flex-shrink-0 ${!isActive ? "border-transparent" : "border-primary-500"
                    }`
                  }
                >
                  {t(`pages.${page.name}`)}
                </NavLink>
              )
            })}
          </div>
        </div>
      </div>
      <div className="container pt-14 sm:pt-20 pb-24 lg:pb-32">{children}</div>
    </div>
  );
};

export default CommonLayout;
