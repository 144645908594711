import api from "api";

class User {
  async update(values: FormData){
    const res = await api.put('/users/details', values);
    return res.data;
  };

  async changePassword(values: { password: string, newPassword: string}){
    const res =await api.put('/users/details', values);
    return res.data;
  };

  async sendRequest(values: IRequest){
    const res = await api.post('/requests', values);
    return res.data;
  }
};

export const userApi = new User();