import { Disclosure } from "@headlessui/react";
import useAuth from "hooks/useAuth";
import useLanguages from "hooks/useLanguages";
import { FC } from "react";

interface LogoutProps {
  onClick?: () => void
}

const Logout: FC<LogoutProps> = ({ onClick }) => {
  const { t } = useLanguages();
  const { logout } = useAuth({});

  return (
    <Disclosure
      as="li"
      className="w-full text-red-600"
    >
      <button onClick={() => {
        logout()
        onClick  && onClick()
      }} className={`flex items-start w-full px-4 font-medium uppercase tracking-wide text-sm hover:bg-neutral-100 dark:hover:bg-neutral-800 rounded-lg`}>
        <span
          className={`py-2.5 pr-3 text-start block w-full`}
        >{t('button.logout')}</span>
      </button>
    </Disclosure>
  )
};

export default Logout;