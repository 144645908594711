import api from "api";
import { SignInParams } from "containers/PageLogin/PageLogin";
import { OtpParams } from "containers/PageSignUp/PageSignUp";
import { SignUpParams } from "containers/PageSignUp/Register";

class AuthClient {
  async login(values: SignInParams) {
    const res = await api.post("/auth/login", values);
    return res.data;
  }

  async register(values: OtpParams) {
    const res = await api.post("/auth/otp", values);
    return res.data;
  }

  async otp(values: SignUpParams & { otp: string }) {
    const res = await api.post("/auth/register", values);
    return res.data;
  }

  async logout() {
    const res = await api.get("/auth/logout");
    return res.data;
  }

  async forgot(values: {
    email?: string;
    phonenumber?: string;
  }) {
    const res = await api.post("/auth/forgot", values);
    return res.data;
  }

  async changePassword() {
    const res = await api.put("/auth/change-password");
    return res.data;
  }
}

export const authClient = new AuthClient();
