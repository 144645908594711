import { useQuery } from "@tanstack/react-query";
import api from "api";
import { useMemo } from "react";

interface UseHotelProps {
  params?: any
}

interface UseHotelValues {
  hotels: IHotel[]
  isLoading: boolean
}

const useHotels = ({ params }: UseHotelProps): UseHotelValues => {
  const { data, isLoading } = useQuery({
    queryKey: ["hotels"],
    queryFn: async () => {
      const res = await api.get('/hotels', { params });
      return res.data;
    }
  });
  const hotels = useMemo<IHotel[]>(() => data ? data : [], [data]);

  return {
    hotels,
    isLoading
  }
};

export default useHotels;