import React, { InputHTMLAttributes } from "react";

export interface PhoneInputProps extends InputHTMLAttributes<HTMLInputElement> {
  sizeClass?: string;
  fontClass?: string;
  rounded?: string;
}

const PhoneInput = React.forwardRef<HTMLInputElement, PhoneInputProps>(
  (
    {
      className = "",
      sizeClass = "h-11 px-4 py-3",
      fontClass = "text-sm font-normal",
      rounded = "rounded-2xl",
      children,
      ...args
    },
    ref
  ) => {
    return (
      <>
        <p className="text-sm font-normal absolute left-3 top-10 bottom-0">+993</p>
        <input
          ref={ref}
          type={"text"}
          maxLength={12}
          className={`block pl-14 w-full border-neutral-200 focus:border-primary-300 focus:ring focus:ring-primary-200 focus:ring-opacity-50 bg-white dark:border-neutral-700 dark:focus:ring-primary-6000 dark:focus:ring-opacity-25 dark:bg-neutral-900 ${rounded} ${fontClass} ${sizeClass} ${className}`}
          {...args}
        />
      </>
    );
  }
);

export default PhoneInput;
