import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import useLanguages from "hooks/useLanguages";
import { FC } from "react";
import { Helmet } from "react-helmet";
import NcImage from "shared/NcImage/NcImage";
import rightImg from "images/BecomeAnAuthorImg.png"
import PartnerForm from "./PartnerForm";

const PartnerPage: FC = () => {
  const { t } = useLanguages();

  return (
    <div className={`nc-BecomPartner`} data-nc-id="BecomePartner">
      <Helmet>
        <title>{t('sitemap.become-partner')} | Oteller</title>
      </Helmet>

      <BgGlassmorphism />

      <div className="container space-y-16 lg:space-y-24 relative flex flex-col lg:flex-row items-start">
        <PartnerForm />
        <div className="flex-grow lg:sticky lg:top-36">
          <NcImage src={rightImg} />
        </div>
      </div>
    </div>
  )
};

export default PartnerPage;