import { FC, useMemo } from "react";
import Pagination from "shared/Pagination/Pagination";
import TabFilters from "./TabFilters";
import useSearch from "hooks/useSearch";
import useLanguages from "hooks/useLanguages";
import useConvertDates from "hooks/useConvertDates";
import { useQuery } from "@tanstack/react-query";
import api from "api";
import HotelCardH from "components/HotelCardH";
import Spinner from "shared/Spinner/Spinner";

export interface SectionGridHotelsProps { }

const SectionGridHotels: FC<SectionGridHotelsProps> = () => {
  const { region, startDate, endDate, adultCount, childrenCount, roomCount, rangePrices } = useSearch();
  const checkIn = useConvertDates(startDate);
  const checkOut = useConvertDates(endDate);
  const guestCount = adultCount + childrenCount;
  const { t } = useLanguages();

  const { data: availableHotels, isLoading } = useQuery({
    queryKey: ["search-hotels", region, startDate, endDate, adultCount, childrenCount, roomCount, rangePrices],
    queryFn: async () => {
      const res = await api.get('/hotels/search', {
        params: {
          region,
          startDate,
          endDate,
          guestCount: adultCount + childrenCount,
          roomCount,
          min: rangePrices[0],
          max: rangePrices[1]
        }
      });
      return res.data;
    }
  });
  const hotels = useMemo<IHotel[]>(() => availableHotels || [], [availableHotels]);

  return (
    <div className="pt-10">
      <div className="relative flex min-h-screen">
        <div className="min-h-screen w-full">
          <div className={`mb-12 lg:mb-16`}>
            <h2 className="text-2xl md:text-3xl lg:text-4xl font-semibold">{t('search.search-result')} {region && t(`regions.${region}`)}</h2>
            {(
              <span className="block text-neutral-500 dark:text-neutral-400 mt-3">
                {checkIn.format("MMMM DD")} - {checkOut.format("MMMM DD")}
                <span className="mx-2">·</span>
                {guestCount} {guestCount > 1 ? t('counter.guests.plural') : t("counter.guests.single")}
                <span className="mx-2">·</span>
                {roomCount} {roomCount > 1 ? t('counter.rooms.plural') : t('counter.rooms.single')}
              </span>
            )}
          </div>
          <div className="mb-8 lg:mb-11">
            <TabFilters />
          </div>
          {isLoading ? <div className="h-72 flex flex-col justify-center items-center gap-10">
            <div>
              <Spinner />
            </div>
            <h2 className="font-normal text-sm text-neutral-500 dark:text-neutral-400">{t('general.loading')}</h2>
          </div> : hotels?.length ? (
            <div className="grid grid-cols-1 gap-6 md:gap-8 xl:grid-cols-2">
              {hotels.map((hotel) => (
                <div key={hotel._id}>
                  <HotelCardH data={hotel} href={`/search/${hotel._id}`} />
                </div>
              ))}
            </div>
          ) : (
            <div>
              <h2 className="text-xl font-medium">{t('alert.not-found-hotels')}</h2>
              <p>{t('alert.change-your-search')}</p>
            </div>
          )}
          {hotels?.length > 20 && (
            <div className="flex mt-16 justify-center items-center">
              <Pagination />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default SectionGridHotels;
