import { NavItemType } from "shared/Navigation/NavigationItem";
import ncNanoId from "utils/ncNanoId";

export const NAVIGATION_DEMO: NavItemType[] = [
  {
    id: ncNanoId(),
    href: "/",
    name: "home",
    isNew: true,
  },
  {
    id: ncNanoId(),
    href: "/about-us",
    name: "about-us",
  },
  {
    id: ncNanoId(),
    href: "/contact-us",
    name: "customer-support"
  },
  {
    id: ncNanoId(),
    href: "/become-partner",
    name: "become-partner",
  }
];
