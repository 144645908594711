import useLanguages from "hooks/useLanguages"
import { FC } from "react"

interface OtpMethodProps {
  setMethod: (value: "email" | "phone") => void
  defaultValue?: string
}

export const OtpMethod: FC<OtpMethodProps> = ({ setMethod }) => {
  const { t } = useLanguages();

  return (
    <div className="w-full inline-flex items-center border rounded-2xl divide-x-[1px] divide-neutral-200 dark:divide-neutral-700 dark:border-neutral-700">
      <label htmlFor="phone" className="p-4 flex-1 space-x-3 flex flex-row items-start">
        <input id="phone" name="otpMethod" value={"phone"} className="mt-1 text-[#504DE2] bg-neutral-100 border-neutral-200 focus:ring-[#504DE2] dark:focus:ring-[#504DE2] dark:ring-offset-neutral-800 focus:ring-2 dark:bg-neutral-700 dark:border-neutral-700" type={"radio"} onChange={() => setMethod("phone")} />
        <div >
          <h3 className="text-neutral-900 dark:text-neutral-100">{t('fields.sms')}</h3>
          <p className="text-neutral-500 dark:text-neutral-400 text-sm font-light">{t('alert.send-otp-sms')}</p>
        </div>
      </label>
      <label htmlFor="mail" className="p-4 flex-1 space-x-3 flex flex-row items-start">
        <input id="mail" name="otpMethod" value={"email"} className="mt-1 text-[#504DE2] bg-neutral-100 border-neutral-200 focus:ring-[#504DE2] dark:focus:ring-[#504DE2] dark:ring-offset-neutral-800 focus:ring-2 dark:bg-neutral-700 dark:border-neutral-700" type={"radio"} onChange={() => setMethod("email")} />
        <div>
          <h3 className="text-neutral-900 dark:text-neutral-100">{t('fields.email')}</h3>
          <p className="text-neutral-500 dark:text-neutral-400 text-sm font-light">{t("alert.send-otp-email")}</p>
        </div>
      </label>
    </div>
  )
}