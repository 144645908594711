import { useApp } from "hooks/useApp";
import useLanguages from "hooks/useLanguages";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import Spinner from "shared/Spinner/Spinner";

const CommonGuard = () => {
  const { isLoggedIn, isReady } = useApp();
  const { t } = useLanguages();
  const location = useLocation();

  if (isReady) {
    return (
      <div className="h-96 flex flex-col justify-center items-center gap-10">
        <div>
          <Spinner />
        </div>
        <h2 className="font-normal text-sm text-neutral-500 dark:text-neutral-400">{t('general.loading')}</h2>
      </div>
    )
  };

  return isLoggedIn ? <Outlet /> : <Navigate to={"/sign-in"} state={{ from: location }} replace />
};

export default CommonGuard;