import { useTranslation } from "react-i18next";

const useLanguages = () => {
  const { t, i18n } = useTranslation();

  const changeLanguageHandler = (lang: string) => {
    i18n.changeLanguage(lang);
    localStorage.setItem("lang", lang);
  };

  return { t, i18n, changeLanguageHandler };
};

export default useLanguages;